<template>
  <div class="gang-drill h100">
    <div class="h100 y-auto">
      <div class="head-title">
        <span>{{ $t('cuttingDock.drillSettings.enable') }}</span>
        <a-switch
          v-model="formData.enabled"
          id="engraving_setting_drill_enable_switch"
        ></a-switch>
      </div>
      <div v-show="formData.enabled">
        <div class="gang-drill-table">
          <div class="table-box">
            <el-table
              :data="formData.drills"
              :row-class-name="tableRowClassName"
              :cell-class-name="tableCellClassName"
              @cell-click="cellClick"
              :max-height="500"
            >
              <el-table-column
                :label="$t('cuttingDock.drillSettings.toolTable.use')"
                prop="isUsed"
              >
                <template slot-scope="scope">
                  <a-checkbox
                    v-model="scope.row.isUsed"
                    :id="`engraving_setting_drill_knife_table_${scope.row.index}_isUsed_checkbox`"
                  ></a-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                v-for="(knife, index) in knifeColumn"
                :key="index"
                :label="translateLang(knife.label)"
                :prop="knife.prop"
              >
                <template slot-scope="scope">
                  <div
                    v-if="
                      scope.row.index === rowIndex &&
                      scope.column.index === columnIndex
                    "
                  >
                    <el-input
                      v-model="scope.row[knife.prop]"
                      ref="editInput"
                      :type="textareaJudge(knife.prop)"
                      resize="none"
                      size="mini"
                      autosize
                      @blur="inputBlur(scope.row, knife.prop)"
                      @change="inputBlur"
                      @input="cancelBenchDrill()"
                      :id="`engraving_setting_drill_knife_table_${scope.row.index}_${knife.prop}_input`"
                    />
                  </div>
                  <div class="knives_type" style="white-space: pre-line" v-else>
                    <span
                      :id="`engraving_setting_drill_knife_table_${scope.row.index}_${knife.prop}`"
                      >{{ scope.row[knife.prop] }}</span
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="
                  $t('cuttingDock.drillSettings.toolTable.benchmarkDrill')
                "
                prop="benchDrill"
              >
                <template slot-scope="scope">
                  <a-checkbox
                    v-model="scope.row._selected"
                    :disabled="scope.row.number == ''"
                    @change="changeBenchDrill(scope.row)"
                    :id="`engraving_setting_drill_knife_table_${scope.row.index}_benchDrill_checkbox`"
                  ></a-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="operation-btn">
            <a-button
              @click="addNewKnife"
              class="mr12"
              id="engraving_setting_drill_add_btn"
              >{{
                $t('cuttingDock.drillSettings.toolTable.addKnife')
              }}</a-button
            >
            <a-button
              @click="deleteKnife"
              id="engraving_setting_drill_delete_btn"
              >{{
                $t('cuttingDock.drillSettings.toolTable.deleteKnife')
              }}</a-button
            >
          </div>
        </div>
        <div class="code-box">
          <div>
            <div>
              <span>{{ $t('cuttingDock.drillSettings.prefix') }}:</span>
              <a-textarea
                :placeholder="$t('cuttingDock.drillSettings.prefix')"
                v-model="formData.prefix"
                id="engraving_setting_drill_prefix_textarea"
              ></a-textarea>
            </div>
            <div>
              <span>{{ $t('cuttingDock.drillSettings.suffix') }}:</span>
              <a-textarea
                :placeholder="$t('cuttingDock.drillSettings.suffix')"
                v-model="formData.suffix"
                id="engraving_setting_drill_suffix_textarea"
              ></a-textarea>
            </div>
          </div>
          <div>
            <div>
              <span>{{ $t('cuttingDock.drillSettings.startCode') }}：</span>
              <a-textarea
                :placeholder="$t('cuttingDock.drillSettings.startCode')"
                v-model="formData.beginCode"
                id="engraving_setting_drill_beginCode_textarea"
              ></a-textarea>
            </div>
            <div>
              <span>{{ $t('cuttingDock.drillSettings.endCode') }}：</span>
              <a-textarea
                :placeholder="$t('cuttingDock.drillSettings.endCode')"
                v-model="formData.endCode"
                id="engraving_setting_drill_endCode_textarea"
              ></a-textarea>
            </div>
          </div>
        </div>
        <!-- 加工设置 -->
        <div class="process-setting">
          <div class="fs20 mb20">
            {{ $t('cuttingDock.drillSettings.processSetting.title') }}
          </div>
          <div class="mb20">
            <span
              >{{ $t('cuttingDock.drillSettings.processSetting.mode') }}：</span
            >
            <a-radio-group v-model="formData.drill_process_mode">
              <a-radio
                v-for="item in drillMode"
                :key="item.label"
                :value="item.value"
                :id="item.id"
                >{{ item.label
                }}<span
                  v-if="item.value === 'group_hole'"
                  style="color: red"
                  class="ml10"
                ></span
              ></a-radio>
            </a-radio-group>
            <span style="color: #f00">{{
              $t('cuttingDock.drillSettings.processSetting.drill_benchmark_Tip')
            }}</span>
          </div>
          <!--          排钻组合下刀-->
          <div
            class="drilling-part"
            v-if="formData.drill_process_mode === 'recent_hole'"
          >
            <div class="drilling-title">
              <span style="margin-right: 10px">{{
                $t('cuttingDock.drillSettings.combineKnife')
              }}</span>
              <el-checkbox
                v-model="isDriliingChecked"
                id="engraving_setting_drill_isDriliingChecked_checkbox"
              ></el-checkbox>
            </div>
            <div class="drilling-content" v-show="isDriliingChecked">
              <div class="left-table">
                <el-table
                  :data="drillTableData"
                  :span-method="arraySpanMethod"
                  :row-style="comstumRow"
                  @cell-click="drillCellClick"
                  border
                  style="width: 100%"
                >
                  <el-table-column
                    align="center"
                    prop="name"
                    label=""
                    width="180"
                  >
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="prefix"
                    :label="$t('cuttingDock.drillSettings.combineTable.prefix')"
                    width="180"
                  >
                    <template slot-scope="scope">
                      <div
                        v-if="
                          scope.row.name === drillRowName &&
                          scope.column.property === drillColumnProperty
                        "
                      >
                        <el-input
                          v-model="scope.row.prefix"
                          ref="editInput"
                          type="textarea"
                          size="mini"
                          @blur="inputBlur"
                          @change="inputBlur"
                          :id="`engraving_setting_drill_table_${scope.row.index}_prefix_input`"
                          :disabled="
                            formData.drill_process_mode === 'group_hole'
                          "
                        />
                      </div>
                      <div v-else>
                        <p
                          v-for="(text, index) in scope.row.prefix.split('\n')"
                          :key="index"
                          class="drill-fix"
                          :id="`engraving_setting_drill_table_${scope.row.index}_prefix_${index}`"
                        >
                          {{ text }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="suffix"
                    :label="$t('cuttingDock.drillSettings.combineTable.suffix')"
                    width="180"
                  >
                    <template slot-scope="scope">
                      <div
                        v-if="
                          scope.row.name === drillRowName &&
                          scope.column.property === drillColumnProperty
                        "
                      >
                        <el-input
                          v-model="scope.row.suffix"
                          ref="editInput"
                          type="textarea"
                          size="mini"
                          @blur="inputBlur"
                          @change="inputBlur"
                          :id="`engraving_setting_drill_table_${scope.row.index}_suffix_input`"
                          :disabled="
                            formData.drill_process_mode === 'group_hole'
                          "
                        />
                      </div>
                      <div v-else>
                        <p
                          v-for="(text, index) in scope.row.suffix.split('\n')"
                          :key="index"
                          class="drill-fix"
                          :id="`engraving_setting_drill_table_${scope.row.index}_suffix_${index}`"
                        >
                          {{ text }}
                        </p>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="right-text">
                <p>
                  {{ $t('cuttingDock.drillSettings.combineTable.example') }}
                </p>
                <p
                  v-for="(text, index) in drillCode.split('\n')"
                  :key="index"
                  class="drill-fix"
                  :id="`engraving_setting_drill_example_${index}`"
                >
                  {{ text }}
                </p>
              </div>
            </div>
            <div class="drilling-bottom" v-show="isDriliingChecked">
              <el-button
                @click="handleAddHole"
                id="engraving_setting_drill_addHole_btn"
                :disabled="formData.drill_process_mode === 'group_hole'"
                >{{
                  $t('cuttingDock.drillSettings.combineTable.addExtraHole')
                }}</el-button
              >
              <el-button
                @click="handleDeleteHole"
                id="engraving_setting_drill_deleteHole_btn"
                :disabled="formData.drill_process_mode === 'group_hole'"
                >{{
                  $t('cuttingDock.drillSettings.combineTable.deleteExtraHole')
                }}</el-button
              >
            </div>
          </div>

          <!--          排钻组合下刀基准-->
          <div
            class="mt20"
            v-if="formData.drill_process_mode === 'recent_hole'"
          >
            <span
              >{{
                $t('cuttingDock.drillSettings.processSetting.drill_benchmark')
              }}：</span
            >
            <a-radio-group v-model="formData.drill_benchmark">
              <a-radio value="main">{{
                $t('cuttingDock.drillSettings.processSetting.mainHole')
              }}</a-radio>
              <a-radio value="end">{{
                $t('cuttingDock.drillSettings.processSetting.secondaryHole')
              }}</a-radio>
            </a-radio-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translate } from '@/util/commonFun'
import { buryPointApi, genUniId } from '@/util/commonFuncs'
import { webId } from '@/util/configure'
import { mapGetters, mapState } from 'vuex'

import { gangDrillTableConfigList as gangDrillTableConfig } from './config/tableConfig'

const textAreaFieldSet = new Set(['feedRate', 'number', 'number2'])
export default {
  props: {
    obj: {
      type: [Array, Object],
      default: () => {},
    },
    ncExportSetting: {
      type: [Array, Object],
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('equipEnvironment', ['showConfig']),
    ...mapState('equipEnvironment', ['showCode2']),
    knifeColumn() {
      const otherConfig = [
        {
          label: 'cuttingDock.drillSettings.toolTable.number',
          prop: 'number',
        },
        {
          label: 'cuttingDock.drillSettings.toolTable.number2',
          prop: 'number2',
        },
      ]
      if (!this.showConfig || !this.showCode2) {
        otherConfig.pop()
      }
      return otherConfig.concat(gangDrillTableConfig)
    },
  },
  data() {
    return {
      formData: {
        drills: [],
        enabled: true, // 记录是否启用排钻包
        prefix: '', // 排钻前缀
        suffix: '', // 排钻后缀
        beginCode: '', // 排钻启动代码
        endCode: '', // 排钻结束代码
        benchmarkDrill: '', // 基准钻
        drill_process_mode: 'recent_drill', // 排钻加工模式
        drill_benchmark: '', // 排钻组合下刀基准
      },
      rowIndex: -1,
      columnIndex: -1,
      currentRow: {},
      drillTableData: [],
      isDriliingChecked: false,
      gangDrillCombinationAuxiliaryList: [], //从孔列表
      drillRowName: '',
      drillColumnProperty: '',
      drillCode: '', //样例代码
      drillMode: [
        {
          label: this.$t('cuttingDock.drillSettings.processSetting.recentHole'),
          value: 'recent_hole',
          id: 'engraving_setting_drill_default_mode',
        },
        {
          label: this.$t('cuttingDock.drillSettings.processSetting.groupHole'),
          value: 'group_hole',
          id: 'engraving_setting_drill_tool_change_mode',
        },
      ],
    }
  },
  watch: {
    obj(val) {
      if (Object.keys(this.obj).length > 0) {
        this.isDriliingChecked = val.gangDrillCombinationEnable
        this.gangDrillCombinationAuxiliaryList =
          val.gangDrillCombinationAuxiliaryList
        this.formatDrillData(val)
        let s = JSON.parse(JSON.stringify(this.obj))
        s.drills.forEach((v) => {
          v._selected = false
          v.originNumber2 = v.number2
        })
        s.drills.forEach((v) => {
          if (this.obj.benchmarkDrill == v.number && v.number != '') {
            v._selected = true
          }
          if (v.isUsed == '') {
            v.isUsed = false
          }
        })
        this.formData = s
      }
    },
    drillTableData: {
      deep: true,
      handler(val) {
        const drills = val.slice(0, val.length - 2)
        const more = val.slice(-2)
        let str =
          drills
            .map((drill, index) => drill.prefix + (10 + index) + drill.suffix)
            .join('') + more.map((e) => e.prefix).join('')
        this.drillCode = str
      },
    },
  },
  methods: {
    /**
     * @desc 判断当前输入框是否更改为文本域
     * @param field
     * @return {String} 'textarea' | 'text'
     */
    textareaJudge(field) {
      return textAreaFieldSet.has(field) ? 'textarea' : 'text'
    },
    translateLang(key) {
      return translate(key)
    },
    formatDrillData(data, isEdit = false) {
      let tableData = []
      tableData.push({
        name: '主孔',
        prefix: isEdit
          ? this.drillTableData[0].prefix
          : data.gangDrillCombinationMainPrefix,
        suffix: isEdit
          ? this.drillTableData[0].suffix
          : data.gangDrillCombinationMainSuffix,
      })
      tableData = tableData
        .concat(
          this.gangDrillCombinationAuxiliaryList.map((e, index) => ({
            name: `从孔${index + 1}`,
            prefix: e.knifePrefix,
            suffix: e.knifeSuffix,
            webId: e.webId,
          }))
        )
        .concat([
          {
            name: '组合注释',
            prefix: isEdit
              ? this.drillTableData[this.drillTableData.length - 2].prefix
              : data.gangDrillCombinationComment,
            suffix: '',
          },
          {
            name: '首次启用注释',
            prefix: isEdit
              ? this.drillTableData[this.drillTableData.length - 1].prefix
              : data.gangDrillCombinationuCommentFirstSign,
            suffix: '',
          },
        ])
      this.drillTableData = tableData
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        rowIndex === this.drillTableData.length - 1 ||
        rowIndex === this.drillTableData.length - 2
      ) {
        if (columnIndex === 1) {
          return [1, 2]
        }
      }
    },
    // 自定义行样式
    comstumRow({ row, rowIndex }) {
      if (
        rowIndex === this.drillTableData.length - 1 ||
        rowIndex === this.drillTableData.length - 2
      ) {
        return {
          height: '90px',
        }
      }
    },
    // 组合排钻表格点击
    drillCellClick(row, column, cell, event) {
      if (column.property === 'name') {
        return
      } else {
        this.drillRowName = row.name
        this.drillColumnProperty = column.property
        this.$nextTick(() => {
          this.$refs['editInput'].focus()
        })
      }
    },
    // 添加从孔
    handleAddHole() {
      this.gangDrillCombinationAuxiliaryList = this.drillTableData
        .filter((e) => e.name.includes('从孔'))
        .map((e) => ({
          knifePrefix: e.prefix,
          knifeSuffix: e.suffix,
          webId: e.webId,
        }))
      this.gangDrillCombinationAuxiliaryList.push({
        knifePrefix: '',
        knifeSuffix: '',
      })
      this.formatDrillData(this.obj, true)
    },
    handleDeleteHole() {
      this.gangDrillCombinationAuxiliaryList.pop({
        knifePrefix: '',
        knifeSuffix: '',
      })
      this.formatDrillData(this.obj, true)
    },
    changeBenchDrill(row, type) {
      if (row.number == '') {
        this.$message({
          message: this.$t('cuttingDock.drillSettings.changeBenchDrillTip'),
          offset: 60,
          type: 'info',
          duration: 1000,
        })
        return
      }
      if (row._selected == false) {
        this.formData.drills.forEach((v) => {
          v._selected = false
        })
        this.formData.benchmarkDrill = ''
      }
      if (row._selected == true) {
        this.formData.drills.forEach((v) => {
          v._selected = false
        })
        row._selected = true
        this.formData.benchmarkDrill = row.number
      }
    },
    addNewKnife() {
      this.formData.drills.push({
        diameter: '',
        feedRate: '',
        isUsed: true,
        moveF: '',
        moveZF: '',
        number: '',
        number2: '',
        originNumber2: '',
        origin: '',
        xOffset: '',
        yOffset: '',
        [webId]: genUniId(),
      })
    },
    deleteKnife() {
      this.formData.drills.splice(this.currentRow.index, 1)
    },
    //把每一行的索引加到行数据中
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    //把每一列的索引加到列数据中
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    cellClick(row, column, cell, event) {
      if (column.property == 'isUsed' || column.property == 'benchDrill') {
        return
      } else {
        this.rowIndex = row.index
        this.columnIndex = column.index
        this.currentRow = row
        if (column.property != 'type') {
          this.$nextTick(() => {
            this.$refs['editInput'][0].focus()
          })
        }
      }
    },
    inputBlur(row, prop) {
      this.rowIndex = -1
      this.columnIndex = -1
      this.drillRowName = ''
      this.drillColumnProperty = ''
      if (row && prop == 'number2' && row.number2 != row.originNumber2) {
        buryPointApi('equipment_dock', 'gang_drill_number2')
      }
    },
    cancelBenchDrill() {
      if (this.currentRow.number == '') {
        this.currentRow._selected = false
      }
    },
  },
}
</script>

<style scoped lang="less">
.gang-drill {
  // margin: 24px 24px 0 24px;
  .head-title {
    display: flex;
    align-items: center;
    background: #fff;
    span {
      margin-right: 1rem;
      font-weight: 400;
      font-size: 1.25rem;
    }
  }
  .gang-drill-table {
    box-sizing: border-box;
    // margin-top: 1.5rem;
    margin-top: 16px;
    padding: 1.5rem 1.5rem 1rem 1.5rem;
    background: #fff;
    .table-box {
      height: 33.0625rem;
      max-height: 33.0625rem;
    }
  }
  .code-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    background: #fff;
    span {
      cursor: default;
    }
    textarea {
      display: block;
      width: 29.125rem;
      height: 7.0625rem;
      margin-top: 0.5rem;
      border: 0.0625rem solid #dddddd;
      border-radius: 0.25rem;
      resize: none;
    }
    & > div:nth-child(2n + 1) {
      margin-right: 8rem;
    }
    & > div:first-child {
      margin-bottom: 1.5rem;
    }
    & > div:nth-child(2) {
      margin-bottom: 1.5rem;
    }
    span {
      color: #000;
      font-weight: 400;
      font-size: 1rem;
    }
  }
  .drilling-part {
    background-color: #fff;
    .drilling-title {
      .el-checkbox {
        /deep/ .el-checkbox__inner {
          width: 20px;
          height: 20px;
        }
        /deep/ .el-checkbox__inner::after {
          height: 12px;
          width: 7px;
          top: 0;
          left: 5px;
        }
      }
    }
    .drilling-content {
      display: flex;
      .left-table {
        width: 541px;
        margin-right: 50px;
        margin: 20px 50px 20px 0;
        .drill-fix {
          margin-bottom: 0;
        }
      }
      .right-text {
        .drill-fix {
          margin-bottom: 0;
        }
      }
    }
  }
  .process-setting {
    padding: 24px;
  }
}
</style>
