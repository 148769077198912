export default {
  common: {
    checkPlankIsConflict: 'Checking plank status',
    preferenceSaveErr: 'Error saving preferences',
    guimen: 'Cabinet Door',
    preferenceLoadTip: 'Loading your preferences...',
    preferenceLoadErrTip:
      'Failed to load preferences. Please refresh and try again to avoid incorrect preference settings!',
    slotKnifeErr:
      'The slot-specific processing tool is missing. Please check the production line settings! 【{msg}】',
    downImageErrTip:
      'Exporting images failed because the enabled labeling feature divides images into different folders based on the large board. You need to select the small board labels to export together.',
    downNcErrTip:
      'Downloading NC failed because the enabled labeling feature divides images into different folders based on the large board. You need to select the small board labels to export together.',
    localFileIsBeingWrite: 'Local file is being written',
    taskMonitor: 'Task Monitoring',
    downloadByType: 'Download {type}',
    anewChooseCatalog: 'Re-select directory',
    downloadSetting: 'Download settings',
    attention: 'Attention',
    errpb: 'An error occurred, please try again',
    unit: 'Pieces',
    defaultUse: 'Default Use',
    defaultChooseUse: 'Default Choose Use',
    requestFail: 'Request Failed',
    downloadDoneByType: '{type} download completed',
    sysTip: 'System Tip',
    pleaseChoose: 'Please choose',
    confirmDelete: 'Confirm Deletion',
    notDataTipByType: 'No data available, please {msg} first',
    paginationTotalTip: 'Items {current}-{page} of {total} total',
    chooseAmountData: 'Selected {number} items of data',
    moreOperate: 'More',
    transferByType: 'Transfer {type}',
    clickCopy: 'Click to copy {type}',
    currentLine: 'Current Line',
    remark: 'Remark',
    customFunction: 'Custom Function',
    limitedTimeTrial: 'Limited Time Trial',
    startDate: 'Start Date',
    endDate: 'End Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    lastMonth: 'Last Month',
    lastSixMonth: 'Last Six Months',
    lastYear: 'Last Year',
    searchByType: 'Please enter {type} to search',
    addTime: 'Add Time',
    startArrange: 'Start Arranging',
    select: 'Select',
    name: 'Name',
    plankNo: 'Board Number',
    plankName: 'Board Name',
    orderNo: 'Order Number',
    order: 'Order',
    plankRemark: 'Board Remark',
    room: 'Room',
    orderAddress: 'Order Address',
    addressEditErr:
      'Modification of project addresses is not supported for multiple orders',
    customerEditErr:
      'Modification of customer information is not supported for multiple orders',
    factoryEditErr:
      'Modification of factory order numbers is not supported for multiple orders',
    roomNameEditErr:
      'Modification of room names is not supported for multiple orders',
    remarkEditErr:
      'Modification of order remarks is not supported for multiple orders',
    matCode: 'Material Code',
    matCode2: 'Material',
    plankMatCode: 'Board Material',
    count: 'Count',
    customer: 'Customer',
    customerInfo: 'Customer Information',
    factoryOrderNo: 'Factory Order Number',
    roomName: 'Room Name',
    color: 'Color',
    plankColor: 'Board Color',
    plankThick: 'Board Thickness',
    plankHighGloss: 'Highlight Properties',
    thick: 'Thickness',
    front: 'Front',
    back: 'Back',
    slot: 'Slot',
    hole: 'Hole',
    hsInfo: '{hole} Hole {slot} Slot',
    diameter: 'Diameter',
    finishedWidth: 'Finished Width',
    finishedHeight: 'Finished Height',
    texture: 'Texture',
    normal: 'Vertical Texture',
    reverse: 'Horizontal Texture',
    notCare: 'No Texture',
    holeSlotsStastic: 'Hole and Slot Count',
    edge: {
      title: 'Edge',
      info: 'Edge Information',
      front: 'Front Edge',
      back: 'Back Edge',
      left: 'Left Edge',
      right: 'Right Edge',
      up: 'Upper Edge',
      down: 'Lower Edge',
    },
    isSpecial: 'Special',
    notSpecial: 'Not Special',
    isDoorPlank: 'Door Board',
    notDoorPlank: 'Not a Door Board',
    width: 'Width',
    long: 'Length',
    plankWidth: 'Board Width',
    plankHeight: 'Board Length',
    shortLong: 'Short Side Length',
    shortWidth: 'Short Side Width',
    plankShape: 'Plank Shape',
    detail: 'Detail',
    cancel: 'Cancel',
    confirm: 'Confirm',
    alreadyKnow: 'I already know',
    Noprompts: 'No further prompts',
    deep: 'Depth',
    other: 'Other',
    heighGloss: 'High Gloss',
    heighGlossPlank: 'High Gloss Board',
    notHeighGloss: 'Not High Gloss',
    notHeighGlossPlank: 'Not a High Gloss Board',
    saveSuccess: 'Save Success',
    deleteSuccess: 'Delete Success',
    addSuccess: 'Add Success',
    editSuccess: 'Edit Success',
    engraving: 'Engraving Machine',
    electronicSaw: 'Electronic Saw',
    glassEquip: 'Glass Cutting Machine',
    labelingMachine: 'labeling machine',
    copy: 'Copy',
    paste: 'Paste',
    undo: 'Undo',
    move: 'move',
    PTP: 'PTP',
    dock56FDrill: '5/6-Face Drilling Machine',
    sideHoleDrill: 'Side Hole Drilling Machine',
    combination: 'Combination Machine',
    integration: 'Integration Machine',
    standardPlank: 'Standard Board',
    standardMaterial: 'Standard Material',
    specialMaterial: 'Special Material',
    specialPlank: 'Special Board',
    singlePlank: 'Single-Sided Board',
    previous: 'Previous',
    next: 'Next',
    rate: 'Utilization Rate',
    paibanBatch: 'Batch',
    return: 'Return',
    FGNo: 'Contract Number',
    refresh: 'Refresh',
    inde: 'Index',
    productionLine: 'Production Line',
    operation: 'Operation',
    colorPlaceHolder: 'Press enter to create a color swatch',
    saveAndContinue: 'Save and Continue',
    reset: 'Reset',
    check: 'Query',
    inputPlaceholder: 'Please enter',
    filter: 'Filter',
    all: 'All',
    exportPDF: 'Export PDF',
    exportPic: 'Export Image',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    saveSetting: 'Save Settings',
    isHeightGloss: 'Please select if it is a high-gloss board',
    noSync: 'Not Synced',
    sync: 'Synced',
    formatSuccess: '{type} Successful',
    dataLoading: 'Data is loading...',
    warmTip: 'Friendly Reminder',
    wellcome: 'Welcome to Cloud Typesetting',
    timeOut: 'Typesetting timed out, do you want to typeset again?',
    warning: 'Warning',
    export: 'Export',
    mark: 'Mark',
    noMark: 'No Mark',
    sawArrange: 'Sawing Typesetting',
    directArrange: 'Direct Typesetting',
    area: 'Area',
    plankNum: 'Board Part Barcode',
    loc: 'Cabinet',
    cutHeight: 'Cutting Length',
    cutWidth: 'Cutting Width',
    defaultProduction: 'Default Production Line',
    tip: 'Tip',
    history: 'History',
    hinge: 'Hinge Hole',
    CCPXHole: 'Three-in-one eccentric hole',
    stretchHinge: 'Straightener slot and hinge cup hole',
    hingeScrew: 'Hinged door panel pilot holes',
    stretch: 'Straightener',
    stretcher: 'Straightener Slot',
    clothole: 'Clothes Rod Hole',
    backPlank: 'Backboard Slot',
    ALS: 'Hidden Pull Slot',
    doorSlot: 'Door Metal Slot',
    drawerSlot: 'Drawer Metal Slot',
    KC: 'Trousers Drawer Hole',
    stealth: 'Invisible Component',
    lkSlot: 'Lock groove',
    lightSlot: 'Light Slot',
    allHole: 'All Holes on the Specialty Board',
    allSlot: 'All Slots on the Specialty Board',
    millInfo: 'Mill Handle',
    FSDrill: 'Five/Six-Face Drilling Machine',
    engraving2: 'Engraving Machine',
    xyReverse: 'XY Axis Swap',
    xyReverseTip:
      'The XY axis interchange function is currently not compatible with the correct placement of L-shaped residual material gaps. Please use it with caution',
    rect: 'Rectangle',
    Ltype: 'L-Shape',
    archive: 'Archive',
    tellPlaceholder: 'Please enter your phone number',
    knivesStore: 'Knife Storage',
    roll: 'Flip board piece',
    pause: 'Pause',
    editTrans: 'Edit Translation',
    translateManager: 'Translation Manager',
    gratingSlot: 'Grating Slot',
    toMaterialList: 'Go to Material List',
    deleteFail: 'Deletion failed, please try again later!',
    batchDelete: 'Batch Delete',
    workShop: 'Workshop Production',
    FGAdrress: 'Project Address/Contract Number',
    cuttingSize: 'Cutting Size',
    finishedSize: 'Finished Size',
    labelImg: 'Label Image',
    layoutImg: 'Layout image',
    file: 'file',
    folder: 'Folder',
    reName: 'Rename',
    to: 'to',
    plankUnit: 'sheet',
    tagTemp: 'Label Template',
    roomRemark: 'Room Remarks',
    noModificationPermission: 'No modification permission',
    modificationRecord: 'Modification Record',
    page: 'page',
    strip: 'strip',
    jump: 'jump',
    freeAgreement: 'Software disclaimer agreement',
    preStep: 'pre Step',
    nextStep: 'Next Step',
    upload: 'upload',
    uploading: 'uploading',
    iAgree: 'I Agree',
    pleaseSelectPlank: 'Please select at least one board',
    saveToStoreTip: 'Please select at least one non-cut-out piece',
    fileIsNotExist: 'file does not exist',
    selectAll: 'Select All',
    pdfTag: 'PDF Tag',
    tagImage: 'Tag Image',
    projectName: 'Cloud Typesetting',
    guigui: 'guigui',
    gratingSlot: 'Grating Slot',
    deleteTip:
      'After deleting this data, it cannot be recovered. Are you sure you want to delete this data?',
    deleteError: 'An unknown error occurred and the deletion failed',
    cancelDelete: 'Deletion canceled',
    confirmDeleteTip:
      'After deleting, the board will be entered in the lower right corner of the page in the "Board to be typeset - not stored", whether to confirm the deletion of the data?',
    deleteEmptyTip: 'Whether to delete this large board',
    notCanDelete: 'The cropped remaining materials do not support warehousing',
    new: 'New',
    innerBluneFailure: 'Inline cloud typesetting does not take effect',
    deleteBigPlankTip:
      'A large slab must be kept under the current material color thickness and cannot be deleted!',
    continueExport: 'Ignore and proceed to the output file',
    toCheck: 'Go check',
    pleaseCheck:
      'Please check: The current layout result has hole-slot conflict/flying out small plate/depth anomaly. Please check!',
    clampHandCheck:
      'Detected some of your plate hole slot can not avoid the clamp position, please adjust manually',
    conflictPartDetail: 'Details of conflicting plates',
    colorNotUndefined: 'The color can not be empty',
    materialNotUndefined: 'The material can not be empty',
    newSetting: 'New',
    yes: 'yes',
    no: 'no',
    checkColorPrint:
      'At present, the color label has been turned on, please use the color printer to print~',
    colorRules:
      'After turning on the generation of color labels, orders/rooms/cabinets/door panels will be distinguished by color, and the maximum number of color labels supported for the same type of data is 12, the specific rules are as follows:',
    greyPart: 'The gray part of the label on the right is',
    region: 'region',
    notSame: 'The color of the {regionName} region is different',
    doorTip: 'Grey is the door panel, white is the non-door panel',
    exist: 'exist',
    roomName: 'room',
    loc: 'Cabinets',
    limitNotEffect:
      'If there are more than 12 data, the color label will not take effect',
    hoverTip:
      'Images with the bmp suffix are encoded in black and white, and cannot be output with color labels',
    lock: 'Lock',
    unlock: 'Unlock',
    batchLock: 'Batch Lock/Unlock',
    surplusLock: 'Surplus Material Lock',
    surplusUnlock: 'Surplus Material Unlock',
    is: 'yes',
    not: 'no',
    savingHistory: 'Saving history file',
    saveHistorySuccess: 'Save history file successfully',
    saveHistoryFail: 'Failed to save the history file',
    bw: 'Whether to export black and white labels',
    modular: 'Unit cabinets',
    autoAdjust: 'Auto-adjust',
    cutOrderTip:
      'An error has been detected in your 【{errorCutOrdersString} slab】 cutting order! Please make adjustments before downloading the NC file',
    lockedCutOrderTip:
      '【The {errorCutOrdersString} board】is locked, please go to manually modify it',
    autoAdjustWarning:
      'The current typesetting result does not support automatic adjustment',
    checkNetwork: 'Please check your network',
    moreInfo: 'See more information',
    continueLayout: 'Continue layout',
    adjustPosition: 'Adjust position',
    specialAndFiveSix:
      'When processing irregular shapes with five/six-sided drilling, enabling irregular nesting may cause the board to be cut. Please confirm whether to continue the layout?',
    plankPathErr:
      'Internal plank irregularity error, please check if it meets the tool specifications!【{plankIDList}】',
  },
  home: {
    head: {
      production: 'Product Introduction',
      resolve: 'Solutions',
      about: 'About Us',
      equipment: 'Equipment',
      tell: 'Complaint Hotline',
      enter: 'Enter Cloud Typesetting',
    },
    swiper: {
      case1: {
        tag: 'Newly Upgraded Version',
        title: 'Cloud Typesetting',
        subTitle: 'The Intelligent Brain of Machines',
        description:
          'A cloud-based online typesetting optimization software revolutionizing the industry',
      },
      case2: {
        title: 'Cabinet Production',
        subTitle: 'Technological Innovation',
        description:
          'Supports integration with various labeling machines, cutting machines, side-hole machines, five-sided PTP, and five/six-sided drilling machines',
      },
      case3: {
        title: 'Industrial Brain',
        subTitle: 'Solutions',
        description:
          'Intelligent typesetting algorithm achieves higher material utilization rates',
      },
    },
    statistics: {
      today: "Today's Scheduling",
      toMonth: 'Monthly Scheduling',
      outturn: 'Yield Rate',
      cumulative: 'Cumulative Users',
    },
    intro: {
      title:
        'Enterprise-grade Big Data Cloud-based Online Typesetting Software',
      subTitle:
        'Insights into industry trends, focusing on customer needs, enabling digital transformation and upgrading of enterprises, driving the automation of the internet furniture production industry. Introducing AI-assisted algorithms for the existing panel furniture industry production scenarios, continuously contributing to new growth for enterprises.',
      menue: {
        case1: {
          title: 'Online Typesetting',
          subTitle: 'Typesetting at any time',
          description:
            'No need to download software, typesetting can be done at any time, remote control without relying on a computer.',
        },
        case2: {
          title: 'Multi-line Production',
          subTitle: 'Quick switching between production lines',
          description:
            'Supports rapid processing on multiple production lines simultaneously, with quick switching between production lines.',
        },
        case3: {
          title: 'Precise Production',
          subTitle: 'Precise typesetting and parameter settings',
          description:
            'Precise typesetting and precise parameter settings, making panel production more accurate.',
        },
        case4: {
          title: 'Custom Production',
          subTitle: 'Custom labels, five/six-sided drilling, scrap shapes',
          description:
            'Custom labels, custom five/six-sided drilling, custom scrap shapes, combined equipment processing.',
        },
      },
    },
    fangan: {
      title: 'Industry Professional Solutions',
      subTitle:
        'Low cost, high output value, intelligent algorithms increase panel utilization, compatible with various design software, providing users with the optimal production plan.',
      menue: {
        case1: {
          title: 'Integrated Online Order Reception and Production',
          description:
            'Remote online operation, overcoming spatial obstacles, orders can be synchronized to the factory at any time, design and production completed synchronously.',
        },
        case2: {
          title: 'One-click Switching between Production Lines',
          description:
            'Customizable production lines, supporting multiple production lines and multiple types of equipment docking.',
        },
        case3: {
          title: 'Intelligent Algorithm Improves Panel Utilization',
          description:
            'Traditional algorithms have low panel utilization, intelligent algorithms provide precise parameter settings, customized production plans.',
        },
        case4: {
          title: 'Professional Cutting Solutions',
          description:
            'Optimize cutting sequence and cutting points, making the path shorter and cutting more stable.',
        },
      },
    },
    aboutUs: {
      title1: 'Xingeer Human Settlements Technology',
      subTitle:
        'Leader in the Transformation of the Furniture Manufacturing Industry',
      description:
        'Xingeer Enterprise, focused on realizing the internet transformation of the traditional furniture manufacturing industry.',
      culture: {
        case1: {
          title: 'Enterprise Purpose',
          subTitle: 'Help factories achieve triple growth',
        },
        case2: {
          title: 'Cultural Values',
          subTitle:
            'Customer-centered, based on the efforts of employees, hard work, benefiting the people',
        },
        case3: {
          title: 'Guiding Ideology',
          subTitle:
            'Mass line, building an elite team with sales force, product force, and organizational force',
        },
      },
      feature: {
        case1: 'Professional Team Maintenance',
        case2: 'Continuous Updates and Iterations',
        case3: 'One-on-one After-sales Service',
        case4: 'Customized Functionality Service',
      },
    },
    cooperation: {
      title: 'Sincere Cooperation, Creating a Shared Future',
      subTitle:
        'The docking equipment brands cover 90% of the commonly used domestic industrial equipment, with 107,554 merchants joining us.',
    },
    footer: {
      contact: {
        title: 'Contact Us',
        adrres:
          'Address: No. 50 Xiuxi, Binhu District, Wuxi City, Jiangsu Province',
        subAdrres: 'K-park Service Center, Building 1, 16th Floor',
        tell: 'Phone',
      },
      business: {
        title: "Xin Ge Er's Core Business",
        department1: 'Door and Window Software Department',
        department2: 'Furniture Software Department',
        department3: 'Prefabricated Construction Department',
        department4: "Xin Ge Er's Official Website",
      },
      brand: {
        title: "Xin Ge Er's Affiliate Brands",
        brand1: 'Cai Shen Jin Fu',
        brand2: 'Men Tu Wang',
        brand3: 'Ding Xin Investment',
        brand4: 'San Zhang Tu Technology',
        brand5: 'Shanghai Fen Mo Technology',
      },
      organization: {
        title: "Xin Ge Er's Global Branches",
        organization1: 'Xin Ge Er - South China Bureau (Foshan Branch)',
        organization2: 'Xin Ge Er - North Bureau (Linyi Branch)',
        organization3: 'Xin Ge Er - Southwest Bureau (Chengdu Branch)',
        organization4: 'Xin Ge Er - GG Western Service Center',
        organization5: 'Xin Ge Er - GG Eastern Service Center',
        organization6: 'Xin Ge Er - Agent Cluster',
        organization7: 'Xin Ge Er - International Market Department',
      },
      follow: {
        title: 'Scan to Follow GG',
      },
      friendLink: {
        link1: 'Xin Ge Er Software',
        link2: 'K Show Purchasing Software',
        link3: 'Men Tu Wang',
        link4: 'All Aluminum Cabinet Software',
        link5: 'ZOL Software Download',
        link6: 'Hua Jun Software Park',
        link7: 'Download.com',
        link8: 'ZOL Software Download',
        link9: 'Green Resource Network',
        link10: 'Wardrobe Franchise',
        link11: 'Home Franchise',
        link12: 'American Standard Bathroom Franchise',
      },
    },
  },
  login: {
    title: 'Login',
    tell: 'Phone Number',
    password: 'Password',
    passwordPlaceholder: 'Please enter your password',
    forget: 'Forgot Password?',
    register: 'Register an Account',
    backLog: 'Go Back to Login',
    verifyCode: 'Verification Code',
    getVerifyCode: 'Get Verification Code',
    confimPassword: 'Confirm Password',
    confimPasswordPlaceholder: 'Please confirm your password',
    editPassword: 'Change Password',
    register: 'Register',
    reSend: 'Get a new code',
    loginLoadText: 'Logging in, please wait...',
    loadingPageTextL: 'Login successful, loading page structure...',
    loginSuccess: 'Login Successful',
    tellErr: 'Invalid phone number format',
    passwordErr: 'Please enter a password',
  },
  main: {
    head: {
      title: 'Bluen',
      subTitle: 'Save time, materials, and effort',
      task: 'Receive Task',
      arrange: 'Typesetting',
      surplus: 'Scrap Materials',
      setting: 'Settings',
      find: 'Discover',
      upgrade: 'Upgrade',
      editNickName: 'Edit Nickname',
      editPassword: 'Change Password',
      logout: 'Log Out',
      notice: 'Announcements',
      enterOfficial: 'Visit the Official Website',
      equipment: 'Equipment',
      help: 'Help',
      enterThinkerOfficial: 'Enter the Xin Ge Er Official Website',
      history: 'Save History',
      plankManager: 'Stock Management',
      tagManager: 'Label Management',
      suplumentManager: 'Supplement Management',
      fileManager: 'File Management',
      productionLine: 'Equipment Integration',
      workeShop: 'Device Online',
      useLesson: 'Usage Tutorial',
      videoLesson: 'Video Tutorial',
      prolineManager: 'Production Line Management',
      materialManager: 'Material Management',
    },
    sideBar: {
      title: 'Production Optimization',
      materialList: 'Cutting List',
      paibanResult: 'Typesetting Result',
      printTag: 'Print Labels',
      history: 'Save History',
      limitDate: 'Trial',
      surplusManager: 'Scrap Management',
      translateManager: 'Translate Management',
      moreSettings: 'More Settings',
    },
    allRead: 'All Read',
    learnMore: 'Learn More',
    noticeEmpty: 'No Announcements Available...',
    noticeErr: 'Error Fetching Detailed Announcements',
    feedBack: {
      tab1: 'Submit Feedback',
      tab2: 'Feedback History',
      type: 'Feedback Type',
      typePlaceholder: 'Please Select a Feature',
      description: 'Description',
      descriptionPlaceholder:
        'Hi! Please provide a detailed usage scenario description of at least 15 words to help us better assist you. Thank you for your cooperation!',
      uploadPic: 'Add Pictures (Max 6 images, each no larger than 10MB)',
      tell: 'Contact Number',
      submit: 'Submit',
      admin: 'Cloud Typesetting Administrator:',
      picTip: 'Image: (Click to expand and view image details)',
      empty: 'No Feedback Data Available',
      picErr: 'Each image should not exceed 10MB',
      contentErr: 'The number of characters should be between 15 and 300',
      necessary: 'This field is required',
    },
  },
  taskPage: {
    tab1: 'Production Task',
    tab2: 'Mass production queue',
    tab3: 'Archive',
    filter: {
      title: 'Filter',
      unArrange: 'Not formatted',
      arranged: 'Arranged',
      allArranged: 'All Arranged',
      preLayout: 'Pre-layout',
    },
    search: {
      placeholder: 'Please enter the search content',
      orderName: 'Order Name',
      sender: 'Sender',
    },
    importTask: 'Import Task',
    refuseTask: 'Refuse task',
    refreshList: 'Refresh List',
    refuseRecord: 'Refusal Record',
    taskCard: {
      sender: 'imported material list',
      arranged: 'has been formatted',
      matCode: 'Material',
      texture: 'texture',
      delivery: 'Delivery Date',
      color: 'Color',
      delete: 'Delete',
      addToBatch: 'Add to Batch',
      save: 'Save',
      arrange: 'Directly arrange',
      deleteTitle: 'Delete Task',
      checkDetail: 'Click to view details',
      refuse: 'Refuse',
      refuseTime: 'Refusal Time',
      refuseReason: 'Reason for Refusal',
      refuseTask: 'Refuse Task',
      refuseTaskPlaceholder: 'Please enter the reason for refusal',
      addErr: 'Failed to add, please try again later!',
    },
    batchNo: 'batch number',
    batchArrange: 'batch arranging',
    clearBatch: 'Clear the queue',
    taskCount: 'Current task count:',
    checkAllTask: 'View all material lists',
    clearSuccess: 'Clearing succeeded',
    record: {
      batchNo: 'batch',
      saveDate: 'Save time',
      materialList: 'Material List',
      arrangedRecord: 'arranged scheme',
      delete: 'Delete',
      searchPlaceholder: 'Please enter the batch number',
      tipTitle: 'Tip',
      tipText: 'Delete this archive or not?',
      confirm: 'Confirm',
    },
    errTip:
      'No task data available for processing, please go to the task list to add!',
    emptyErr:
      'The task order has no plank data, unable to proceed with layout production!',
    operationSuccess: 'Operation successful',
    operationFail: 'Operation failed, please try again later!',
    saveSuccess: 'Save successful',
    plankMaxLimit:
      'The number of small planks exceeds the maximum layout limit',
    batchWarning: 'Please produce in batches',
    batchEmptyErr:
      'No plank data in the order list, unable to proceed with batch production!',
    noRoomTip: 'No rooms exist, unable to proceed with batch production!',
    refuseHistory: 'Refuse history',
  },
  materialPage: {
    labelBackProcTip:
      'This update has optimized the labeling method on the back of the large board. Now, you can directly seal the edges and punch slots according to the information on the label. Please be informed!',
    edgeEmptyTip: 'Trim value cannot be empty',
    edgeNumberLimit: 'Trim value can only be a number',
    chooseSurplusQuantity:
      'There are {total} types of available surplus materials, {quantity} types selected',
    chooseBujianStoreQuantity:
      'There are {total} supplementary pieces, {quantity} supplementary planks can be included, {chooseQuantity} planks selected',
    isConfirmPushToAwaitStore:
      'Are you sure you want to add the selected planks to the awaiting layout warehouse?',
    enterAutoCreateMatCode: 'Press Enter to auto-create material',
    chooseAwaitStoreQuantity:
      'There are {total} small planks in the awaiting layout warehouse, {quantity} planks can be included in the layout, {chooseQuantity} planks selected',
    defaultFold: 'Parts Data Default Collapsed',
    startArrange: 'Start Arranging',
    importMaterial: 'Import Material List',
    saveToHistory: 'Save to History',
    addPlank: 'Add Panel',
    deletePlank: 'Delete Panel',
    batchEdit: 'Batch Edit',
    moreSetting: 'More Settings',
    plankSize: 'Panel Size',
    plankSize2: 'Size (Length * Width * Thick)',
    searchPlaceholder: 'Search Panel Name/Panel Number',
    checkArranged: 'View Layout',
    checkErr: 'No arrangement has been made, unable to view!',
    downloadSheet: 'Download Template',
    downloadSheetShowScientific:
      'Download sample table (showing Scientific notation)',
    exportHoleSlotPDF: 'Export Panel Hole and Slot Diagram',
    style1: 'Style 1',
    style2: 'Style 2',
    style3: 'Style 3',
    importNoCover: 'Import Material List without Overwriting',
    openBujianPlankRecommend: 'Open the patch panel recommended',
    openAwaitStorePlankRecommend:
      'Open the library to be formatted small board recommendation',
    openSurplusPlankRecommend:
      'Open the remaining material large board recommended',
    pushToAwaitStore: 'Store in the library to be formatted',
    setTableHead: 'Set Material List Header',
    syncConfirm:
      'The original data between the cabinet system and the cloud arranging system is inconsistent! Do you want to synchronize with the cabinet system?',
    handleSync:
      'Note: Manual synchronization can be performed in the original sheet management',
    noMoreTip: 'No More Prompts',
    checkMore: 'View More',
    noHistory: 'No Saved History...',
    sameOrder:
      'There are orders with exported production files. Please be aware of duplicate production issues!',
    markOrder: 'Mark as Produced on the Order Page?',
    editErrTip: 'Multi-order modifications are not supported at this time',
    autoSaveSuccess: 'Autosaved to History!',
    goAdd: 'Go to Add',
    noProductionLine:
      'The production line for this record has been deleted. Please rearrange in the Material List',
    fileErr: 'Failed to parse file content. Please confirm the file content!',
    noCheckData: 'No data found!',
    noArrangeTip:
      'Cannot view the arrangement plan directly. Please click "Start Arranging"!',
    tooOld: 'The five-six-sided drilling settings are too old',
    contactUs: 'Please contact our after-sales personnel for reconnection',
    pathErr:
      'There is abnormal data inside the panel (Panel Number: {errorPathPlankID}). Please check!',
    sizeErr:
      'There is abnormal data inside the panel, please check! Panel size：{width}-{height}',
    noKnife:
      'The cutting tool diameter has not been set. Please go to the engraving machine to set the tool!',
    sendFactorySuccess: 'Successfully sent to the workshop',
    sendFactoryErr:
      'Failed to send to the workshop. Please contact our after-sales personnel for assistance',
    createTaskCard: 'Do you want to create a corresponding task card?',
    surplusStatistics: 'Total available surplus material is',
    surplusStatistics2: 'Selected',
    suplementStatistic:
      'supplements, can be accompanied by supplement boards in total ',
    expand: 'Expand',
    packUp: 'Collapse',
    importErr: 'Please select the correct file!',
    sendLoadingText: 'Processing and sending NC files...',
    arrangeLoading: 'A total of',
    arrangeLoading2: 'panels are being optimized for arrangement...',
    surplusHead: {
      surplusName: 'Surplus Material Name',
      shape: 'Shape',
      type: 'Type',
      remark: 'Notes',
      area: 'Area (m²)',
    },
    surplusTip:
      'There is no available surplus material at the moment. You can go to',
    creatSurplus: 'Create New Surplus',
    orderInfo: 'Order Information',
    suplement: {
      statistic:
        'There are {total} replacement parts in total, {useable} of which can be used as spare panels, and {selected} have been selected.',
      exportSuplement: 'Export Replacement Parts List',
      suplementLoading: 'Loading Replacement Parts Data',
      exportSuplementIng: 'Exporting Supplement List',
      noMatchSup: 'No matching usable supplemental data found',
      exportTip: 'Please select at least one supplement before exporting',
    },
    empty: 'No panel data available',
    paibanSetting: {
      title: 'Layout Settings',
      selectProduction: 'Production Line Selection',
      arrangeWay: 'Layout Plan Selection',
      suitText: 'Suitable for',
      suitEquipmentNormal: 'Regular Engraving Machine',
      suitEquipmentSpecial: 'Glass Cutting Machine, Sawing Machine',
      lessRoll: 'Less Roll',
      computedSpecial: 'Calculate Irregular Shapes',
      specialTip: 'Low Speed',
      irregularNesting: 'Irregular Nesting',
      primaryNesting: 'Primary Nesting',
      specialShapedPanel: 'Special Shaped Panel',
      limitedTimeTrial: 'Limited Time Trial',
      seniorShape: 'Senior Shape',
      heteromorphicInlay: 'Heteromorphic Inlay',
      autoCutSurplus: 'Automatic Scrap Cutting',
      cutWay: 'Cutting Method',
      maxRect: 'Cut into Maximum Rectangle',
      fixedRect: 'Cut into Fixed Size',
      minRectSetting: 'Cutting Minimum Size',
      firstCutL: 'Prioritize L-shaped remnant generation',
      firstCutLTip:
        'When enabled, it will prioritize cutting rectangular sheets into L-shaped remnants if conditions are met',
      shortLine: 'Short Side Longer than',
      longLine: 'Long Side Longer than',
      surplusTemplate: 'Common Scrap Templates',
      add: 'Add',
      templateErr:
        'When selecting "Cut into Common Size", at least one data template must be selected.',
      cutLong: 'Cutting Length',
      cutWidth: 'Cutting Width',
      baseInfo: 'Basic Information',
      thick: 'Panel Thickness',
      continue: 'Confirm and Continue',
      standardSelect: 'Standard Large Tile Selection',
      customPlankOrder: 'Custom large board layout order',
      customPlankOrderDetail: 'UnArranged small board list',
      customPlankOrderTip:
        'The embedded cloud layout does not take effect for this feature',
      surplusPlank: 'Remaining small board list',
      overSizePlank: 'Over-sized board list',
      plankOrder: 'Layout order',
      plankType: 'Board type',
      plankSize: 'Board size',
      plankEdgeOff: 'Trimming value',
      amount: 'Quantity of cutting materials',
      amountMax: 'Maximum cutting quantity',
      numAndOverSizeTip:
        'There are small boards that exceed the size limit and insufficient large boards that cannot be typeset',
      numErrorTip: 'Insufficient usage of large boards to layout',
      overSizeTip:
        'There is a small board that exceeds the size limit and cannot be typeset',
      restPartList: 'Unarranged Small Board List',
      layoutOrder: 'Layout Order',
      arrangeAmount: 'Cutting Quantity',
    },
    filter: {
      title: 'Panel Filtering',
      search: 'Search',
      all: 'Select all',
      resetFilter: 'Cancel Filtering',
    },
    edit: {
      title: 'Batch modification',
      target: 'Modify object',
      onlineSelectRow: 'Only select the row',
      allRow: 'All Rows',
      editKey: 'Contents to be modified',
      editValue: 'change to',
      warningTip:
        'Attention: the hole groove and irregular shape will be automatically cleared after modification',
      allEdge: 'All Edges',
    },
    add: {
      title: 'Add Board',
      continue: 'Confirm and continue',
      matCodeErr: 'The material cannot be empty',
      thickErr: 'Thickness cannot be empty',
      colorErr: 'The color cannot be empty',
      longErr: 'The length of the finished product cannot be empty',
      widthErr: 'Finished product width cannot be empty',
      countErr: 'The quantity must be at least 1',
    },
    delete: {
      title: 'Important Note',
      tip: 'The data cannot be restored after deletion. Are you sure you want to delete it? ',
      errTip:
        'Please select at least one board before performing this operation!',
      confirm: 'Confirm deletion',
    },
    save: {
      errTip: 'There is no information to save!',
      title: 'Save Scheme',
      subTitle: '(Note: history is only saved for one year)',
      name: 'Scheme Name',
      historyName: 'Historical scheme',
      successTip: 'Save successfully!',
      fieldTip: 'Save failed!',
    },
    exportErr: 'No board is available for export at the moment',
    exportPicErr:
      'An unknown error occurred while exporting the board hole slot diagram. Please contact us for resolution',
    plankNotFound:
      'No relevant board found! Please confirm whether to draw the board!',
    noPlankName: 'No board name found',
    plankDataErr:
      'The plank data is incorrect. Please check carefully to avoid production losses',
    exportThreeViewErr:
      'Failed to export the three views of the hole slot. Please contact after-sales for handling',
    heighGlossWarning:
      'Note: When the material is a high gloss plate, the plate cannot be restored to a high gloss plate after modifying the material!',
    reset: 'Restore default',
    settingHead: {
      title: 'Set the header of the cutting list',
      head: 'Header',
      example: 'sample',
      tip: ' Operation tips: Drag the header data label left and right to change its position ',
    },
    copyLine: 'Click to copy the current line below',
    copySuccess: 'Copying {name} data succeeded',
    arranging:
      'A total of {count} small boards are being optimized for layout...',
    overSize: {
      title: 'Panel oversized',
      subTitle: 'List of oversized plates',
      overSizeTip: 'Over-size tip for plate',
      nonWorkInfo: 'Details of the unprocessable board',
      conflictWorkInfo: 'Details of the conflicting plates',
      warning:
        'It is detected that the small board size is too long, resulting in an inability to format! Please adjust the small board size or add corresponding size originals',
      checkInfo: 'View the details of the board',
      tipContent:
        'The system has detected that there are oversized plates in the layout template. Below are suggestions for adding special plates. You can manually modify the length and width of the plates and click the One-click Add button below the table to quickly add them and continue with the layout. Alternatively, you can manually add plates in the Original Sheet Settings - Special Plates',
      add: 'One-click add',
      addPlank: 'Add original piece',
      error: 'The board is oversized, and the layout failed',
      successTip: 'The board is added successfully! Re-arranging the layout',
      warningTip: 'Please fill in the length and width of the board!',
    },
    arrangeErr:
      'There are anomalies in the internal data of the board, and it is impossible to arrange production',
    nonDelete: 'Cannot Delete',
    addOverSizeTip: 'Oversize exists, please add it again',
    addPlankSuccess: 'Add Special Board successfully',
    addPlankFail: 'Failure to add special board',
    openDir: 'Opening direction',
  },
  arrangedPage: {
    slotErrTip:
      'The current panel has edge machining grooves, and re-layout is not supported!',
    batchBujianErrTip:
      'The current part does not exist in the original material list data, you can select it from the replenishment/awaiting layout library for re-layout',
    disPlaySetting: 'Display setting',
    useFolderIsNot:
      'It is detected that the file download save location "{type}" does not exist! Please check if the file has been deleted. If using a USB drive, please ensure it is properly connected',
    useFolderIsNot1:
      'It is detected that you have not set a file download location, please select a directory first',
    downloadNC: 'Download NC',
    downloadNC1: 'Download NC file',
    printTag: 'Print Label',
    reArrange: 'Re-Arrange',
    saveToHistory: 'Save to History',
    moreSetting: 'More',
    followExportTag: 'Download NC with Label File Export',
    tagTemplateSelect: 'Label Template Selection',
    showCuttingSequence: 'Show Cutting Sequence',
    showPlankSize: 'Show Sheet Size',
    sendToFactory: {
      title: 'Send to Workshop for Production',
      fileType: 'Label File Type',
    },
    checkData: 'Checking data integrity...',
    dataLoading: 'Loading...',
    addToBujianStore: 'Adding to Spare Parts Warehouse...',
    updateTaskStatus: 'Updating Task Status...',
    sending: 'Sending to workshop...',
    waitLoadding: 'Waiting',
    loadding: 'Loading',
    finish: 'Finished',
    loadSetting: 'Load Settings',
    loadPreference: 'Load Preferences',
    dealTemplate: 'Process Template',
    initTagData: 'Initialize Tag Data',
    prepareDrawData: 'Prepare Label Drawing Data',
    drawDataText: 'Label drawing data',
    genPdfTagFile: 'Generate PDF Tag File',
    genPicTagFile: 'Generate a label image file',
    uploadFile: 'Upload File',
    dealNCFile: 'Process NC File',
    zipFile: 'Generate Local Zip File',
    plankCount: 'Number of Large Sheets',
    rollCount: 'Number of Revisions',
    totalRate: 'Overall Optimization Rate',
    rate: 'Optimization Rate',
    useArea: 'Optimized Area',
    materialList: 'Material List',
    saveSurplus: 'Save Scrap Material',
    workeShop: 'Purchase Order',
    holeSlotErr: 'Detected abnormal hole slot depth on the board piece!',
    knivesErr: `The processing tool can't be found when the plate is detected!`,
    checkDetail: 'Check Details',
    deepErr: {
      title: 'Abnormal Depth of Board Hole Slot',
      errStatistic: '{count} abnormalities found on the sub-board',
      errText: 'Abnormal Part',
      markErrPart: 'Mark the Abnormal Part',
    },
    warningTip:
      'For double-sided polished boards: Process the <span class="font-style">glossy side</span> first, then process the <span class="font-style">matte side</span>; For single-sided polished boards: Process the <span class="font-style">matte side</span> directly.',
    showDetail: {
      tip1: 'Click to select a single board piece',
      tip2: 'View board piece label',
      edgeInfo: 'Edge information',
    },
    arrangeTip: '(Supports irregular nested layout)',
    plankOpration: 'Board piece operations',
    rotate: 'Rotate 90 degrees',
    deletePart: 'Delete board piece',
    notCare: 'Board piece has no texture',
    editRemark: 'Edit remarks',
    clearRemark: 'Clear remarks',
    recalcIndex: 'Recalculate cutting points and order',
    recalcIndexSuccess:
      'Recalculation of the drop point and cutting sequence successfully',
    storageArea: {
      title: 'Temporary board piece storage area',
      empty: 'No board pieces stored',
      storageTip: 'Drag board pieces here to temporarily store them',
    },
    subtleArrange: 'Fine layout',
    addPlank: 'Add large sheet',
    deletePlank: 'Delete large sheet',
    deletePlankErr:
      'Cannot delete the large sheet as there are smaller sheets on it!',
    useSurplus: {
      title: 'Use scrap material',
      search: 'Search',
      placeholder: 'Please enter',
    },
    dragWarning: 'Please drag the board piece onto the large sheet!',
    areaWarning:
      'Unable to place the board piece. Please check the available space.',
    subtlePage: {
      cut: 'Scrap cutting',
      cancelCut: 'Cancel cutting',
      cutPoint: 'Scrap cutting adsorption point',
      saveSurplus: 'Save scrap material',
      save: 'Save scheme',
      storageArea: 'Temporary storage area',
      cutErr:
        'Cutting can only be performed on the large sheet, and cannot touch the smaller sheets!',
      saveErr: 'Please select a scrap material to cut first',
      cutErr2:
        'Cutting can only be performed on the main board and cannot exceed the main board!',
      selectTip: 'Please select a surplus material plank for cutting',
      noCutPath: 'Irregular parts cannot cut rectangles',
      cutOperate: 'Remaining material cutting operation',
      line: 'Manual stroke',
    },
    shortCut: 'Shortcut keys',
    updownRoll: 'Scroll up and down',
    leftRightRoll: 'Scroll left and right',
    zoomScale: 'Zoom in and out',
    dragCanvas: 'Drag the page',
    mouseRoll: 'Mouse scroll wheel',
    mouseRight: 'Right mouse button',
    doublePlank: 'Double-click the large sheet',
    keyboard: 'Keyboard',
    picSymble: 'Legend',
    funished: 'Finished product',
    holeSlotConflict1: 'Hole and slot conflict',
    plankErr: 'Board piece conflict',
    frontSlot: 'Front slot',
    backSlot: 'Back slot',
    frontHole: 'Front hole',
    backHole: 'Back hole',
    resetScale: 'Reset zoom',
    maxLong: 'Maximum length',
    tempErr:
      'Failed to add. Template position is incorrect or template is too large!',
    sendErr:
      'Failed to send to workshop, please check if the tag template and plank data are correct before resending',
    someTips:
      'You are currently using the trial version of cloud layout for custom layout, please be careful to avoid production problems!',
    cutOrderErr:
      'Incorrect cutting order, please click to recalculate the knife point and cutting order!',
    holeSlotConflict:
      'Hole slot conflict detected: Board has hole-slot conflict or flying out small plate',
    exportSetting: 'Export Settings',
    specialPaibanErr:
      'Some planks have special processes, re-layout is not supported for now!',
    moreResult: 'More Layout Results',
    moreResultTip:
      'The large panel has a slot for edge processing, so it will not participate in re-layout',
    batchBujian: 'Batch Patching',
    stored: 'Stored',
    onlySurplus: 'View Only Surplus',
    UnuseSurplus: 'Utilization rate does not include excess material',
    usePlankAdsorption: 'Plank adsorption function',
    dataDefaultCollapsed: 'Data is collapsed by default',
    unit: 'Pieces',
    cutSurplus: 'Cut Surplus',
    frontWarningTip:
      'For double-sided processing gloss board: process <span class="font-style">matte surface</span> first, then process <span class="font-style">glossy surface</span>; &nbsp; For single-sided processing gloss board: process directly <span class="font-style">glossy surface</span>',
    selectTip: 'Please select planks that need to be patched below',
    plankKeyWordPlaceholder: 'Please enter the plank name or number',
    surplusStoreSuccess: 'Surplus stored successfully',
    editCutOrder: 'Edit cutting order',
    setCutPoint: 'Set knife point',
    specialPaibanChangeErr:
      'Some planks have special processes, layout switching is not supported for now!',
    storedSuccess:
      'Patching successful! Selected planks have been added to patch management!',
    noSurplus: 'No available surplus found',
    selectErrTip:
      'Surplus planks cannot be patched, please select normal planks first',
    noRoomIDTip:
      'This plank does not have a room ID, cannot be patched, please select other planks first',
    noPlank: 'Plank not found',
    noPaibanData: 'No layout data, unable to proceed',
    historyNameErr: 'Please enter the plan name',
    tempErrTip:
      'Detected that you have not added templates for five/six-sided drilling, thus unable to export five/six-sided drilling processing files. Note: After adding, you need to re-layout',
    changeDataTip:
      'Note: Detected changes in your data, whether to create a new history file, and save this change content?',
    upgrade: 'Upgrade',
    upgradeTip: ', you can click the button below to upgrade',
    plankOverEdge:
      'Failed to download NC! Please check if there is overlap between planks or if the planks are outside the large plank',
    useSurplusTip: 'Whether to deduct surplus',
    noDataExport: 'No layout data available for export',
    noPlankExport: 'No planks available for export',
    lockTip1: 'Click to unlock, after unlocking you can',
    lockTip2: 'edit plank position and attributes',
    unlockTip1: 'Click to lock, once locked, it cannot be accessed',
    unlockTip2: 'edit the position and attributes of the small board',
    plankLockErr: 'This large plank has been locked and cannot be placed',
    lockPartEditTip: 'Plank is locked and cannot be edited',
    glassEquipmentTip:
      'When the production line is a glass cutting machine, the plank cannot be moved',
    dragErrTip:
      'Cannot place, please check plank material, color, and thickness attributes!',
    subtleSaveTip:
      'Changes have been made to the plank but not saved, do you want to exit?',
    rollTip:
      'There are through holes/slots on the large plank, flipping is not recommended!',
    glossRollErr: 'Glossy planks are prohibited from rolling',
    lockDeleteErr: 'Plank is locked and cannot be deleted',
    deleteConfirmTip: 'Deletion operation cannot be undone',
    deleteContinueTip: 'Do you want to continue?',
    deleteConfirm: 'Please confirm deletion',
    selectPlankTip: 'Please select plank before performing this operation',
    storeFailTip:
      'Surplus storage failed! Please check if there is overlap between planks or if the planks are outside the large plank',
    repeatStoreErr: 'The current surplus plank has been stored before',
    surplusStoreFail:
      'Storage failed, please check if the plank is rectangular or L-shaped!',
    plankConfilictErr:
      'Cannot place points here, will conflict with other planks',
    surplusErrTip:
      'Cannot place points here, will produce an incorrect surplus',
    changeArrageSuccess: 'Successfully switched layout scheme',
    trialProduction: {
      trialProductionSample: 'Trial production sample cabinet',
      trialProduced: 'I have put it into trial production',
      trialWarning:
        'Due to your failure to verify the production of the sample cabinet, all losses and adverse consequences to yourself, others and cloud typesetting software will be borne by you personally',
      pleaseUploadTrial:
        'Please upload the photo of the assembled sample cabinet for trial production',
      uploadTrial: 'Upload sample cabinet',
    },
    awaitPlankStore: {
      store: 'Board warehouse',
      notTip: 'No longer indicates that it is not in the library',
      justLookCurrent: 'Just look at the current order small board',
      notInPushStorage: 'Not put in storage',
      beInStorage: 'Be in storage',
      searchPlaceholder: 'Enter an order to search the library panel',
      plankUploading: 'The board is saved',
      oneClickStorage: 'One click to save to the waiting board library',
      notUsePlank: 'No board available',
      notPlank: 'No board is temporarily stored',
      dragPlankInHere:
        'You can drag the board here to temporarily store the board',
      surplusCannotBeStorage: 'Surplus materials cannot be stored',
      plankHasBeenDeduction:
        'The small board has been reduced or there is no original data, can not be stored',
      plankLoadErr: 'Failed to load board information. Procedure',
      taskTitle: 'Upload the board to be typeset in the library',
      dataGenErr: 'Data generation error',
      pushSuccess: 'Warehousing success',
      pushErr: 'An unknown error occurred. The repository failed',
      awaitBoxExistPlank:
        'The inventory to be typeset is in the small board that has not been stored, it is recommended to operate after storage',
      checkPlankStatus: 'Checking board status...',
      knivesModalTitle: 'The tool can not be found in the existence of a slot',
      cannotDownloadByKnivesErr:
        'Can not download NC, the system detected the existence of the following slot could not find the tool',
      viewPart: 'View the problem panel',
    },
    batchSetCut: 'Batch Set Cut Points',
    applyTo: 'Apply To',
    ncDownloadLifeText: {
      checkData: 'Checking data',
      checkDone: 'Data check completed',
      checkDataText: 'Data Detection',
      loadSetting: 'Loading settings',
      settingLoadDone: 'Settings loaded',
      loadSettingText: 'Settings Loading',
      downloadNc: 'Downloading NC',
      ncDownloadDone: 'NC download completed',
      downloadText: 'Download NC',
      loadPreferenceSetting: 'Loading preference settings',
      PreferenceSettingLoadDone: 'Preference settings loaded',
      loadPreferenceText: 'Load Preferences',
      dealTemp: 'Processing template',
      TempDealDone: 'Template processing completed',
      dealTempText: 'Process Template',
      beBeingGenTagFile: 'Generating {fileType} file',
      genTagFileDone: '{fileType} file generated',
      genTagFileText: 'Generate {fileType} file',
      zip: 'Generating local zip file',
      zipDone: 'Local zip file generated',
      genZipText: 'Generate a compressed file',
      fileTypeText: 'Image',
    },
    surplusCommonTemp: 'Surplus Common Template',
    plankErrTip:
      'The current material plan has small boards with no available large boards for layout!',
    overSizeTip: 'Insufficient large boards or oversized',
    uselessPart: 'No usable small boards on the current large board',
    splitArranged:
      'Too many small boards, it is recommended to arrange in batches',
    arrangedErrTip:
      'Layout failed due to insufficient large boards or oversized small boards',
    deductionModalTitle: 'Please select the deduction object',
    bujianDeduction: 'Deduct and supplement small boards',
    paibanDeduction: 'Deduction of temporary storage area small board',
    surplusDeduction: 'Deduction of surplus material large board',
    unlockPaiban: 'Unlock the remaining materials involved in typesetting',
    maintain:
      'Maintain the lock of remaining materials involved in typesetting',
    lockModalText:
      'Is there a need to rearrange the layout of the leftover large board that has been occupied by other orders',
    lockNewSModalText:
      'The current surplus material board is occupied by other orders. Do you want to continue downloading NC',
    rePaibanWarn:
      'The surplus large boards are occupied by other orders, and there is a shortage of available large boards. Please go to the material list to reformat',
    sawRePaiban:
      'Attention: You are using a saw blade for cutting, and have added a layout for irregular plates. You need to reformat them to output NC files',
  },
  historyPage: {
    download: 'Download Locally',
    allType: 'Save All',
    handleSave: 'Manual Save',
    autoSave: 'Auto Save',
    allArrange: 'All Layout',
    arranged: 'Laid Out',
    unArrange: 'Not Laid Out',
    searchPlaceholder: 'Search Order Name/Batch',
    trailProduction: {
      title: 'Trial Production Sample Cabinet',
      lastTime: 'Last Trial Production Date',
      recordList: 'Trial Production Records',
      start: 'Start Trial Production',
      date: 'Time',
      outputFrom: 'Output Location',
      empty: 'No Data Available',
      record: 'Uploaded Trial Production Sample Cabinet Image Records',
      tip1: 'Note: The current trial production order is using default production data',
      tip2: 'Note: The current trial production order is not using the latest process settings parameters. You can go to the trial production room to save them again!',
      download: 'Download the processing file',
    },
    rename: 'Rename',
    historyName: 'Scheme Name',
    deleteTip: 'Are you sure you want to delete the following history records?',
    startDate: 'Start Date',
    endDate: 'End Date',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
    lastSeason: 'Last Three Months',
    toArrangePage: 'Navigating to Layout Page',
    notArrangedTip:
      'This record has not been arranged yet, please go to the material list first!',
    toMaterialPage: 'Navigating to Material List Page',
    lessSelectTip:
      'Please select at least one record before performing this action',
    downloadNc: 'Download Processing File',
  },
  workShopPage: {
    warningTip:
      'This feature is a limited-time trial. Please arrange your order production reasonably!',
    fileManager: 'Production File Management',
    date: 'Save Time',
    allSelect: 'Select All',
    selected: 'selected',
    suplement: 'Supplement',
    size: 'Size',
    saveToStore: 'Add to Supplement Warehouse',
    enterArrange: 'Entering Layout...',
    arrange: 'Start Layout',
    suplement: 'Supplement Warehouse',
    deleteTip:
      'After deleting this data, it cannot be restored. Are you sure you want to delete this data?',
    arrangeTip: 'Analyzing supplement data, please wait...',
    store: 'Supplement Warehouse',
    downloadFile: 'Download Production File',
    notBujianFile: 'Supplementary file not found',
    productLineDelete:
      'This production line has been deleted, NC file download unavailable!',
  },
  basePlankManager: {
    saveTip: 'Note: Press Enter to save',
    offsetSize: 'Trimming Size',
    overSizeTip:
      'When the size is too large, automatically select a larger sheet size for layout',
    addTip:
      'If the board piece exceeds the allowed size during layout, a popup will appear to automatically add a special large sheet',
    onlyLayoutTooBig: 'Super small plate only in the super large plate',
    settingRemark: 'Note: the function here is adjusted to the right setting',
    searchPlaceholder: 'Search for board material/color',
    syncData: 'Synchronize cabinet data',
    deletePlank: 'Delete special large sheet',
    addPlank: 'Create new special sheet',
    matCodePlaceholder: 'Please select a board material',
    thickPlaceHolder: 'Please select a thickness',
    widthErr: 'Board width cannot be empty',
    heightErr: 'Board length cannot be empty',
    matCodeErr: 'Board material cannot be empty',
    colorErr: 'Board color cannot be empty',
    editPlank: 'Edit sheet',
    deleteTip:
      'After deleting this data, it cannot be recovered. Are you sure you want to delete this data?',
    plankKnifeSelect: 'Original sheet cutting knife selection',
    knifeSelectTip: 'Note: This feature is not supported for nested layout',
    knife: 'Cutting Tool',
    matcodeInput: 'Please enter the board material',
    thickInput: 'Please enter the board thickness',
    default: 'System Default',
    unessential: 'This column is optional',
    emptyTip:
      'Note: If this item is not filled in, it will not be distinguished when matching the original sheet!',
    defaultUse: 'Default Selection',
    editMatcode: 'Edit Material',
    syncTip:
      'Will get data from {fromMc} and overwrite the original sheet management data in the existing cloud layout. Do you want to synchronize?',
    sameErr: 'The same data already exists',
    cancelDelete: 'Deletion canceled',
    syncErr: 'Synchronization failed',
    syncSuccess: 'Synchronization successful',
    syncDataTip: 'Synchronize ${fromMc} data',
    editErrTip: 'There are unfilled information, please check!',
    numberCheck: '{type} can only input numbers!',
    deleteStandardPlate: 'Removal of standard plate',
    createStandardPlate: 'New standard board',
    editStandardPlate: 'Edit Standard Plate',
    trimmingDimension: 'Trimming Dimension',
  },
  bujianAndStore: {
    bujianStore: 'Bujian Warehouse',
    awaitStore: 'Plank Awaiting Layout Warehouse',
    depositAwaitStore: 'Deposit into Awaiting Layout Warehouse',
    pushToAwaitStore: 'Push to Awaiting Layout Warehouse',
    useTwoTableArrange: 'Use Two Tables for Layout',
    confirmUseTwoTableTip:
      'The selected small planks include both the Bujian and Awaiting Layout warehouses. Do you want to continue with the layout?',
    confirmAddPlankToAwaitStore:
      'Are you sure you want to add the selected planks to the Awaiting Layout warehouse?',
    pushStoreSuccess: 'Successfully added to warehouse',
    dataGenErr: 'Data generation error',
    unknownErr: 'Unknown error occurred, failed to add to warehouse',
  },
  surplusPage: {
    surplusMaterial: 'Surplus Material List',
    surplusStore: 'Surplus Material Warehouse',
    surplusShape: 'Surplus Shape',
    batchTransfer: 'Batch Transfer',
    createNewSurplusStore: 'Create New Surplus Warehouse',
    pushStoreSetting: 'Surplus Storage Settings',
    surplusStoreName: 'Surplus Warehouse Name',
    surplusStoreName2: 'Surplus Warehouse Name',
    addSurplusStore: 'Add Surplus Warehouse',
    inventoryCeiling: 'Inventory Ceiling',
    autoTransferTip:
      'After deletion, surplus materials in the warehouse will be automatically transferred to the surplus material list',
    asIndexPushStore:
      'Store in order according to warehouse storage conditions',
    pushToSurplusMaterial: 'Store in Surplus Material List',
    surplusStoreTable: {
      number: 'Number',
      name: 'Surplus Warehouse Name',
      quantity: 'Stored Surplus Quantity (pcs)',
      capacity: 'Surplus Inventory Ceiling (pcs)',
      remark: 'Remark',
      operation: 'Operation',
    },
    editSurplusStore: 'Edit Surplus Warehouse',
    addSurplus: 'Add Scrap',
    deletSurplus: 'Delete Scrap',
    editSurplus: 'Edit Surplus',
    surplusNoRoll: 'Enable Scrap Processing Without Re-rolling',
    type: {
      all: 'All Shapes',
    },
    allMatcode: 'All Materials',
    allThick: 'All Thicknesses',
    surplusName: 'Scrap Name',
    rectSurplus: 'Rectangular Surplus',
    LTypeSurplus: 'L-shaped Surplus',
    noMatchSup: 'No relevant data found',
    jumpTo: 'Jump to',
    page: 'Page',
    newMatcode: 'Create New Material',
    newColor: 'Create New Color',
    autoCreateColor: 'Press Enter to automatically create a color card',
    nameLimit: 'Surplus name can contain up to 10 characters',
    nameLimitNameErr: 'Surplus name cannot be empty',
    tempLongTip: 'Plank length can only contain numbers',
    minLongLimit: 'Short side length can only contain numbers',
    minLongEmptyTip: 'Short side length cannot be empty',
    minLongLimitMax: 'Short side length cannot exceed plank length',
    widthLimit: 'Plank width can only contain numbers',
    minWidthLimit: 'Short side width can only contain numbers',
    minWidthEmptyLimit: 'Short side width cannot be empty',
    minWidthLimitMax: 'Short side width cannot exceed plank width',
    thickLimit: 'Plank thickness can only contain numbers',
    thickEmptyLimit: 'Plank thickness cannot be empty',
    countInputLimit: 'Quantity must be a positive integer',
    countEmptyLimit: 'Quantity cannot be empty',
    remarkMaxlimit: 'Remarks can contain up to 50 characters',
    createTime: 'Creation Time',
    branchName: 'Surplus Material Warehouse',
    lockStatus: 'Locked State',
    lockOrder: 'Locked Order',
    enableSurplusLock: 'Enable the surplus material locking feature',
    isCloseSurplusLock:
      'After the feature is turned off, all surplus materials will be unlocked. Do you wish to continue',
    confirm: 'Confirm to close',
    surplusTransfer: 'Transfer of surplus materials',
    targetSurplusStore: 'Target surplus material warehouse',
    enableSurLockTip:
      'After activation, the remaining material used for each typesetting will be locked to prevent it from being occupied by other orders',
  },
  printPage: {
    print: 'print',
    defaultTemplate: 'Default Template（60 x 40）',
    editTemplate: 'Edit Template',
    deleteWarning: 'Important Note',
    deleteTip:
      'Deleting this template cannot be restored. Are you sure you want to delete it?',
    tagSetting: 'Label Setting',
    rememberSetting: 'The setting will be remembered after modification',
    genCutingTag: 'Generate the label for the cutting board',
    genSurplusTag: 'Generate surplus tag',
    genPlankTag: 'Generate a large board tag',
    genPartTag: 'Generate Small Plate Labels',
    tagPosition: 'Label Position',
    tagPositionTip:
      '(Only the labeling machine needs to fill in the X/Y coordinates below)',
    Xposition: 'X coordinate',
    Yposition: 'Y coordinate',
    genPlankSuffix:
      'The suffix of the file name for the large board label generation',
    arrangeMirror: 'Label image mirror',
    drillPlankBase: 'Five or six drill-and-place plate benchmarks',
    suitAuto: 'Label sorting adapts to automatic loading and unloading:',
    colorLabel: 'Generate color labels',
    up: 'upward',
    down: 'downward',
    default: 'Default',
    printSetting: 'Print Settings',
    oneToMore: 'One page with multiple pages',
    perPage: 'Each',
    coloumn: 'column',
    tagMargin: 'Label Gap',
    coloumnMargin: 'Column spacing',
    specialMark: 'special mark',
    opacity: 'transparency',
    suite: 'Adaptive',
    size: 'Size',
    symble: 'Legend',
    lightSide: 'See the light side',
    locFrontSide: 'front side of the cabinet',
    openSide: 'Opening direction',
    extraTip:
      ' Note: When using direct printing for the first time, please add a new sheet of paper with the same size and reversed length and width in the printer settings, and then use the new paper for printing. For example, the original paper size is 60×40, and the new paper size is 40×60',
    noMore: 'No more',
    prepareData: 'Preparing data...',
    genTagFile:
      'Generating tags, please do not switch pages, minimize the browser, or scroll the tags up and down!',
    waitGen: 'Please wait for the tags to load completely before downloading',
    tempLoading: 'Loading tag template, please wait',
    front: 'Front',
    open: 'Open',
    toEdit: 'Go to Edit',
    simplifyOneCodeTip:
      'Note: The barcode properties set in this tag template do not support the "Simple Barcode" function, please modify the barcode properties (recommended barcode property: CODE128)',
    plankTemp: 'Small Plank Tag Template',
    surplusTemp: 'Surplus Material Tag Template',
    tempNamePlaceHolder: 'Please enter the template name',
    editTip:
      'You can edit the tag template by dragging the buttons from the left to the tag paper on the right according to your needs',
    oneCode: 'Barcode',
    image: 'Image',
    editPage: {
      title: 'Label Template Editing',
      reset: 'Reset to Defaults',
      exit: 'Exit Editing',
      tagInfo: 'Tag Information',
      tagName: 'Tag Name',
      tagSize: 'Tag Size',
      tagData: 'Tag Data',
      dragTip: 'Drag with the left mouse button to create a data tag',
      dataTip: 'Select data information from the property panel on the right',
      text: 'Text',
      dataSource: 'Data Source',
      graph: 'Graphic',
      typograpyPic: 'Typography Layout',
      barCode: 'Barcode',
      QRCode: 'QR Code',
      plankMark: 'Plate Mark',
      holeSlotPic: 'Hole and Slot Diagram',
      holeSlotPicZoom: 'Hole Slot Picture Zoom',
      rotateDeg: 'Rotation Angle',
      colorDeep: 'Color Depth',
      surplusMark: 'Surplus Material Mark',
      surplusSizePic: 'Surplus Material Size Picture',
      dataSize: 'Data Size',
      extraList: 'Additional List',
      QRCodeFormat: 'QR Code Format',
      formatQR: 'QR Format',
      formatDM: 'DM Format',
      locIndex: 'Cabinet Index',
      plankFrontSign: 'Panel Mark',
      genNCSuffix: 'Generate NC Suffix',
      table: 'Table',
      inputPlaceholder: 'Double-click to enter text',
      attribute: 'Attribute',
      fontFamily: 'Font Family',
      fontSize: 'Font Size',
      fontWeight: 'Font Weight',
      autoRow: 'Auto Wrap Text',
      rowMargin: 'Row Spacing',
      autoFontSize: 'Automatically Adjust Font Size on Overflow',
      statisticsDimension: 'Statistical Dimensions',
      noLimit: 'Unrestricted',
      sameOrderNo: 'Same Order',
      sameRoom: 'Same Room',
      sameWardrobe: 'Same Wardrobe',
      alignWay: 'Alignment',
      JustifyLeft: 'Left-Aligned',
      JustifyRight: 'Right-Aligned',
      JustifyCenter: 'Centered',
      otherOpration: 'Other Operations',
      attributeReset: 'Reset Attributes',
      barCodeSize: 'Barcode Size',
      installPic: 'Installation Diagram',
      plankCode: 'Plate Barcode',
      plankCode2: 'Plate Barcode (noMark)',
      frontCode: 'Front Barcode',
      backCode: 'Back Barcode',
      orderRemark: 'Order Remarks',
      NCFileName: 'Cutting File Name',
      NCFileNameTip:
        'The NC file name should not contain Chinese characters and should not exceed 10 characters in length',
      showOpenSide: 'Show Door Opening Direction',
      limitFixed: 'Limit Decimal Places',
      showFrontHS: 'Show Front Hole Slot',
      showBackHS: 'Show Back Hole Slot',
      showPlankSize: 'Show Plank Size',
      tempNamePlaceholder: 'Please enter the label name',
      deleteAreaTip: 'Please select the area to delete',
      noHoleSlot: 'Show Barcode Even without Hole and Slot',
      rotate: 'Rotate Barcode',
      barcodeAtrribute: 'Barcode Attributes',
      imgSize: 'Image Size',
      noEdgeDisplay: 'Do Not Display if Edge is 0',
      allEdgeNoDisplay: 'Edge banding is not displayed',
      imgRotate: 'Image Rotation',
      circleD: 'Circle Frame Diameter',
      roomNo: 'Room Number',
      roomOrder: 'Cabinet Order (Sorted by Room)',
      alphabetically: 'Sort Alphabetically',
      numberOrder: 'Sort Numerically',
      specialMarkup: 'Special Marking Symbols',
    },
    autoSaveToHistory: 'Automatically saved to history!',
    firstPrintTip:
      'When using direct printing for the first time, please add a new paper size with swapped length and width in the printer settings, then use the newly added paper for printing',
    tagDownloading: 'The label is downloading...',
  },

  fileManagerPage: {
    notOpeFolderPermission: 'Unauthorized operation of directory',
    notFindFolder:
      'The current directory cannot be detected on the current client',
    productLineUniversal: 'Universal for all production lines',
    effectCondition:
      'This setting change will take effect only after re-layout',
    addPath: 'Add path',
    downloadPositionChangeByType:
      'The download location for the generated files has been changed to {type}',
    isDeleteFolder:
      'Are you sure you want to delete the used file storage location?',
    netWorkIsNotSafe:
      'Your network environment seems to be unsafe, we cannot enable this feature, you can provide feedback to us',
    NotSupportBrowse:
      'Your browser does not currently support this feature. Please download the latest version of the <a href="https://www.microsoft.com/en-us/edge/download" target="_blank">Edge</a> browser to use it.',
    notSupportDisk:
      'System-level directories such as [Desktop] and [C Drive] cannot be selected',
    useFolderDownload: 'Production files are downloaded in an unzipped format',
    downloadPosition: 'Download file location',
    changeUseFolder: 'Change',
    folderNameSetting: 'Folder Naming Settings',
    mainFolder: 'Primary Folder',
    exportNameSetting: 'Export Processed Files with Order Number Naming',
    fileMax: 'Maximum File Name Length',
    char: 'Characters',
    overTip:
      'Excess part will not be displayed (Label graphics zip and json files will follow the primary folder naming)',
    example: 'Example',
    engravingFolder: 'Engraving Machine Folder',
    electronicSawFolder: 'Electronic Saw Folder',
    labellingFolder: 'Labeling Machine Folder',
    sideHoleDrillFolder: 'Side Hole Drilling Machine Folder',
    fiveDrillFolder: 'Five-Sided Drilling Machine Folder',
    sixDrillFolder: 'Six-Sided Drilling Machine Folder',
    PTPFolder: 'PTP Folder',
    labellingTagFolder: 'Labeling Image Folder',
    folderMergeSetting: 'Folder Merging Settings',
    folderMerge: 'Folder Merging',
    mergeName: 'Merged Name',
    subMergeFolder:
      'Merge into subfolders based on material, color, and thickness:',
    subFolderName: 'Subfolder Name',
    splitChar: 'Separator',
    splitTip: '(Unsupported characters include / : ? < > | . ” *)',
    folderAutoAdd: 'Automatic Increment Starting Value for Folder Count',
    resetTip: '(Resets to the starting value at midnight every day)',
  },

  // 更多设置页面
  moreSettings: {
    generalSetting: 'General setting',
    outSyncTip:
      'When the cloud typesetting original film data is not synchronized with the cabinet cabinet, a pop-up window is generated',
    secondarySwitchShow:
      'The secondary content of the switch control in the engraving machine setting is still displayed after the switch is turned off',
    exPaibanTip: 'Generates prompt pop-ups when using hetero-nested typography',
    surpTagThresholdTextHalf1:
      'When the area of the surplus material (not cutting surplus material) is less than',
    surpTagThresholdTextHalf2: ' do not generate a surplus material label',
  },

  lineManager: {
    add: 'Add Production Line',
    engraving: 'Cutting Equipment',
    holeSlotEquip: 'H&S Equipment',
    workSetting: 'Processing Settings',
    noUse: 'Not Enabled',
    reName: 'Rename',
    warningTip:
      '* Note: After setting the following functions, please do not save on the cabinet client device docking page to avoid resetting the parameters!',
    engravingSetting: 'Cutting Machine Settings',
    workWay: 'Processing Method',
    cutedSpecial: 'Special Holes and Slots for Processing:',
    noWork:
      'This option does not apply to the default template for five/six-sided drilling',
    onlyCut: 'Only Cutting',
    onlyFrontHoleSlot: 'Only Process Front Holes and Slots of Boards',
    onlyHoleSlot: 'Only Process Front and Back Holes and Slots of Boards',
    sideHoleSetting: 'Side Hole Machine Settings',
    cutHoleSlot: 'Processed Holes and Slots',
    minSize: 'Short side dimension',
    maxSize: 'Long side dimension',
    minSize2: 'All holes, slots, and special shapes on boards with a size of',
    singleHole: 'Single Hole',
    singleSlot: 'Single Slot',
    diameterRange: 'Hole Diameter Range',
    hDeepRange: 'Hole Depth Range',
    widthRange: 'Slot Width Range',
    sDeepRange: 'Slot Depth Range',
    to: 'to',
    specialHS: 'Special Holes, Slots, and Shapes',
    allowRoll:
      'Allow Cutting Machine to Process Special Holes and Slots by Turning Over the Board',
    engravingPath: 'Process Special Parts of Boards on the Cutting Machine',
    drillSetting: 'Five/Six-Sided Drilling Settings',
    engravingCuted:
      'Processed Holes and Slots on the Cutting Machine for Five/Six-Sided Drilling',
    noGen: 'Do Not Generate',
    genCut: 'Generate and Process',
    genNoCut: 'Generate But Do Not Process',
    cutTip:
      'Note: This option only applies to custom five/six-sided drilling files',
    maxShortSize: 'Maximum Short Side Length',
    maxLongSize: 'Maximum Long Side Size',
    normalSlot: 'Regular Slot',
    specialSlot: 'Special Slot',
    combinSetting: 'Combined Equipment Settings',
    allowShortSize: 'Allow Short Side Length',
    equipment1: 'Equipment 1',
    equipment2: 'Equipment 2',
    noSameTip: 'Note: The two equipment cannot be the same',
    workRange: 'Processing Range for Equipment 1',
    rangeWarning:
      'Note: Only the processing range for Equipment 1 can be set. All non-Equipment 1 processing ranges will be processed on Equipment 2. Please proceed with caution!',
    deleteWarning:
      'After deleting this production line, it cannot be recovered (all settings for this production line will be deleted). Are you sure you want to delete this production line?',
    top: 'Top',
    inputCheck:
      'The minimum value of {msg} cannot be greater than the maximum value',
    lockTip: 'The production line is locked and cannot be operated!',
    reNameTip: 'The default production line cannot be renamed',
    permissonTip:
      'Your identity has expired. Please reopen this page from the cabinet!',
    permissonErr:
      'You do not have this permission. Please upgrade your account version!',
    permissionOneMacErr:
      'You currently have access to {idx} production line for the integrated machine. If you need to add more, please contact the salesperson!',
    newLine: 'New Production Line {idx}',
    deleteCheck: 'Cannot delete all',
    deving: 'Under Development',
    combinTip: 'Please set up the combined equipment below',
    noUseEquip: 'Processing Equipment Not Enabled',
    equipmentSelectTip:
      'When selecting combined equipment with a cutting machine, the cutting equipment cannot be set to an electronic saw',
    lineLoading: 'Data Loading',
    strenchType: 'Straightener Slot Type',
    aioProcessTip:
      'You have enabled the "All-in-One" device, processing mode defaults to processing both sides of the planks with holes and slots',
    aioProcessTip2:
      'After enabling the All-in-One, special holes and slots will be processed in the carving machine section',
    aioProcessTip3:
      'Note: This function is not compatible with carving machines that have enabled the XY axis swap function. Please use with caution',
    specialHoleTip:
      'Note: When using the above "special hole slot processing" function, please set the "through-hole drilling method" and "through-slot penetration method" to drill through at once',
    specialHoleTip2:
      'Note: When using the above "special hole slot processing" function, please set the carving machine’s "through-hole drilling method" and "through-slot penetration method" to drill through at once',
    fiveSixNotTake:
      'This option does not apply to the default templates for five/six-sided drilling',
    onlyFrontSide: 'Process front side only',
    onlyBackSide: 'Process back side only',
    bothFrontBack: 'Process both front and back sides',
    shareDataErrTip: 'This data is applied by {operatorPhone} , IP: {actionIP}',
    restoreParams: 'Restore parameters',
    loadMore: 'Load more',
  },

  cuttingDock: {
    title: 'Cutting Machine Docking',
    other: 'Others',
    autoStockCode: 'Automatic loading and unloading code',
    stationStart: 'Station Start',
    stationStartCode1: 'Station 1 Start Code',
    stationStartCode1Placeholder: 'Enter Station 1 Start Code',
    stationStartCode2: 'Station 2 Start Code',
    stationStartCode2Placeholder: 'Enter Station 2 Start Code',
    stationEnd: 'Station End',
    stationEndCode1: 'Station 1 End Code',
    stationEndCode1Placeholder: 'Enter Station 1 End Code',
    stationEndCode2: 'Station 2 End Code',
    stationEndCode2Placeholder: 'Enter Station 2 End Code',
    frontBackStart: 'Front and Back Start',
    frontBackEnd: 'Front and Back End',
    frontStartCode: 'Front Start Code',
    frontStartCodePlaceholder: 'Enter Front Start Code',
    frontEndCode: 'Front End Code',
    frontEndCodePlaceholder: 'Enter Front End Code',
    backStartCode: 'Back Start Code',
    backStartCodePlaceholder: 'Enter Back Start Code',
    backEndCode: 'Back End Code',
    backEndCodePlaceholder: 'Enter Back End Code',
    plankStart: 'Plank Turn Start',
    plankEnd: 'Plank Turn End',
    plankRollCode1: 'Station 1 Turn Code',
    plankRollCode1Placeholder: 'Enter Station 1 Turn Code',
    plankRollCode2: 'Station 2 Turn Code',
    plankRollCode2Placeholder: 'Enter Station 2 Turn Code',
    autoBaitingCode: 'Auto Feeding Code',
    autoBaitingCode1: 'Station 1 Feeding Code',
    autoBaitingCode1Placeholder: 'Enter Station 1 Feeding Code',
    autoBaitingCode2: 'Station 2 Feeding Code',
    autoBaitingCode2Placeholder: 'Enter Station 2 Feeding Code',

    preStartEquipment: 'Pre-Start Equipment',
    firstKnifePreCode: 'First Knife Pre-Start Code Advance',
    secondKnifePreCode: 'Second and Subsequent Knifes Pre-Start Code Advance',
    rowInsert: 'Row Insert',
    pumpPause: 'Vacuum Pump Pause',
    pumpPauseTip:
      'Note: Simplex equipment is required to fill in the same "Automatic feeding code" and "Double-sided Machining front Note Code" for both stations 1 and 2.',
    pumpPause2Tip:
      'Note: Simplex equipment needs to be filled with the same "vacuum pump pause code" for both stations 1 and 2.',
    pumpPauseCode1: 'Station 1 Vacuum Pump Pause Code',
    pumpPauseCode1Placeholder:
      'Please enter the vacuum pump pause code for Station 1',
    pumpPauseCode2: 'Station 2 Vacuum Pump Pause Code',
    pumpPauseCode2Placeholder:
      'Please enter the vacuum pump pause code for Station 2',

    upSpeedTip: 'Please enter the common edge acceleration speed',
    cutThickTip: 'Please enter the common edge cutting thickness',
    sizeErrTip:
      'Please set the correct size for the exported layout in the labeling machine!',
    offEdgeKnifeDiameter: 'Please enter the trimming knife diameter',
    slowDownSpeedTip:
      'Error in entering the deceleration parameter for cutting corners',
    permissionErr: 'This is the free version, saving is not allowed',
    labellingTempErr: 'Please select a labeling machine template',
    autoRollInputTip:
      'To enable automatic label rotation, a valid value must be entered',
    offlineTempFormat:
      'The current labeling machine file format has been discontinued. Please confirm',
    autoStockCode1: 'Station 1 automatic feeding code',
    autoStockCode1PlaceHolder: 'Enter the automatic feeding code for Station 1',
    autoStockCode2PlaceHolder: 'Enter the automatic feeding code for Station 2',
    autoStockCode2: 'Station 2 automatic feeding code',
    pauseCode: 'Pause code',
    pauseCodePlaceHolder: 'Enter the pause code',
    annotationCode1:
      'Annotation code for double-sided processing (front) of workstation 1',
    annotationCode1Tip:
      'Suitable for equipment with different commands for automatic loading (front)',
    annotationCode1Placeholder:
      'Enter the annotation code for double-sided processing (front) of workstation 1',
    annotationCode2:
      'Annotation code for double-sided processing (front) of workstation 2',
    annotationCode2Placeholder:
      'Enter the annotation code for double-sided processing (front) of workstation 2',

    cuttingParams: {
      title: 'Cutting Parameters',
      holeSlotMainSide: 'Concentrated Face of Holes and Slots',
      holeMainSide: 'Concentrated Face of Holes',
      slotMainSide: 'Concentrated Face of Slots',
      workPriority:
        '(Functional Priority: The face with punching holes and slots follows the unpunched holes and slots on the small board > Concentrated Face of Slots > Concentrated Face of Holes)',
      hingeRollWork:
        'Straightener and Hinge Cup Holes are Processed on the Reverse Side of the Large Board',
      followWork:
        'The face with punching holes and slots follows the unpunched holes and slots on the small board',
      autoMatchSide: 'Allocate Front and Back Holes and Slots Proportionally',
      backHSRate: 'Proportion of Holes and Slots on the Reverse Side',
      matchTip:
        '(This setting will not be effective for high-gloss boards or for opening and calculating abnormal layout plans)',
      specIalHoleAndSlotSurface:
        'Custom definition of machining surfaces for special slots and holes',
      specialSlotsHoles: 'Special slots and holes',
      FixedmachSurface: 'Fixed machining surface',
      deleteRuleTip: 'Whether to delete this rule',
      blankFront: 'Front side of the large board',
      blankBack: 'Back side of the large board',
      addRules: 'Add rule',
      addRulesTip:
        '(When the same small board matches multiple rules, the rule with the earliest effective sequence number takes effect, You can drag any row to change the rule order)',
      highGlossSetting: 'High-Gloss Board Settings',
      heighGlossFront:
        'Small Boards with High-Gloss Faces are Arranged on the Large Board During Layout',
      sideSlotPut: 'Place Slots Close to the Edge',
      optimize: 'Undergoing Functional Optimization',
      sysDefault: 'System Default',
      plankInSide: 'Inner Side of Large Board',
      plankOutSide: 'Outer Side of Large Board',
      specialNotWork:
        'This feature does not apply to abnormally shaped board pieces',
      commonSide: 'Common Edge Cutting',
      plankThickRange:
        'The Thickness of the Board Piece is Less than or Equal to',
      useCommonSide: 'Use Common Edge Cutting When',
      zeroTip:
        '(The layout gap needs to be manually changed to 0, and the tool will lift when processing to the already cut edge to skip it)',
      useTip:
        'After enabling this feature, oblique down-cutting processing will not be supported. Please note!',
      commonSpeed: 'Common Edge Acceleration',
      highSpeed: 'High-Speed Milling Speed',
      min: 'Minute',
      notWorkTip:
        '(Common edge acceleration will not be effective when the board processing meets the conditions for common edge cutting)',
      changeStartPoint: 'Double-Station Starting Point Switching',
      changeStartPointTip:
        'After enabling double-station starting point switching, it is not compatible with the XY axis swap function. Please use cautiously.',
      flatOffset: 'Machine Plane Offset',
      xRowChange: 'X-Axis Coordinate Reference Swap',
      bigplankPointChange:
        'Swap the reference coordinates on the back of the large board',
      reverseXRowChange: 'Swap X-Axis coordinates',
      changebigplankPointTips:
        'After enabling this feature, it is not compatible with the "XY axis swap" and "dual station start point switch" functions. Please use cautiously.',
      reverseYRowChange: 'Swap Y-Axis coordinates',
      plankMirrorTip:
        'Use this feature when mirroring board pieces processed on the cutting machine',
      notSameSize: 'Different Sizes for Double-Station Tables',
      maxSize1: 'Maximum Size for Station 1',
      notSameSizeTip:
        '(After enabling different sizes for double-station tables, it is not compatible with the double-station file merging function. Please use cautiously.)',
      maxSize2: 'Maximum Size for Station 2',
      autoSelectTip:
        '(When using this feature with different sizes for the two tables of a double-station machine, it will automatically select the corresponding station for processing large boards.)',
      bigFlatLimit:
        'The large station only processes large boards that cannot be processed by the small station:',
      surplusSetting: 'Scrap Settings',
      surplusPosition: 'Scrap Placement Position',
      cutHoleWay: 'Cutting and Drilling Method',
      knifeFlat: 'Knife Reference Plane',

      flat: 'Table Surface',
      plankFlat: 'Large Board Surface',
      freeHeightZ: 'Z Height When Idle',
      relativeHeight: 'Relative Height to the Upper Surface of the Large Board',
      cutDeepZ: 'Cutting Depth in Z Axis',
      cutDeepH: 'Through-Hole Drilling Method',
      onceThrough: 'Drill Through Once',
      rollThrough: 'Drill Through by Turning Over Twice',
      cutDeepS: 'Through-Slot Drilling Method',
      plankCutDirection: 'Cutting Direction of Small Boards',
      sDirection: 'Clockwise',
      nDirection: 'Counterclockwise',
      curveReplenish:
        'Arc Interpolation Instruction for Cutting Arc-Shaped Boards',
      curveReplenishTip:
        'Use this function when sawtooth appears during cutting of arc-shaped pieces',
      noGen: 'Do Not Generate G17/G18/G19 Plane Instructions',
      arcInstruction: 'Arc machining command prefix',
      arcExample: 'G17G03 X100 Y100',
      example: 'example',
      curveCutTip: 'Arc cutting is represented by I and J',
      coverRate: 'The Overlap Rate for Milling Slots Is:',
      roughTip:
        'Use this function when there are burrs inside the slot during milling',
      clearCurve:
        'Automatic Rounding of Slots That Are Not Completely Milled Through:',
      curveUseTip:
        'Select this function when rounding is needed during slot milling. Note that this function does not apply to connectors.',
      slotCutWay: 'Slot Milling Toolpath',
      outToIn: 'Mill Slots from Outside to Inside',
      inToOut: 'Mill Slots from Inside to Outside',
      zShapProcess: 'Zigzag through groove',
      zShapProcessTip:
        'Only for the front and back pull through slot milling, the tool path overlap rate defaults to the tool radius',
      slotWorkLimit:
        '(Only Effective When the Number of Layers for Slot Milling > 1)',
      workSpeedOp: 'Processing Speed Optimization',
      obliqueCut: 'Oblique Downward Cutting During Cutting',
      backOblique: 'Oblique Downward Cutting for Backside Trimming',
      obliqueSpeed: 'Oblique Cutting Speed',
      arcSpeed: 'Curve Processing Speed',
      cuttingPreDistance: 'Distance to Advance Before Cutting',
      backKnife: 'Deceleration Before Cutting Ends',
      openKnifeSpeed: 'Speed for Cutting End',
      cutCornerSlow: 'Deceleration When Cutting Corners',
      addRow: 'Add Row',
      bettwenDistance: 'Distance Before and After Corners',
      cuttingSpeed: 'Cutting Speed',
      stepCutting: 'Layered Cutting',
      splitSlot: 'Cut Slots in Two Steps',
      splitPlank: 'Cut Small Boards in Two Steps',
      maxWH: 'Length or Width Less Than',
      areaLimit: 'And Area Less Than',
      secondDeep: 'Second Cutting Depth',
      splitPlankThick: 'Sizing layered cutting settings',
      saveLayeredCuttingTip:
        'The sum of the three layered cuts must be equal to the thickness of the plate',
      saveLayeredRepeatTip:
        'It is not recommended to set different layered data for the thickness of the highlight attribute of the same material, please delete the redundant data!',
      splitPlankThick: 'Layered Cutting for Different Board Thicknesses',
      movePlankSetting: 'Anti-slip board settings',
      newCutSequence: 'New cutting algorithm',
      newCutSequenceText:
        'By default, the center of the large slab has the strongest vacuum suction, and the small slabs in this area are cut last',
      positionTip: 'Click on the corresponding area to select',
      suctionPosition: 'Vacuum strong suction position',
      strongArea: 'Strong suction area',
      minSuctionPlank: 'Minimum suction board',
      safeWidth: 'Width',
      safeHeight: 'Height',
      safeTip: 'Will cut first if the board cannot be held securely',
      scattered_layout: 'Small board scatter layout',
      totalThickLimit:
        'The Sum of Three-Layer Cutting Must Equal the Thickness of the Board Piece',
      offsetFollowStep: 'Trimming Also Follows Layered Cutting',
      longPlankTip1:
        "1. After enabling this feature, you must set the vacuum pump pause code in 'Material Cutting Docking - Vacuum Pump Pause', otherwise the problem of bending when cutting long boards cannot be solved.",
      longPlankTip2:
        '2. After enabling or disabling this feature, save the layout records in the history. When downloading NC, re-layout is required.',
      secondCutting: 'Secondary cutting object',
      normalPlank: 'Regular board',
      normalPlankTip:
        'Regular board: boards such as cross and vertical partitions in the cabinet body, and boards added directly in the material cutting list, excluding cabinet doors.',
      plankLongLimit: 'When the long side of the long board exceeds',
      plankShortLimit: 'The short side exceeds',
      cuttingSplit: 'Cut in two steps',
      longExpand: 'First cutting allowance: Long side allowance',
      firstExpand: 'First cutting allowance',
      longExpandTip: 'Long edge allowance',
      longLimit: 'Long edge exceeds',
      cuttingSplitTip: 'Condition for cutting long boards in two passes',
      shortExpand: 'Short side allowance',
      expandTip:
        '(Increase the long/short side dimensions by x mm each. For irregular long boards, the long side allowance applies to both sides.)',
      pumpOffWay: 'Vacuum shutdown method',
      afterOnePlank: 'After cutting a single long board',
      afterAllPlank: 'After cutting the entire large board',
      numberFixed: 'Number of decimal places to keep',
      bufferCode:
        'Buffer command for the surface of the board when starting the cut',
      layoutGap: 'Layout gap setting',
      layoutGapTip:
        'Note: Setting the layout gap to 0 may cause layout overlap',
      wasteCutting: 'Automatic waste truncation',
      zkPointCustomize:
        'Z-Knuckle Slant Hole Machining Rotation Reference Point Customization',
      sawSetting: 'Saw Cutting Settings',
      cuttingLimit: 'Truncate size larger than',
      waste: 'of waste',
      wasteSizeLimit: 'The minimum size must not be less than 100mm',
      topLeft: 'Left and top sides',
      topLeftD: 'Top left corner',
      bottomLeft: 'Left and bottom sides',
      bottomLeftD: 'Bottom left corner',
      topRight: 'Right and top sides',
      topRightD: 'Top right corner',
      bottomRight: 'Right and bottom sides',
      bottomRightD: 'Bottom right corner',
      firstTime: 'First time',
      secondTime: 'Second time',
      thirdTime: 'Third time',
      startCutting: 'Cutting started',
      thickRange: 'Thickness ≥',
      longCut: 'Long board processing',
      edgeSetting: 'Edge trimming settings',
      cuttingOrder: 'Cutting order:',
      cuttingOrderTip:
        'There is a risk of board running with this method, please use it carefully',
      plankFirst: 'Cut small boards first',
      wasteFirst: 'Cut waste material first',
      offTip:
        'When turned off, it will follow the trimming settings in the cutting parameters',
      edgeCutDirection: 'Trimming Direction',
      onlyLong: 'Trim only long edges',
      backCutOrder: 'Back side trimming order',
      backFirst: 'Trim back side first',
      backSecond: 'Trim back side after hole and slot processing',
      frontReCut: 'Re-trim front side',
      frontReCutTip:
        '(To make the positioning cylinder edge smoother and prevent marks)',
      useNewCut: 'Enable new trimming rules',
      newCutTip:
        'Use this function when an additional trimming dimension is required on the front side of double-sided processing of large panels',
      fixedTip:
        'Note: Keeping the decimal places to 0 or 1 may cause overlapping in layout',
      cuttingSlow: 'Buffer on the surface of cutting parts',
      holeSlow: 'Buffer on the surface of punching parts',
      slotSlow:
        'Buffer on the surface of grooving/milling holes/milling slots parts',
      slowCode: 'Usage of buffer commands',
      code: 'Command',
      zkRotatePoint: 'Rotation reference point',
      zkRotateDir: 'Rotation angle increment direction',
      restSizeLimit: 'Remaining size of surplus is less than',
      useSaw: 'Use saw blade',
      unGenSawPath: 'Do not generate longitudinal saw blade cutting path',
      suitMachine: 'Suitable for full aluminum welding plate processing',
      plankBack: 'Back of the large plank',
      plankLeft: 'Left side of the large plank',
      plankRight: 'Right side of the large plank',
      plankFront: 'Front of the large plank',
      frontSide: 'Front side',
      backSide: 'Back side',
      rightSide: 'Right side',
      leftSide: 'Left side',
      singleMachineLimit:
        'Generate edge trimming path on the front of single-sided processed panels and perform edge trimming first',
      singleMachineLimitTip:
        'This function does not apply to integrated machines or saw blade cutting',
      setBatchPositionWaring:
        'Tip: If you need to cancel the quick modification of the cutting point mode, please uncheck the "Modify Cutting Point" button on the right side',
      setBatchPosition: 'Batch modify cutting points',
      setBatchPositionAs: 'Modify the cutting direction to:',
      activeBatchPosition: '{activePartCount}small board has been selected',
    },

    labelingSettings: {
      title: 'Label Settings',
      output: 'Output Automatic Labeling File',
      specification: 'Label File Format',
      codeFrontGenerate:
        'Automatically Generate Feeding Code Before Labeling Code',
      templateOffline: 'The Template Used Is Offline!',
      rollLabelling: 'Flip Labeling Settings',
      flipSetting: {
        title: 'Flip Settings',
        engravingFlipProcess:
          'Enable Engraving Machine Flip Processing Labeling Mode',
        flipTip:
          'Enable this function when the engraving machine needs to flip for processing',
      },
      rotateSetting: {
        title: 'Label Rotation Settings',
        rotateTip:
          'When the board size is smaller than the set value for the width or height, the label will rotate 90° clockwise',
        horizontal: 'Board Width ≤',
        vertical: 'Board Height ≤',
        either: 'Board Width OR Height ≤',
      },
      countertopSetting: {
        title: 'Workbench Settings',
        startPoint: 'Workstation Starting Point:',
        upLeft: 'Upper Left',
        upRight: 'Upper Right',
        downLeft: 'Lower Left',
        downRight: 'Lower Right',
        baseExchange: 'Coordinate Base Exchange:',
        exchangeX: 'X-axis Exchange',
        exchangeY: 'Y-axis Exchange',
      },
      offset: 'Label Coordinate Offset',
      offsetTip:
        'Generally, the labeling coordinate is at the center of the small board, but some devices require adjustment',
      label_rotate_offset: 'Label Offset After Rotation',
      label_rotate_offset_tip:
        'Adjust when the offset position differs significantly before and after label rotation',
      label_rotate_offset_err_tip:
        'After the label rotation coordinate offset is turned on, the X and Y axis coordinate offsets are required',
      imgSetting: {
        title: 'Label Image Settings',
        suffix: 'Label Image Suffix:',
        code: 'Image Coding Method:',
        codeTip:
          'Note: png, jpeg will synchronously modify the coding format of the layout image, and the embedded cloud layout does not support this feature',
        imgRatio: 'Label Image Resolution:',
        slowDown: 'Will reduce the efficiency of exporting label images',
        color: 'True Color',
        bit: 'Black and White',
        resolution: 'Label Image Resolution',
        resolutionPlaceholder: 'Resolution',
        labelPool: 'Data Label Pool',
        dragTip: 'You can drag and drop labels within the label pool',
        labelImgName: 'Label Image Name',
        labelImgNameErrTip: 'The label image name cannot be empty',
        labelTip1:
          "Note: 1. When customizing, either 'Board Barcode' or 'Label Sequence Number' must be used, otherwise there may be image overlay issues;",
        labelTip2:
          '2. This feature does not apply to the labeling machine system template',
        delimiter: 'Delimiter:',
        inputTip:
          'Does not support input of characters such as / : ? < > | . ” *',
        numStart: 'Starting Value for Label Sequence Number',
        example: 'Custom Label Image Name (Example)',
        divideFolders:
          'Divide label images into different folders based on large boards',
        foldersTip1:
          "Note: 1. This feature cannot be used together with the 'Merge with Material Cutting Machine Files' feature;",
        foldersTip2:
          '2. This feature is not yet effective for the embedded cloud layout',
        stickIntoFolders:
          "Insert labeling files into the corresponding large board's label image folders:",
        stickTip1:
          "Note: 1. This feature needs to be used in conjunction with the 'Export Labels Along with Downloading NC' feature;",
        stickTip2:
          "2. This feature will only take effect if the file generation rule in the used labeling template selects 'Generate Single File';",
        stickTip3:
          '3. This feature is not yet effective for the embedded cloud layout',
      },
      paibanImgSetting: {
        title: 'Layout Image Settings',
        reparation: 'Automatic Compensation of Layout Image Sequence to',
        figure: 'Position',
        inputTip: 'Please enter a number between 1-99',
        paibanImg: {
          export: 'Export Layout Image',
          prefix: 'Layout Image Name Prefix',
          size: 'Layout Image Size',
          format: 'Layout Image Format',
          directional: 'Layout Direction',
          vertical: 'Vertical',
          horizontal: 'Horizontal',
        },
      },
      autolabel: 'Automatic Labeling Order Setting',
      customTemp: 'Effective Only for Custom Templates',
      cuttingLabelOder: 'Labeling According to Cutting Order',
      labelToUp: 'Labeling from Bottom to Top Based on Layout',
      labelToDown: 'Labeling from Top to Bottom Based on Layout',
      labelDodge: 'Automatic Label Avoidance',
      settingValue: 'Select Setting Value',
      enabelTip:
        'When enabled, the label will rotate if the small board meets the following setting values',
      fixedHori: 'Select Parallel to the Fixed Edge of the Small Board',
      fixedHoriTip:
        'Effective Only for Non-textured Small Boards. When enabled, the label will rotate to align the horizontal edge of the label with the fixed edge of the small board during labeling',
      tagHori: 'Label Horizontal Edge',
    },

    drillSettings: {
      title: 'Drilling Machine Settings',
      enable: 'Enable Drilling Package',
      toolTable: {
        use: 'Enable',
        number: 'Drilling Machine Number',
        number2: 'Station 2 Drill Number',
        diameter: 'Drill Bit Diameter',
        xOffset: 'X Offset',
        yOffset: 'Y Offset',
        moveZF: 'Z-Axis Rapid Move Speed',
        feedRate: 'Z-Axis Machining Speed',
        moveF: 'XY-Axis Rapid Move Speed',
        benchmarkDrill: 'Benchmark Drill',
        addKnife: 'Add Tool',
        deleteKnife: 'Delete Tool',
      },
      prefix: 'Drilling Machine Prefix',
      suffix: 'Drilling Machine Suffix',
      startCode: 'Drilling Machine Start Code',
      endCode: 'Drilling Machine End Code',
      combineKnife: 'Combined Drilling Machine Cuts',
      combineTable: {
        prefix: 'Drilling Machine Number Prefix',
        suffix: 'Drilling Machine Number Suffix',
        mainHole: 'Main Hole',
        extraHole: 'Extra Hole',
        notes: 'Combined Notes',
        firstUseNotes: 'First Use Notes',
        addExtraHole: 'Add Extra Hole',
        deleteExtraHole: 'Delete Extra Hole',
        example: 'Example',
      },
      processSetting: {
        title: 'Processing Settings',
        mode: 'Drilling Machine Processing Mode',
        processTip:
          'This feature will not be effective when selecting this mode',
        recentHole: 'Drill Nearby Holes (Rapid Tool Change)',
        groupHole:
          'Drill by Category (Change Tool After Completing a Category)',
        drill_benchmark: 'Combination of tool setting benchmarks',
        drill_benchmark_Tip:
          '（Attention：After selecting this method, the drill combination tool setting function will not take effect）',
        mainHole: 'Main hole',
        secondaryHole: 'Secondary hole (Tail hole)',
      },
      changeBenchDrillTip:
        'Please enter the drilling machine number before making this selection',
    },
    NCExportSettings: {
      title: 'NC Export Settings',
      tab1: 'Nesting Machine File Name Settings',
      tab2: 'Nesting Machine File Name Merging',
      tab3: 'Nesting Machine File Comments',
      tab4: 'Nesting Machine File Types',
      tab5: 'Nesting Machine File Folders',
      tab6: 'Processing Instruction Settings',
      tab7: 'File Format Settings',
      fileNameSettings: {
        twoStationAutoSort: 'Automatic Sorting for Dual Stations',
        reparation:
          'Compensate the Number of Digits for Panel Serial Numbers to',
        figure: 'Position',
        labelerBegin: 'Label Machine Prefix',
        machineCenter: 'Machining Center Prefix',
        front: 'Front Side:',
        back: 'Back Side:',
        customizeName: 'Custom File Name (Format):',
        delimiter: 'Delimiter:',
        example: 'Custom File Name (Example):',
        emptyTip:
          'Both front and back file identifiers cannot be empty at the same time',
        labelPool: 'Data Label Pool',
        dragTip:
          'You can drag or click to copy the tags in the tag pool to the input box',
      },
      fileMerge: {
        engravingMerge: 'Nesting Machine File Merging',
        zfMerge: 'Front and Back Side Merging',
        singleMerge: 'Single Station Merging',
        doubleMerge: 'Dual Station Merging',
        fEndCode: 'Back Side End Code',
        zBeginCode: 'Front Side Start Code',
        displayfbInfo: 'Display Front and Back Side Information in File Name',
        fBeginCode: 'Back Side Start Code',
        zEndCode: 'Front Side End Code',
      },
      marginalNotes: {
        addPlankInfo:
          'Add Current Panel Information to the Beginning of Nesting Files:',
        plankInfo: 'Panel Information:',
        addStationCodeFirst:
          'Add Station Code to the Beginning of Nesting Files:',
        addStationCodeSecond:
          'Add Station Code Before Tool Change in Nesting Files:',
      },
      fileType: {
        zAddtion: 'Z-Axis Addition:',
        addSemicolon: 'Add Semicolon at the End of Each NC Line:',
        semicolonTip:
          'Checking this box will also affect the processing files for horn handles',
        fileName: 'File Name',
        defaultName: 'Default File Name',
        dedicatedName: 'Dedicated File Name for Xinghui Equipment',
        xhNotes: 'Xinghui E2-9 Notes',
        format: 'Output Engraving Machine File Format',
        example: 'File Example: NC File',
        stillGenerate:
          'Still generate a back side NC file with only the back side end code when the back side is not processed',
        generateTip:
          'This feature is not supported when used together with the file merging feature',
        fileNameErrTip:
          'File names cannot contain any of the following characters: / : * ? "',
      },
      categorize:
        'Categorize into different folders based on panel color, thickness, and material (including labeling files):',
      simplifyG: 'Simplify G-Code Instructions',
      ZToW: 'Change Code from Z-Axis to W-Axis',
      knives: 'Drilling Tools',
      lineBreakSetting: 'Line Break Format Settings',
      codeFormatSetting: 'File Encoding Format Settings',
      utf8bom: 'UTF-8 with BOM',
      oneCodeSetting: 'Panel Barcode Settings',
      simplifyOneCode: 'Generate Simplified Format Label Barcode',
      codeLocation: 'Code Location Settings',
      generateLocation:
        'Generate station end code before the back side end code',
      emergencySetting: 'Emergency Settings',
      show: 'Show Emergency Settings',
      emergencyCode: 'Emergency Code',
      params: 'Parameters',
      emergencyTip:
        'Can temporarily implement some niche features with the cooperation of after-sales personnel',
      holeSetting: 'Hole punching file settings',
      holeFileCategorize:
        'Hole punching files are placed separately in folders according to different orders',
      holeTip:
        'Does not take effect on the cloud typesetting of the embedded cabinet client',
      specialCodeErr: 'Special characters are not supported!',
      copySuccess: 'Copy successful!',
      copyErr: 'Copy failed, please try dragging the tag!',
      lengthLimit: 'Only supports input of 15 characters!',
      plankInfo: 'Board information',
      otherInfo: 'Other information',
      uniqueBarCode: 'Unique barcode for the plate',
      uniqueBarCodeForPack: 'Unique barcode for the plata(Only for packaging)',
      machineOrder:
        'Generate processing sequence number from cutting file name',
      folderSetting:
        'Place cutting files of the same size boards in the same folder',
      labelImageINCFolder:
        'Place the labeled images and accompanying label files in the NC folder',
      custom: 'Cutsom',
      folderSettingTip:
        'Note: Embedded layout does not support this feature yet',
      settingMoveTip:
        'The original "{func}" function has been moved to the "{module}" page!',
      fileManagerFunction:
        'Place drilling files in separate folders according to different orders',
      gotoSetting: 'Go to settings',
    },
    aioMachineSetting: {
      openFunction: 'The following functions need to be enabled for docking',
      openCarving: 'Enable the carving machine',
      xyReverseFunction: 'XY Axis Swap Function',
      carvingStartSet: 'Set the carving machine start position to',
      rightTop: 'Top Right',
      carvingEdgeSet:
        'Adjust the front edge trimming value of the carving machine to',
      finalEdgeSame: 'Same as the final trimming value',
      beginCode: 'Program Start Code',
      endCode: 'Program End Code',
      drillHole: 'Drill Hole',
      pullTrough: 'Pull Trough',
      showDataTemp: 'Show Data Sample',
      dataTemp: 'Data Sample',

      title: 'All-in-one machine settings',
      outputFile: 'Output processing file for all-in-one machine',
      outputFileTips:
        'After enabling the all-in-one machine, it is not compatible with XY interchangeable file merging without penetrating the irregular holes. Please use it with caution',
      fileType: 'File format',
      select: 'Please select',
      diameter: 'diameter of the trimming tool',
      jidongTip:
        'When using the East-Japan integrated machine, please set the starting point of the engraving machine station to the upper right corner and activate ',
      skill: 'Function',
      clampHandLength: 'Gripper length(L)',
      clampHandWidth: 'Gripper width（W）',
      clampHandMargin1: 'Clamp hand 1 margin(H1)',
      clampHandMargin2: 'Clamp hand 2 margin(H2)',
      duchClampHand: 'The hole groove avoids the clamping hand position',
      clampHandDirection: 'Hand direction',
      left: 'left',
      right: 'right',
      top: 'top',
      bottom: 'bottom',
    },
    oneDragTwo: {
      title: 'One to two engraving machine',
      outputMachineFile:
        'Output the processing file for the one-to-two engraving machine',
      outputMachineFileTips:
        'After enabling the "one-to-two" engraving machine, it is not compatible with the file merging function of the cutting machine. Please use it with caution',
      fileType: 'File format',
      select: 'Please select',
      machineStart: 'starting point of the workstation',
      positionClose:
        'When closing, it will follow the starting point setting of the workstation in the cutting parameters',
      engravingPlus: 'Rapid engraving machine',
      a: '',
    },
    sawEngraving: {
      title: 'Saw and engraving machine',
      preLayNav: 'Saw and engraving machine settings',
      preLayKnifeInput: 'Cross cutting blade diameter of saw blade',
      enableSaw: 'Enable sawing and engraving machine',
      cutSet: 'Cutting settings',
      sawUsing: 'Usage of saw blade',
      trim: 'Trimming',
      cutBlank: 'Cutting and blanking',
      sawUseCond: 'Saw blade usage conditions',
      hasUsing: 'Use available path',
      onlyUse: 'use only when necessary',
      cutMethod: 'Cutting method',
      oneKnifePull: 'Cut through the large board with one stroke',
      onlyOneKnifePull:
        'only then did he directly pull it through with one knife',
      enableSawThick: 'Enable saw blade thickness layout',
      edgedDir:
        'The edge of the large board that has been sealed and will not be cut again',
      top: 'Top',
      bottom: 'Bottom',
      left: 'Left',
      right: 'Right',
      dockTip:
        'The processing of butt welded plates requires the sealing of the edges on all four sides of the rectangular plate, similar to the overall trimming, and manual operation in a single row layout',
      tempPaiabanTip:
        'After enabling saw blade cutting, it is not supported to directly add irregular plates in the layout. If you need to add them, please go to the cutting list to add and layout them!',
    },
    firstSave:
      'It is detected that the user is using cloud typesetting for the first time. After saving the settings, please pay attention to whether the trimming direction in the file is reasonable to avoid unnecessary losses',
    knives: {
      xOffset1: 'X Coordinate Offset',
      yOffset1: 'Y Coordinate Offset',
      zOffset1: 'Z Coordinate Offset',
      title: 'Tool Magazine',
      insert: 'Insert a row',
      delete: 'Delete the row',
      tip: '（Note: After modifying the tool library information or adjusting settings, users producing cabinet layouts need to restart the cabinet client to apply the latest changes!）',
      number: 'number',
      name: 'Name',
      diameter: 'Blade DiameterΦ',
      cutting: 'Cutting Material',
      use: 'Purpose',
      moveZF: 'Z-axis fast movement speed',
      cutZF: 'Z-axis processing speed',
      moveF: 'XY axis fast movement speed',
      cutF: 'XY axis processing speed',
      startCode: 'Code for starting tool change',
      stopCode: 'Tool change end code',
      startCode2: '工位2换刀开始代码',
      stopCode2: '工位2换刀结束代码',
      preStartCode: 'pre-start code',
      punchHoles: 'Punch Holes',
      pullSlots: 'Pull Slot',
      millHoles: 'Milling Holes',
      millSlots: 'Milling slot',
      sawBlade: 'saw blade',
      doubleSaw: 'Enable double-bit different tool change start code',
      singleStation: 'Single-station',
      doubleStation: 'Two-station',
      sawBladeTip:
        'This function does not work for 1-to-2 engraving machines and all-in-one machines',
      serialNum: 'serial number',
      sawWidth: 'Knife width',
      sawType: 'Saw blade type',
      cutDirection: 'Cutting Direction',
      moveSpeed: 'empty speed',
      cutSpeed: 'processing speed',
      startCode: 'Start code for tool change',
      endCode: 'End code for tool change',
      sawDownCode: 'Lowering code',
      sawUpCode: 'Tool-off code',
      abscissa: 'prefix of the abscissa',
      ordinate: 'The prefix of the vertical coordinate',
      sawHor: 'cross-cutting saw blade',
      sawVer: 'vertical cutting blade',
      towardsZero: 'Cut towards the 0 point coordinate',
      awayFromZero: 'Cut away from the 0 coordinate',
      diameterDesc: 'diameter of the knife Φ',
      xOffset: 'Horizontal coordinate offset',
      yOffset: 'Vertical coordinate offset',
      zOffset: 'offset of the elevation coordinate',
      offsetX: 'Offset X',
      offsetY: 'Offset Y',
      offsetZ: 'Offset Z',
      offsetNo: 'Offset number',
      knifeWidth: 'knife width',
      drill: 'cut',
      cut: 'processing',
      speed: 'Speed',
      type: 'Type',
      millHoleSlot: 'Milling hole/slot',
      rapidFeedSpeed: 'Rapid feed speed',
    },
    other: {
      title: 'Other',
      tip: 'For single-station equipment, both Station 1 and Station 2 must be assigned the same automatic feeding code',
    },
  },

  sideHoleMachine: {
    useArcCode: 'Arc interpolation command for cutting arcs',
    coordSwitch: 'Coordinate Reference Switch',
    updownCoordSwitch: 'Lifting Coordinate Reference Switch',
    knifes: {
      number: 'Number',
      diameter: 'Blade Width',
      type: 'Type',
      moveSpeed: 'Rapid Feed Speed',
      doSpeed: 'Processing Speed',
      startCode: 'Tool Change Start Code',
      endCode: 'Tool Change End Code',
    },
    ymSetting: 'Yimu Connection Settings',
    flanking: 'side processing',
    frontProcess: 'front processing',
    oldSetting: 'Old version setting',
    equipParamsSetting: 'Equipment parameter setting',
    holeSetting: 'Hole setting',
    raminoSetting: 'Ramino connection setting',
    mortiseTenonSetting: 'Tenon and mortise connection setting',
    straight: 'Straight Type',
    eccentric: 'eccentric Type',
    pbSize: 'Upper and lower breaking board size',
    coordinateValue: 'coordinate value',
    codeFormat: 'File encoding format',
    suffixSetting: 'File suffix setting',
    insertRow: 'Insert Row',
    deleteRow: 'Delete Row',
    equipmentParams: {
      equipPauseCode: 'Equipment Pause Code',
      wiggleCode: 'Wiggle Code',
      equipStartCode: 'Equipment start code:',
      flipPauseCode: 'Pause code for flipping',
      equipEndCode: 'Equipment end code:',
      equipStartCodePlaceholder: 'Enter the device start code',
      flipPauseCodePlaceholder: 'Enter the flip pause code',
      equipEndCodeTip: 'Enter the equipment end code',
      sideChangeSetting: 'Side change setting',
      workTip:
        'By default, processing starts from the left side ① and proceeds in a counter-clockwise direction',
      frontTurnCode: 'Start code above:',
      frontTurnEndCode: 'End code above',
      frontTurnCodePlaceholder: 'Enter the start code above',
      frontTurnEndCodePlaceholder: 'Enter the end code above',
      leftTurnCode: 'Left start code:',
      leftTurnEndCode: 'Left end code:',
      leftTurnCodePlaceholder: 'Enter the left start code',
      leftTurnEndCodePlaceholder: 'Enter the left end code',
      downTurnCode: 'The following is the code:',
      downTurnEndCode: 'End code below:',
      downTurnCodePlaceholder: 'Enter the following start code',
      downTurnEndCodePlaceholder: 'Enter the following end code',
      rightTurnCode: 'Right-hand start code:',
      rightTurnEndCode: 'Right end code:',
      rightTurnCodePlaceholder: 'Enter the right start code',
      rightTurnEndCodePlaceholder: 'Enter the right end code',
      topFlipCode: 'Flip Code - Top',
      downFlipCode: 'Flip Code - Down',
      leftFlipCode: 'Flip Code - Left',
      rightFlipCode: 'Flip Code - Right',
      cylinderOutCode: 'cylinder out code',
      cylinderbackCode: 'Cylinder retract code',
      cylinderbackDirective: 'Cylinder retract command',
      moveDirective: 'Move Instruction',
      moveAxisCode: 'Move Axis',
      pushAxisCode: 'Cylinder push axis',
      swingAxisCode: 'swing axis',
      moveCode: 'Move Code',
      punchCode: 'Processing Code',
      free: 'free walk',
      punchPart: 'Processing part',
      cutFaceCode: 'Processing plane code',
      horVerCutFace: 'Horizontal and vertical plane',
      horCutFace: 'Horizontal lifting plane',
      verCutFace: 'Vertical lifting plane',
    },
    tagPool: 'Data tag pool',
    dragTip: 'Drag the label in the label pool',
    knives: 'Processing tools:',
    directives: 'Processing instructions:',
    slot: 'Straight slot processing',
    cirWork: 'arc machining',
    cirInter: 'circular interpolation',
    cirOption1: 'Clockwise: G03 Counterclockwise: G02',
    cirOption2: 'Clockwise: G02 Counterclockwise: G03',
    coordinate: {
      xPointSymbol: 'X-coordinate:',
      yPointSymbol: 'Y-coordinate:',
      zPointSymbol: 'Z-coordinate:',
      upright: 'Positive value',
      negative: 'Negative value',
    },
    encodeFormat: {
      format: 'File encoding format:',
      wrap: 'Line wrap format setting:',
      formatOption1: 'UTF-8 format',
      formatOption2: 'ANSI format',
    },
    suffix: 'File suffix setting:',
    enableFP: 'Enable front processing',
    slotSetting: 'Slot setting',
    takeUpDirective: 'Knife retraction command',
    workSeparately: 'Process the through slot on the front and back separately',
    secondSlotLong: 'Length of the second slot',
    enableOld: 'Enable the old version of the side hole machine setting',
    default: 'Default setting',
    customizable1: 'Custom 1',
    customizable2: 'Custom 2',
    customizable3: 'Custom 3',
    customizable4: 'Custom 4',
    knifeSetting: 'Knife setting',
    soltTip: '(Including milling holes/slots)',
    specialSetting: 'Special setting',
    ramino: 'Ramino',
    sun: 'Mortise and tenon',
    please: 'Stay tuned',
    designatedKnives: 'Designated knives',
    designated: 'Designated',
    workOrder: 'Processing command',
    other: 'Others',
    nameSetting: 'Suffix name setting',
    repeatErr: 'The number is duplicate. Please reset it.',
  },
  // 电子锯
  electronicSaw: {
    importMaterial: 'Import Material List',
    formSetting: 'Electronic Saw Form Setting',
    fileFormat: 'Output File Format for Cutting List:',
    isSmallFile: 'Export Small Board Image File:',
    fileGroupRule:
      'Divide Electronic Saw Files into Different Tables Based Color on, Material, and Thickness:',
    roomsByCategory:
      'Divide Electronic Saw files into Different Tables Based on Rooms:',
    fileName: 'Cutting List File NameEncoding:',
    fineNameTip: 'Open list file name cannot be empty!',
    delimiter: 'Delimiter:',
    fileNamePlaceholder: 'Custom Cutting List',
    outputFile: 'Output List Encoding Format:',
    outputFileEncoding: 'Output list encoding format:',
    utf8: 'UTF-8 with BOM',
    cellTab: 'Generate Cell Tabulators:',
    texture: 'Texture Representation:',
    special: 'Special Shape Representation:',
    notSpecial: 'Not a Special Shape',
    genSimpleLabelBarcode: 'Generate Simple Format Label Barcode',
    uniqueBarCode: 'Unique barcode for the plate',
    uniqueBarCodeTip:
      'Generate simple format label barcode function does not work after enabled',
    customeForm: 'Custom Form',
    tagPool: 'Data Tag Pool',
    tagPoolTips: 'Drag and Drop or Click to Copy Tags from the Tag Pool',
    displayHead: 'Hide Table Header',
    addTableHead: 'Add Table Header',
    deleteTableHead: 'Delete Table Header',
    generateExample: 'Generate Output Form Example',
    importExample: 'Import Form Example',
    importExampleTip:
      'The imported form example is only for reference when customizing the electronic saw',
    importExampleErrTip:
      'No material list has been imported, please import the material list first',
    outputExample: 'Output Form Example',
    outputExampleTip: 'Output example of the custom form',
    outputExampleErrTip:
      'No output form example has been generated, please generate one first',
    defaultTableHead: 'Default Table Header',
    deleteHeadErrTip: 'No table header to delete!',
  },

  // Lock Popup Component
  lockModal: {
    passwordInput: 'Please enter the password',
    lockPasswordInput: 'Please enter the unlock password',
    passwordInputPlaceholder: 'Please enter a four-digit password',
    editPassword: 'Change Password',
    confirmPassword: 'Confirm Password',
    setLockPwd: 'Set Unlock Password',
    pwdFormatErr: 'Incorrect password format',
    sendMessageTo: 'SMS verification code will be sent to',
    captchaPlaceholder: 'Please enter the verification code',
    sendCaptcha: 'Send Verification Code',
    sendSeccess: 'SMS sent successfully',
    sendFail: 'SMS failed to send, please try again later',
    updateSeccess: 'Password set successfully',
    editSuccess: 'Password changed successfully',
    lockSeccess: 'Unlock successful',
    lock: 'Unlock',
    inputErrLimit:
      'The current number of errors has reached the limit, please reset the unlock password!',
  },

  // 五六面钻
  dock56Drill: {
    equipType: 'Equipment Type',
    equipment: 'Equipment',
    fiveDock: 'Five-Sided Drill',
    sixDock: 'Six-Sided Drill',
    fileType: 'Generated File Type',
    templateOffline: 'The template used has been discontinued!',
    showExample: 'Show Data Example',
    conNoticeTip: 'Connection Notes:',
    conNoticeTip1:
      '1. Engraving machines with "XY Axis Swap" enabled require a special template for five/six-sided drilling with front and back swapping.',
    conNoticeTip2:
      '2. To display or process special parts of the board on the five/six-sided drill, special part data needs to be configured in the template.',
    conNoticeTip3:
      '3. After first using the five/six-sided drill or changing the template, please conduct a test production of the cabinet and confirm its correctness before regular production.',
    conNoticeTip4:
      '4. During test production, carefully inspect the size, depth, location, and face of the holes and slots on the front and back. Avoid issues with cabinet mirroring.',
    example: 'Data Example',
    commonSetting: 'Common Parameter Settings',
    throughHoleSetting: 'Through-Hole Drilling on Both Sides',
    throughSlotSetting: 'Through-Slot Drilling on Both Sides',
    nonHolesNotFiles:
      'No hole or slot data, no file output for rectangular boards:',
    frontFileIdentifier: 'Front File Identifier:',
    oppositeFileIdentifier: 'Opposite File Identifier:',
    oppositeFileIdentifierTips:
      'Generated as: Original 13-digit part code + identifier character',
    arcDescription: 'Arc Description Method',
    linePoints: 'Line Segment Formation Description',
    arcCommand: 'Arc Command Description',
    slotWidthSplit:
      'Split wide slots into multiple narrow slots for processing:',
    slotSplitWidth: 'Width of single slot after splitting:',
    mm: 'Note: The splitting target is for regular rectangular slots',
    isNotMergeSlot: 'Merge adjacent slots with the same width and depth:',
    slotPunchTip:
      'This function only applies to backboard slots and drawer bottom slots using custom templates',
    sizeRange: 'Adjacent sizes less than or equal to',
    enableWorningTip:
      'This function cannot be enabled simultaneously with the front edge placement direction',
    longDirection: 'Board Length Placement Direction',
    horizontal: 'Horizontal',
    vertical: 'Vertical',
    slotDirection: 'Five/Six-Sided Drill Slot or Special Placement Direction',
    default: 'System Default',
    frontLeft: 'Front and Left',
    frontRight: 'Front and Right',
    backLeft: 'Back and Left',
    backRight: 'Back and Right',
    frontDirection: 'Front Edge Placement Direction',
    enableTip: 'Note: Only effective for Cloud Layout',
    fixedOn: 'Fixed On:',
    front: 'Front',
    back: 'Back',
    left: 'Left',
    right: 'Right',
    coordinateValue: 'Number of Decimal Places for Coordinate Values:',
    decimalPlaces: 'Decimal Places',
    specialParameter: 'Special Parameter Settings',
    output5FDrillSlotKnifeCode: 'XML2 Milling Slot Tool Code',
    output5FDrillShapeKnifeCode: 'XML2 Milling Shape Tool Code',
    plankTexture: 'XML2 Board Texture',
    offset: 'XML2 Milling Shape Tool Compensation',
    mprConHoleDir:
      'Five/Six-Sided Drill MPR File Side Hole Z-Coordinate Reference Plane',
    plankTop: 'Board Upper Surface',
    plankBottom: 'Board Lower Surface',
    codeInfo: 'ban Format File Includes Code Information:',
    bomFile: 'Output bom Format File:',
    plankHeight: 'Board Height Description:',
    plankNum: 'Board Number Description:',
    backZpoint: 'Back Slot Z-Coordinate:',
    plankThick: 'Board Thickness',
    slotDeep: 'Slot Depth',
    outputMprFile: 'File Type',
    oldMPR: 'Old MPR',
    newMPR: 'New MPR',
    mprFileZside:
      'Z-axis Reference Plane for Side Holes in Five/Six-Sided Drill MPR File',
    fileTypeOffline:
      'The currently selected file type for the five/six-sided drill has been discontinued. Please confirm!',
    permissionErr:
      'Identity information has been lost. Please reopen this page from the cabinet!',
    banFileSetting: 'ban Format File Settings',
    banFileCodeInfo: 'ban Format File Contains Code Information:',
    huahuaLine: 'Huahua Line',
  },

  // PTP
  PTP: {
    title: 'PTP',
    outputFileFormat: 'Output File Format',
    outputDefaultFileFormat: 'Output Default File Format',
    outputMPRFormat: 'Output MPR File Format',
    fileFormatSettings: 'File Format Settings',
    wrapSettings: 'Line Wrap Format Settings:',
    enCodeSettings: 'File Encoding Format Settings:',
    utf8bom: 'UTF-8 with BOM',
    partCoordinate: 'Part Coordinate',
    xPointSymbol: 'X-Axis Coordinate:',
    yPointSymbol: 'Y-Axis Coordinate:',
    zPointSymbol: 'Z-Axis Coordinate:',
    upRight: 'Positive Value',
    negative: 'Negative Value',
    plankMirroring: 'Board Mirroring',
    plankMirrorSetting: 'Board Mirroring Settings:',
    xAxis: 'X-Axis Mirroring',
    yAxis: 'Y-Axis Mirroring',
    mirrorTip: '(Use this feature when mirroring the board)',
    knifeTable: {
      type: 'Type',
      diameter: 'Diameter Φ',
      offsetX: 'Offset X',
      offsetY: 'Offset Y',
      offsetZ: 'Offset Z',
      offsetNo: 'Offset Number',
      drillVelocity: 'Drilling Velocity [F] mm/min',
      cutVelocity: 'Cutting Velocity [F] mm/min',
      rotationSeep: 'Rotation Speed [S] r/min',
      insertRow: 'Insert Row',
      deleteRow: 'Delete Row',
    },
    labelPool: 'Data Label Pool',
    dragTip:
      'Drag or click to copy labels from the label pool to the input box',
    procedure: 'Procedure',
    beginCode: 'Procedure Start Code:',
    endCode: 'Procedure End Code:',
    zzdKnifeBeginCode: 'Spindle Knife Change Start Code:',
    spindleCutter: 'Spindle Cutter',
    zzdKnifeEndCode: 'Spindle Knife Change End Code:',
    verticalDrill: 'Vertical Drill',
    czzBeginCode: 'Vertical Drill Startup Code:',
    czzEndCode: 'Vertical Drill Shutdown Code:',
    czzKnifeBeginCode: 'Vertical Drill Knife Change Start Code:',
    czzKnifeEndCode: 'Vertical Drill Knife Change End Code:',
    sidetrack: 'Side Drill',
    czBeginCode: 'Side Drill Startup Code:',
    czEndCode: 'Side Drill Shutdown Code:',
    czKnifeBeginCode: 'Side Drill Knife Change Start Code:',
    czKnifeEndCode: 'Side Drill Knife Change End Code:',
    pauseCode: 'Pause Code:',
    zSafeHeight: 'Z-Axis Safe Height',
    auxiliaryFunction: 'Auxiliary Function',
    xhDedicatedInfo: 'Generate Dedicated Information for Xinghui Devices',
    xhCheckBox: 'Data:',
    outputSettings: 'Output Settings:',
    partExegesis: 'Generate Part Size Annotations in the File',
    knifeNo: 'Knife Number',
    specailKnifeNo: 'Special Knife Number for Processing Unusual Shapes:',
    slotKnifeNo: 'Slot Cutting Knife Number:',
    fileNamePlaceholder: 'Custom Cutting List',
    slotDrill: 'Straightener Knife',
  },

  // Glass Cutting Machine
  glassEquip: {
    title: 'Glass Cutting Machine',
    fileType: 'Processing File Type:',
    sizeReparation: 'Board Size Compensation:',
    cutPreSpace: 'Reserved Spacing for Board Cutting:',
    divideIntoFolders:
      'Organize Files into Different Folders Based on Glass Color, Material, and Thickness:',
  },
  translate: {
    search:
      'Please enter the Chinese or official translation to find the entry',
    en: 'English',
    kz: 'Kazak',
    vi: 'Vietnamese',
    customTranslate: 'Click to add a custom translation',
    customText: 'custom translation',
    targetLang: 'official translation',
    customText: 'Custom translation',
    text: 'Chinese',
  },
  preLayoutSetting: {
    title: 'Pre-layout Settings',
    titleTip:
      'You can preset layout parameters first, layout first, and then select the production line',
    settingTip:
      'The parameters set within the production line need to be consistent with the following pre-layout parameters to output the production file, applicable only to cutting machines',
    emptyKnife: 'Tool not filled',
    noModify: 'No changes in settings, no need to save',
    reSet: 'Reset',
    gayPosition: 'Gray squares can switch surplus material placement position',
    surplusPosition: 'Current surplus material placement position',
    click: 'Click',
    grayStart: 'Gray squares can switch the starting point of the workstation',
    currentStart: 'Current workstation starting point',
    holeSlotSetting: 'Punching slot processing settings',
    knifeDiameter: 'Cutting tool diameter',
    currentVersion: 'Current version',
    on: 'On',
    off: 'Off',
    firstShort: 'First Cut - Short Edge Allowance',
    firstLong: 'First Cut - Long Edge Allowance',
    cuttingSplitLong: 'Cutting Long Boards in Two Passes - Long Edge Exceeds',
    cuttingSplitShort: 'Cutting Long Boards in Two Passes - Short Edge Exceeds',
    plankThick: 'Regular Board Thickness ≥ ',
    normalPlank: 'Regular Board',
    confilictTip: 'There are setting conflicts: {confilictTotal} items',
    selectLine: 'Select Production Line',
    selectLinePlaceholder: 'Please select a production line',
    lineConfilictTip: 'The selected production line conflicts with {line}',
    preWay: 'Pre-layout Scheme',
    paibanErrTip:
      'Changes in the [length and short edge volume] for long board processing may lead to layout failure',
    paibanTip:
      'The current layout requires re-layout due to the specified cutting tool for large board material',
    reLayout: 'Re-layout with the current production line',
    fetchErr: 'Failed to fetch pre-layout settings',
    resetSuccess: 'Reset successful',
    resetConfirm: 'Are you sure you want to revert to the historical version?',
    prolineName: 'Production Line Name',
    layoutGap: 'Layout Gap',
    generalThickness: 'Secondary Cutting Object - Regular Board Thickness',
    cutGeneralPlank: 'Secondary Cutting Object - Regular Board',
    cutSingleDoor: 'Secondary Cutting Object - Door Panel',
    changeProline: 'Switch Production Line',
    backTip: 'Please return to global layout to select a production line',
    preLayout: 'Layout According to Pre-layout Scheme',
    preWayEmpty: '(Empty)Pre-layout Scheme',
    specialConfilictTip:
      'The selected production line affects the arc milling bottom process parameters, re-layout is required',
  },
  lunbo: {
    tips: 'Operating Skills',
    introduction: 'Function Introduction',
    qtDownloadText:
      'Warm reminder: If the downloaded file is incomplete, please go to the cloud typesetting webpage to download it!',
  },
}
