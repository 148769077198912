// Qt.include("SpecialDrillFiles.js")
import { dealEdgeInfoChange, judgeSlotIsRotate } from '@/util/plankCommonFuncs'

import store from '../store'
import { ncSettings, reversalPlank } from './LayoutFuncs'
import {
  adjustHoleDirection,
  changeSlotsPositionByNCsetting,
  rotatePart,
  rotatePartInternal,
  rotatePlankWhenSlotAtTheEdge,
} from './LayoutTool'
import { rotate180Part } from './LayoutTool.js'
import { toDecimal } from './commonFuncs'

//前封边映射信息
const formerMap = {
  '←': '↑',
  '↓': '→',
  '→': '↓',
  '↑': '←',
}
const openMap = {
  left: 'top',
  bottom: 'right',
  right: 'bottom',
  top: 'left',
}

function outputToFile(autoOpenExplorer, isBackupNc, fileName) {
  var files = []

  var msg = gg.loadEncryptedFileToString(flieCache)

  var path = ''

  var result = getNameList(projectInfo, roomInfos, layoutType)
  var projectNameList = result['projectNameList']
  var ordersLength = projectNameList.length
  var projectIdentifiList = []
  if (orderCodeAsDirectionName) {
    //导出加工文件夹按订单号命名
    projectIdentifiList = result['projectCodeList']
  } else {
    projectIdentifiList = result['projectNameList']
    for (var i in projectIdentifiList) {
      projectIdentifiList[i] = projectIdentifiList[i].replace(/:/g, '-')
    }
  }

  var roomNameList = result['roomNameList']
  var pathSuffix = 'NC文件夹' + '/'
  if (isBanlu) {
    var saveDir = '班鲁NC'
  }

  //班鲁NC文件目录需要层级"班鲁NC"进行区分
  function iAddSaveDirFunc(path, addDirName) {
    return path + addDirName + '/'
  }

  if (projectIdentifiList.length > 1) {
    if (projectIdentifiList.length > 3) {
      pathSuffix +=
        projectIdentifiList[0] + '等' + projectIdentifiList.length + '个订单/'
    } else {
      pathSuffix += projectIdentifiList.join('_') + '/'
    }

    if (isBanlu) {
      pathSuffix = iAddSaveDirFunc(pathSuffix, saveDir)
    }
  } else if (roomNameList.length > 0) {
    var rooms = roomNameList
    if (rooms.length > 1) {
      pathSuffix += projectIdentifiList[0] + '/'
      if (isBanlu) {
        pathSuffix = iAddSaveDirFunc(pathSuffix, saveDir)
      }
    } else {
      pathSuffix += projectIdentifiList[0] + '_' + rooms[0] + '/'
      if (isBanlu) {
        pathSuffix = iAddSaveDirFunc(pathSuffix, saveDir)
      }
    }
  } else {
    result = getOrderNoList(layoutList)
    projectIdentifiList = result['projectCodeList']
    projectNameList = result['projectNameList']
    if (projectNameList && projectNameList.length > 0) {
      for (var i in projectNameList) {
        projectNameList[i] = projectNameList[i].replace(/:/g, '-')
      }
    }
    if (
      orderCodeAsDirectionName &&
      projectIdentifiList &&
      projectIdentifiList.length
    ) {
      //导出加工文件夹按订单号命名
      pathSuffix += projectIdentifiList.join('_') + '/'
    } else {
      pathSuffix += 'other' + '/'
    }
    if (isBanlu) {
      pathSuffix = iAddSaveDirFunc(pathSuffix, saveDir)
    }
  }

  pathSuffix = pathSuffix.replace(' ', '-')

  fileName = fileName.replace(/:/g, '')
  fileName = fileName.replace(/\s+/g, '')

  //msg为用户设置的导出路径
  if (msg && msg !== undefined && msg !== '') {
    msg = libGG.parseJson(msg)

    path = gg.getStandardPath(msg.flieurl) + '/' + pathSuffix

    if (isBackupNc) {
      path = gg.getStandardPath(msg.flieurl) + '/' + pathSuffix + fileName + '/'
    }
  } else {
    if (device.isMac) {
      path = gg.getAppDownloadPath() + '/' + pathSuffix
    } else {
      path = gg.getAppAbsolutePath() + pathSuffix
    }

    if (isBackupNc) {
      path = gg.getTempRoot() + pathSuffix + fileName + '/'
    }
  }

  path = path.replace(new RegExp('\\|', 'g'), '')
  gg.rmdir(path)
  gg.mkdir(path)

  if (isBanlu) {
    for (var i in banluFiles) {
      var fileInfo = banluFiles[i]
      var filePath = fileInfo['path']
      var tmpPath = fileInfo['fileType'] + '/'
      if (fileInfo['fileType'] === 'label') {
        //南兴贴标机
        if (labelFormat === 'nanxing') {
          var labelPath = getNanXingLabelPath('', filePath)
        } else {
          labelPath = makeUpMaterialPathFunc('', filePath, 'labelBanlu')
        }
        //设置的贴标文件夹名
        if (ncLabelDirName) {
          labelPath += ncLabelDirName + '/'
        }
        tmpPath = labelPath
      }
      var fileNmae = filePath.substring(
        filePath.lastIndexOf('/') + 1,
        filePath.length
      )

      // 豪德贴标机使用柜柜的文件名时
      if (
        fileInfo['fileType'] === 'label' &&
        !exportUsingBanlu &&
        labelFormat === 'haode'
      ) {
        var index = fileNmae.lastIndexOf('.')
        var name = fileNmae.substring(0, index)
        var suffix = fileNmae.substring(index)

        var mArr = name.split('_')
        if (mArr.length === 8) {
          var pathArr = path.split('/')
          var projectName =
            projectNameList && projectNameList.length
              ? projectNameList.join('_')
              : pathArr[pathArr.length - 3]
          if (doubleEdgeCombine) {
            var plankInfo =
              mArr[3] + ' ' + mArr[4] + ' ' + String(Number(mArr[5])) + '板'
            fileNmae =
              mArr[0] +
              '_' +
              genExportNewFileName(
                plankInfo,
                projectName,
                'nc',
                doubleEdgeCombine,
                mArr[1]
              ) +
              suffix
          } else {
            plankInfo =
              mArr[1] +
              '_' +
              mArr[2] +
              '@' +
              mArr[3] +
              ' ' +
              mArr[4] +
              ' ' +
              String(Number(mArr[5])) +
              '板'
            fileNmae =
              mArr[0] +
              '_' +
              genExportNewFileName(plankInfo, projectName) +
              suffix
          }
        }
      }

      gg.copyFile(filePath, path + tmpPath + fileNmae)
    }
  }

  //星辉格式暂时只能直接导出，不能合并
  if (isBanlu && exportFormat === 'xinghui') {
    files = files.ggConcat(exportNC(path, projectNameList))
  } else {
    if (singleStationCombine) {
      files = files.ggConcat(exportNCSingleStation(path))
    } else if (doubleStationCombine) {
      files = files.ggConcat(exportNCDoubleStation(path))
    } else if (doubleEdgeCombine) {
      files = files.ggConcat(exportNCDoubleEdge(path, projectNameList))
    } else {
      files = files.ggConcat(exportNC(path, projectNameList))
    }
  }

  // 拉米诺导出支持 -> 以此为例
  if (lamelloDrill) {
    // saveLamelloWithXML(ggData, path + "/拉米诺_测试/")
    gg.saveLamelloWithXML(ggData, path + '/拉米诺/')
  }

  // PTP导出支持
  if (ptpHoleDrill && !isBanlu) {
    PtpHoleNC.generator(ggData, path + '/PTP/', ordersLength)
  }

  var buyerAddress
  if (libGG.buyer_address) buyerAddress = libGG.buyer_address
  else buyerAddress = '孔槽文件'
  buyerAddress = buyerAddress.replace(new RegExp('[:<>/|:"*?]', 'g'), '')

  // 侧孔导出支持
  if (sideHoleDrill) {
    var fileSuffix = '.nc'
    var settings = ncCfg['sideHoleDrill']
    if (
      settings &&
      settings['enabled'] &&
      settings['style'] === gg.getTrans('自定义四')
    ) {
      fileSuffix = settings['settingV4']['fileSuffix']
    }
    var sideHoleNcPath = path + '侧孔机/NC/'
    var sideHoleTxtPath = path + '侧孔机/TXT/'
    var sideHoleXmlPath = path + '侧孔机/XML3/'

    var sideNcsKeys = Object.keys(sideNcs)
    for (var j in sideNcs) {
      var iFileName = j

      //批量生产时五六面钻,ptp,侧孔机按订单分类
      if (output56FDrillSortByCode) {
        var orderNo = iGetOrderNo(sideNcOrders, j)
        if (orderNo) {
          sideHoleNcPath = path + '侧孔机/' + orderNo + '/NC/'
          sideHoleTxtPath = path + '侧孔机/' + orderNo + '/TXT/'
          sideHoleXmlPath = path + '侧孔机/XML3/'
        }
      }

      gg.saveStringToFile(
        compatibleSepChar(sideNcs[j]),
        sideHoleNcPath + iFileName + fileSuffix,
        sideHoleEncodingFormat
      )
      files.push(sideHoleNcPath + iFileName + fileSuffix)

      gg.saveStringToFile(
        compatibleSepChar(sideNcs[j]),
        sideHoleTxtPath + iFileName,
        sideHoleEncodingFormat
      )
      files.push(sideHoleTxtPath + iFileName)
    }

    var nGgData = deepCopy(ggData)
    files = files.ggConcat(
      gg.saveFiveSidedDrillWithXML(
        curProductionLine.decimal,
        nGgData,
        sideHoleXmlPath
      )
    )
  }

  // 标签机导出支持
  if (labelingMachine && (!isBanlu || !nanXingLabelingMachine)) {
    var suffix = '.' + getSuffix()
    for (var k in lmNcs) {
      var ncfileName = genExportNewFileName(k, fileName, 'labelingMachine')
      var lastPath = makeUpMaterialPathFunc(path, k, 'labelingMachine')
      //设置的贴标文件夹名
      if (ncLabelDirName) {
        lastPath += ncLabelDirName + '/'
      }
      if (labelMachineName && customizeSetting.indexOf('标签机开头') > -1) {
        gg.saveStringToFile(
          compatibleSepChar(lmNcs[k]),
          lastPath + ncfileName + suffix
        )
        files.push(lastPath + ncfileName + suffix)
      } else {
        gg.saveStringToFile(
          compatibleSepChar(lmNcs[k]),
          lastPath + 'p-' + ncfileName + suffix
        )
        files.push(lastPath + 'p-' + ncfileName + suffix)
      }
    }
  }

  // 五面钻导出支持
  if (dock5FDrill) {
    nGgData = deepCopy(ggData)

    //        if(dock56FDrill_curveSlots) {
    //            removeExtraHoleAndSlot(nGgData);
    //        }

    if (dock56FDrill_partHole) {
      removePartHole(nGgData)
    }

    if (dock56FDrill_excludeSlots) {
      removeAllSlot(nGgData)
    }

    if (dock56FDrill_partSlot) {
      removePartSlot(nGgData)
    }

    if (dock56FDrill_partSlots) {
      removeAllPartHole(nGgData)
    }

    change56FDrillData(nGgData)

    files = files.ggConcat(
      gg.saveSixSidedDrill(
        output56FDrillDecimalNum,
        nGgData,
        path + '/五面钻-' + buyerAddress,
        '/XML/',
        false,
        true,
        false,
        genSimpleLabelBarcode,
        output56FDrillSortByCode
      )
    )
    files = files.ggConcat(
      gg.saveSixSidedDrillWithXML2(
        output56FDrillDecimalNum,
        nGgData,
        path + '/五面钻-' + buyerAddress + '/XML2/',
        false,
        output5FDrillSlotKnifeCode,
        output5FDrillShapeKnifeCode
      )
    )
    files = files.ggConcat(
      gg.saveFiveSidedDrillWithXML(
        output56FDrillDecimalNum,
        nGgData,
        path + '/五面钻-' + buyerAddress + '/XML3/',
        output56FDrillSortByCode
      )
    )
    files = files.ggConcat(
      gg.saveSixSidedDrillWithXML2(
        output56FDrillDecimalNum,
        nGgData,
        path + '/五面钻(切割异形)' + buyerAddress + '/XML2/',
        true,
        output5FDrillSlotKnifeCode,
        output5FDrillShapeKnifeCode
      )
    )
    MprHoleNC.generator(nGgData, path + '/五面钻-' + buyerAddress, '/MPR/')
  }

  function outputDock6FDrill(data) {
    change56FDrillData(data)

    files = files.ggConcat(
      gg.saveSixSidedDrill(
        output56FDrillDecimalNum,
        data,
        path + '/六面钻-' + buyerAddress,
        '/BAN/',
        false,
        false,
        false,
        genSimpleLabelBarcode,
        output56FDrillSortByCode
      )
    )
    files = files.ggConcat(
      gg.saveSixSidedDrill(
        output56FDrillDecimalNum,
        data,
        path + '/六面钻-' + buyerAddress,
        '/BAN2/',
        false,
        true,
        false,
        genSimpleLabelBarcode,
        output56FDrillSortByCode
      )
    )
    files = files.ggConcat(
      gg.saveSixSidedDrill(
        output56FDrillDecimalNum,
        data,
        path + '/六面钻-' + buyerAddress,
        '/BAN3/',
        false,
        true,
        true,
        genSimpleLabelBarcode,
        output56FDrillSortByCode
      )
    )
    files = files.ggConcat(
      gg.saveSixSidedDrill(
        output56FDrillDecimalNum,
        data,
        path + '/六面钻-' + buyerAddress,
        '/BAN4/',
        false,
        false,
        true,
        genSimpleLabelBarcode,
        output56FDrillSortByCode
      )
    )
    files = files.ggConcat(
      gg.saveSixSidedDrill(
        output56FDrillDecimalNum,
        data,
        path + '/六面钻-' + buyerAddress,
        '/BAN_SC/',
        true,
        false,
        false,
        genSimpleLabelBarcode,
        output56FDrillSortByCode
      )
    )
    files = files.ggConcat(
      gg.saveSixSidedDrillWithXML1(
        output56FDrillDecimalNum,
        data,
        path + '/六面钻-' + buyerAddress,
        '/XML1/',
        genSimpleLabelBarcode,
        output56FDrillSortByCode
      )
    )
    files = files.ggConcat(
      gg.saveSixSidedDrillWithXML3(
        output56FDrillDecimalNum,
        data,
        path + '/六面钻-' + buyerAddress,
        '/XML3/',
        genSimpleLabelBarcode,
        output56FDrillSortByCode
      )
    )

    MprHoleNC.generator(data, path + '/六面钻-' + buyerAddress, '/MPR/')
  }

  // 六面钻导出支持
  if (dock6FDrill) {
    nGgData = deepCopy(ggData)

    if (!dock6FDrill_code) {
      removeCodeInfo(nGgData)
    }

    //        if(dock56FDrill_curveSlots) {
    //            removeExtraHoleAndSlot(nGgData);
    //        }

    if (dock56FDrill_partHole) {
      removePartHole(nGgData)
    }

    if (dock56FDrill_excludeSlots) {
      removeAllSlot(nGgData)
    }

    if (dock56FDrill_partSlot) {
      removePartSlot(nGgData)
    }

    if (dock56FDrill_partSlots) {
      removeAllPartHole(nGgData)
    }

    if (dock6FDrill_towSideConHole) {
      dock6FDrillConHoleByTowFace(nGgData)
    }

    outputDock6FDrill(nGgData)
  }

  // 六面钻导出支持(除异形板件及裤抽的孔)
  if (dock6FDrill_partHole) {
    outputDock6FDrill(ggData)
  }

  gg.actionLog('ncexport')

  var room_id = result['room_id']
  var layoutName
  if (room_id) {
    layoutName = projectNameList.join('+') + '-' + roomNameList.join('   ')
  } else {
    layoutName = projectNameList.join('+') + '-' + '批量生产'
  }
  gg.copyFile(
    gg.getTempRoot() + 'layout/layout.json',
    path + layoutName + '.json'
  )
  var zpath = gg.getAppAbsolutePath() + 'base/7z.exe'
  var zipPath = gg.getTempRoot() + getNowDate(true) + '.zip'
  gg.openExternalProcess('', zpath, 'a ' + zipPath + ' ' + path)
  if (isBanlu) {
    layoutName += '_Banlu'
  }

  uploadNcdelay(2000, function () {
    var url = dbProjectObj.uploadFileToOssDir(zipPath, 'nc')

    var rids = ''
    for (var k in roomInfos) {
      var roomInfo = roomInfos[k]
      var rid = roomInfo['id']
      rids += rid + ','
    }
    rids = rids.substring(0, rids.length - 1)
    var params = {
      uid: userService.uid,
      url: url,
      name: layoutName,
      version: gg.getLocalConfig('version'),
      rids: rids,
    }
    globalJSTool.httpPost(
      mPHP_gtc + 'user/add_nc_log',
      params,
      function success(result) {}
    )
  })

  if (autoOpenExplorer) {
    gg.openLocalPath(path)
  }

  savePlateInfo()
  return files
}

function change56FDrillData(ggData) {
  // 判断槽的位置
  function iCheckSlotPos(slot, pCenter) {
    var width = slot['width']
    var x1 = slot['x1']
    var y1 = slot['y1']
    var x2 = slot['x2']
    var y2 = slot['y2']
    var abs = Math.abs

    var sx = Math.abs(x1 - x2)
    var sy = Math.abs(y1 - y2)
    if (Math.abs(sx - width) <= 0.0001) {
      var ox = (x1 + x2) / 2
      if (ox < pCenter.x) {
        return 'l'
      } else if (ox > pCenter.x) {
        return 'r'
      }
    } else {
      var oy = (y1 + y2) / 2
      if (oy < pCenter.y) {
        return 'f'
      } else if (oy > pCenter.y) {
        return 'b'
      }
    }

    return 'c'
  }

  // 判断异形的位置及每边异形的权重
  function iCheckCurvePos(curve, pWidth, pHeight) {
    var posMap = {
      l: 0,
      r: 0,
      b: 0,
      f: 0,
    }

    var tmpCurve = deepCopy(curve)

    // 逆时针转成顺时针
    if (iCurveArea(tmpCurve) > 0) {
      tmpCurve.reverse()
    }

    tmpCurve.push(tmpCurve[0])
    var lstPt
    var passPt = false
    for (var i in tmpCurve) {
      var pt = tmpCurve[i]
      var x = jjFixed2(pt.x)
      var y = jjFixed2(pt.y)

      var pt1 = [0, 0]
      var pt2 = [0, pHeight]
      var pt3 = [pWidth, pHeight]
      var pt4 = [pWidth, 0]

      if (
        Math.abs(x) < 0.001 ||
        Math.abs(y) < 0.001 ||
        Math.abs(pWidth - x) < 0.001 ||
        Math.abs(pHeight - y) < 0.001
      ) {
        if (
          lstPt &&
          ((passPt && Math.abs(x) > 0.01 && Math.abs(pWidth - x) > 0.01) ||
            (passPt && Math.abs(y) > 0.01 && Math.abs(pHeight - y) > 0.01) ||
            (lstPt.x !== x && lstPt.y !== y))
        ) {
          var xc = x - lstPt.x
          var yc = y - lstPt.y
          if (xc < 0) {
            posMap['f'] = Math.max(posMap['f'], -xc)
          } else {
            posMap['b'] = Math.max(posMap['b'], xc)
          }
          if (yc < 0) {
            posMap['r'] = Math.max(posMap['r'], -yc)
          } else {
            posMap['l'] = Math.max(posMap['l'], yc)
          }
        }
        lstPt = {
          x: x,
          y: y,
        }
        passPt = false
      } else {
        passPt = true
      }
    }

    var mapKeys = Object.keys(posMap).sort(function (a, b) {
      return posMap[b] - posMap[a]
    })
    var index = 0
    var lastValue = 0
    for (var key in mapKeys) {
      if (posMap[mapKeys[key]]) {
        if (lastValue !== posMap[mapKeys[key]]) {
          ++index
        }
        lastValue = posMap[mapKeys[key]]
        var value = Math.pow(0.1, index)
        posMap[mapKeys[key]] = Math.round(value * 10000) / 10000
      }
    }
    return posMap
  }

  // 旋转板件
  function iRotate(data, ineedAdjust) {
    var slots = data['slots']
    var holes = data['holes']
    var shape = data['shape']

    var curve = data['curve']
    var cutCurve = data['cutCurve']
    var grooves = data['grooves']

    var width = data['width']
    var height = data['height']
    var rect = data['rect']
    var beforeHeight = height

    var texDir = data['texDir']

    var fe = Number(data['fe'])
    var be = Number(data['be'])
    var re = Number(data['re'])
    var le = Number(data['le'])

    var cutW = width - (re + le)
    var cutH = height - (be + fe)

    var ocpt = Qt.point(width / 2, height / 2)
    var cpt = Qt.point(cutW / 2, cutH / 2)

    //开始一系列旋转操作
    var tmpW = width
    width = height
    height = tmpW
    data['width'] = width
    data['height'] = height
    data['rect'] = Qt.rect(0, 0, width, height)

    data['fe'] = String(re)
    data['re'] = String(be)
    data['be'] = String(le)
    data['le'] = String(fe)

    if (texDir === 'normal') {
      data['texDir'] = 'reverse'
    } else if (texDir === 'reverse') {
      data['texDir'] = 'normal'
    }

    function rotatePt(pt, opt) {
      var x = pt['x'] - opt.x
      var y = pt['y'] - opt.y

      pt['x'] = y + opt.y
      pt['y'] = -x + opt.x
    }

    for (var i in slots) {
      var slot = slots[i]
      var face = slot['face']

      var tmpPt1 = Qt.point(slot['x1'], slot['y1'])
      var tmpPt2 = Qt.point(slot['x2'], slot['y2'])
      //旋转前对侧孔进行特殊处理
      if (face === 'Front' || face === 'Back') {
        var iSlotyF = slot['y1']
        var iSlotyS = slot['y2']
        iSlotyF = beforeHeight - iSlotyF
        iSlotyS = beforeHeight - iSlotyS
        slot['y1'] = iSlotyF
        slot['y2'] = iSlotyS
      }
      rotatePt(tmpPt1, ocpt)
      rotatePt(tmpPt2, ocpt)
      slot['x1'] = tmpPt1.x
      slot['x2'] = tmpPt2.x
      slot['y1'] = tmpPt1.y
      slot['y2'] = tmpPt2.y
      slot['face'] = ichangeRoateFace(face)
      //旋转后对侧孔的处理
      if (face === 'Front' || face === 'Back') {
        var iSlotyF = slot['y1']
        var iSlotyS = slot['y2']
        iSlotyF = beforeHeight - iSlotyF
        iSlotyS = beforeHeight - iSlotyS
        slot['y1'] = iSlotyF
        slot['y2'] = iSlotyS
      }
    }

    for (i in holes) {
      var hole = holes[i]
      face = hole['face']
      //旋转前对侧孔进行特殊处理
      if (face === 'Front' || face === 'Back') {
        var iy = hole['y']
        iy = beforeHeight - iy
        hole['y'] = iy
      }
      var tmpPt = Qt.point(hole['x'], hole['y'])
      rotatePt(tmpPt, ocpt)
      hole['x'] = tmpPt.x
      hole['y'] = tmpPt.y
      hole['face'] = ichangeRoateFace(face)
      //旋转后对侧孔的处理
      if (face === 'Left' || face === 'Right') {
        iy = hole['y']
        iy = height - iy
        hole['y'] = iy
      }
    }

    for (i in shape) {
      var p = shape[i]
      rotatePt(p, ocpt)
    }

    for (i in curve) {
      p = curve[i]
      rotatePt(p, ocpt)
    }

    for (i in cutCurve) {
      p = cutCurve[i]
      rotatePt(p, cpt)

      var angle = p['angle']

      if (angle !== -1) {
        rotatePt(p['center'], cpt)
      }
    }

    for (i in grooves) {
      var groave = grooves[i]
      for (var j in groave) {
        p = groave[i]
        rotatePt(p, cpt)

        angle = p['angle']
        if (angle !== -1) {
          rotatePt(p['center'], cpt)
        }
      }
    }
  }

  //改变旋转边face
  function ichangeRoateFace(face) {
    var resFace = ''
    switch (face) {
      case 'Left':
        resFace = 'Back'
        break
      case 'Front':
        resFace = 'Left'
        break
      case 'Right':
        resFace = 'Front'
        break
      case 'Back':
        resFace = 'Right'
        break
      default:
        resFace = face
    }
    return resFace
  }

  // 计算旋转次数
  function iGetRoateCount(dirMap) {
    var count = 0
    var value = -1
    var dirHash = ['l', 'f', 'r', 'b']
    for (var i = 0; i < 4; ++i) {
      var i1 = 0
      var i2 = 0
      if (dir === 'frontLeft') {
        i1 = 0
        i2 = 1
      } else if (dir === 'backLeft') {
        i1 = 0
        i2 = 3
      } else if (dir === 'frontRight') {
        i1 = 1
        i2 = 2
      } else if (dir === 'backRight') {
        i1 = 2
        i2 = 3
      }

      var tmpValue =
        dirMap[dirHash[(i + i1) % 4]] + dirMap[dirHash[(i + i2) % 4]]
      if (tmpValue > value) {
        value = tmpValue
        count = i
      }
    }
    return count
  }

  // 面积正负值，用来判断顺逆时针
  function iCurveArea(curve) {
    if (!curve) {
      return 0
    }
    var area = 0
    var len = curve.length
    for (var i = 0, j = len - 1; i < len; ++i) {
      area += curve[i].y * (curve[j].x - curve[i].x)
      j = i
    }
    return area / 2
  }

  for (var i in ggData) {
    var data = ggData[i]
    var rect = data['rect']
    var width = rect['width']
    var height = rect['height']
    var slots = data['slots']
    var shape = data['shape']

    if (Math.max(width, height) > 1220) {
      var putDir = output56FDrillLayoutDir
      if (
        (putDir === 'Vertical' && parseInt(width) - parseInt(height) > 0) ||
        (putDir === 'Horizontal' && parseInt(height) - parseInt(width) > 0)
      ) {
        iRotate(data, true)
      }
    } else {
      var dir = output56FDrillSlotDir
      if (dir === 'default') {
        continue
      }

      var dirMap = {}
      dirMap['l'] = 0
      dirMap['b'] = 0
      dirMap['r'] = 0
      dirMap['f'] = 0

      for (var si in slots) {
        var slot = slots[si]
        var pos = iCheckSlotPos(slot, Qt.point(width / 2, height / 2))
        if (pos === 'c') {
          continue // 位于板件中心的槽不考虑
        }
        dirMap[pos] = 1
      }
      var posMap = iCheckCurvePos(shape, width, height)
      for (var pi in posMap) {
        dirMap[pi] += posMap[pi]
      }
      var count = iGetRoateCount(dirMap)
      for (var ind = 0; ind < count; ++ind) {
        iRotate(data)
      }
    }
  }
}

function savePlateInfo() {
  var path = gg.getTempRoot() + 'plateInfo'
  gg.rmdir(path)
  gg.mkdir(path)
  for (var i = 0; i < layoutList.length; i++) {
    var stock = layoutList[i]
    var parts = stock['parts']

    var saveData = {}

    for (var j in parts) {
      var part = parts[j]

      var tmpData = {}
      var name = part['partName']
      var holes = part['holes']
      var sholes = part['sholes']
      var slots = part['slots']
      var sslots = part['sslots']
      var startPt = Qt.point(part['startX'], part['startY'])
      tmpData['holes'] = holes
      tmpData['sholes'] = sholes
      tmpData['slots'] = slots
      tmpData['sslots'] = sslots
      tmpData['startPt'] = startPt
      saveData[name] = tmpData
    }
    gg.saveByteArrayToFile(
      JSON.stringify(saveData, null, 2),
      path + '/bigPlate' + (i + 1) + '.txt'
    )
  }
}

//班鲁NC数据转换
export function viewDataToNCData(data, type, excludeLock) {
  var ncSetting = store.state.ncSetting
  var startPosition = ncSetting.startPosition
  var xyReverse = ncSetting.xyReverse
  // let tmpData = deepCopy(data)
  let tmpData = JSON.parse(JSON.stringify(data))
  // 靠边槽旋转
  if (xyReverse) {
    tmpData.forEach((plank) => {
      plank.parts.forEach((part) => {
        if (!part.isRotateFrontEdge) {
          let { startX, startY, rect, plankHeight, plankWidth } = part
          if (ncSetting.startPosition === '左上角') {
            startY = plankHeight - rect.height - startY
          } else if (ncSetting.startPosition === '右上角') {
            startY = plankHeight - rect.height - startY
            startX = plankWidth - rect.width - startX
          } else if (ncSetting.startPosition === '右下角') {
            startX = plankWidth - rect.width - startX
          }
          if (
            judgeSlotIsRotate(
              part,
              ncSetting,
              startX,
              startY,
              plank.plankWidth,
              plank.plankHeight,
              plank.otherPlate
            )
          ) {
            if (type) {
              rotate180Part(part)
            }
          }
        }
      })
    })
  }
  for (var i in tmpData) {
    var stock = tmpData[i]
    // 如果大板被锁定跳过当次迭代
    if (tmpData[i].isLocked && excludeLock) {
      continue
    }
    var parts = stock['parts']
    var plankWidth = stock['plankWidth']
    var plankHeight = stock['plankHeight']
    for (var j in parts) {
      var part = parts[j]

      if (type === 'toView' && xyReverse) {
        if (startPosition === '左下角' || startPosition === '右上角') {
          // rotatePart(part)
          // rotatePart(part)
          rotate180Part(part)
        }
      }
      var holes = part['holes']
      var slots = [...part['slots'], ...(part['handleSlopes'] ?? [])]
      var edgeInfo = part['edgeInfo']
      var sholes = part['sholes']
      var sslots = part['sslots']
      var curveHoles = part['curveHoles'] ?? []
      var millInfo = part['millInfo'] ?? []
      var oriCurveHoles = part['oriCurveHoles']

      var sslotInfo = part['sslotInfo']
      var sholeInfo = part['sholeInfo']
      let lightEdgeInfo = part['lightEdgeInfo']
      let model_front_edge = part['model_front_edge']
      let openDir = part['openDir']
      let thick = part['thick']
      let doorOpenSide = part['doorOpenSide']
      var left = Number(
        edgeInfo.substring(edgeInfo.indexOf('←') + 1, edgeInfo.indexOf('↓'))
      )
      var bottom = Number(
        edgeInfo.substring(edgeInfo.indexOf('↓') + 1, edgeInfo.indexOf('→'))
      )
      var right = Number(
        edgeInfo.substring(edgeInfo.indexOf('→') + 1, edgeInfo.indexOf('↑'))
      )
      var top = Number(edgeInfo.substring(edgeInfo.indexOf('↑') + 1))
      // 如果是第一次进入排版的时候, 处于xy互换状态且工位起始点时左下角和右上角, 则将异形板件旋转180度
      //全部调整成左下角
      if (startPosition === '左上角') {
        part['startY'] = plankHeight - part['rect'].height - part['startY']
      } else if (startPosition === '左下角') {
      } else if (startPosition === '右上角') {
        part['startY'] = plankHeight - part['rect'].height - part['startY']
        part['startX'] = plankWidth - part['rect'].width - part['startX']
      } else if (startPosition === '右下角') {
        part['startX'] = plankWidth - part['rect'].width - part['startX']
      }
      part['startX'] = toDecimal(part['startX'], store.state.ncSetting.decimal)
      part['startY'] = toDecimal(part['startY'], store.state.ncSetting.decimal)
      if (xyReverse) {
        // if (part['is_high_gloss_plank'] && part['high_gloss_side'] > 0) {
        //   reversalPlank(part)
        // }
        var path = part['path']
        var oPath = part['oPath']
        if (path) {
          for (var pi in path) {
            for (var pj in path[pi]) {
              path[pi][pj] = {
                x: path[pi][pj]['y'],
                y: path[pi][pj]['x'],
              }
            }
          }
        }
        if (oPath) {
          for (var pi in oPath) {
            for (var pj in oPath[pi]) {
              oPath[pi][pj] = {
                x: oPath[pi][pj]['y'],
                y: oPath[pi][pj]['x'],
              }
            }
          }
        }
        if (part.realCurve) {
          const realCurve = part.realCurve
          for (var pi in realCurve) {
            const temp = realCurve[pi].x
            realCurve[pi].x = realCurve[pi].y
            realCurve[pi].y = temp
          }
        }
        if (part.realCurveEx) {
          part.realCurveEx.forEach((realCurveEx) => {
            for (var pi in realCurveEx) {
              const temp = realCurveEx[pi].x
              realCurveEx[pi].x = realCurveEx[pi].y
              realCurveEx[pi].y = temp
            }
          })
        }
        edgeInfo = '←' + top + '↓' + right + '→' + bottom + '↑' + left
        lightEdgeInfo = dealEdgeInfoChange(lightEdgeInfo, 'xyReverse')
        model_front_edge = formerMap[model_front_edge]
        doorOpenSide = openMap[doorOpenSide]
        if (curveHoles) {
          for (var pi in curveHoles) {
            let path = []
            curveHoles[pi].side *= -1
            for (var pj in curveHoles[pi]['path']) {
              let p = curveHoles[pi]['path'][pj]
              path.push({
                x: p['y'],
                y: p['x'],
              })
            }
            part['curveHoles'][pi]['path'] = path
          }
        }

        if (millInfo) {
          for (let pi in millInfo) {
            let shape = []
            millInfo[pi].side *= -1
            for (let pj in millInfo[pi]['shape']) {
              let p = millInfo[pi]['shape'][pj]
              shape.push({
                x: p['y'],
                y: p['x'],
              })
            }
            part['millInfo'][pi]['shape'] = shape
          }
        }

        if (oriCurveHoles) {
          for (var pi in oriCurveHoles) {
            let path = []
            oriCurveHoles[pi].side *= -1
            for (var pj in oriCurveHoles[pi]['path']) {
              let p = oriCurveHoles[pi]['path'][pj]
              path.push({
                x: p['y'],
                y: p['x'],
              })
            }
            part['oriCurveHoles'][pi]['path'] = path
          }
        }

        function changeHoleInfo(str) {
          var charLst = str.split('')
          for (var i in charLst) {
            if (charLst[i] === '↓') {
              charLst[i] = '↑'
            } else if (charLst[i] === '↑') {
              charLst[i] = '↓'
            }
          }
          return charLst.join('')
        }
        if (sslotInfo) {
          sslotInfo = changeHoleInfo(sslotInfo)
        }
        if (sholeInfo) {
          sholeInfo = changeHoleInfo(sholeInfo)
        }

        if (type !== 'toView') {
          part['startY'] = plankHeight - part['rect'].height - part['startY']
          part['startX'] = plankWidth - part['rect'].width - part['startX']
        } else {
          if (startPosition === '左下角' || startPosition === '右上角') {
            part['startX'] = plankWidth - part['rect'].width - part['startX']
            part['startY'] = plankHeight - part['rect'].height - part['startY']
          }
        }

        part['startY'] = toDecimal(
          part['startY'],
          store.state.ncSetting.decimal
        )
        part['startX'] = toDecimal(
          part['startX'],
          store.state.ncSetting.decimal
        )

        var tmp = part['startX']
        part['startX'] = part['startY']
        part['startY'] = tmp
        // part["rect"] = Qt.rect(0, 0, part["rect"]["height"], part["rect"]["width"]);
        part['rect'] = {
          x: 0,
          y: 0,
          width: part['rect']['height'],
          height: part['rect']['width'],
        }
        part['realRect'] = {
          x: 0,
          y: 0,
          width: part['realRect']['height'],
          height: part['realRect']['width'],
        }
        // part["oRect"] = Qt.rect(0, 0, part["oRect"]["height"], part["oRect"]["width"]);
        part['oRect'] = {
          x: 0,
          y: 0,
          width: part['oRect']['height'],
          height: part['oRect']['width'],
        }

        const d = {
          1: 4,
          2: 3,
          3: 2,
          4: 1,
        }

        for (var hi in holes) {
          var hole = holes[hi]
          hole['side'] *= -1
          hole['center'] = {
            x: hole['center']['y'],
            y: hole['center']['x'],
          }
          hole['ocenter'] = {
            x: hole['ocenter']['y'],
            y: hole['ocenter']['x'],
          }

          if (hole.others) {
            const direction = hole.others.direction
            hole.others.direction = d[direction]
          }
          // let newHole = hole
          // 由于历史原因，关于开启xy互换后，板件数据的处理不一致，导致了，板子先旋转了180°，然后在通过交换小板的宽高等操作，来完成反面。因此上面旋转了180°。此处需要跟上面旋转方向相反的方向旋转90°
          // if (startPosition === '左下角' || startPosition === '右上角') {
          //   newHole = adjustHoleDirection(hole, -90)
          // } else {
          // }
          // newHole = adjustHoleDirection(hole, -90)
          // Object.assign(holes[hi], newHole)
        }

        for (var hi in sholes) {
          var shole = sholes[hi]
          shole['side'] = d[shole['side']]
          shole['ocenter'] = {
            x: shole['ocenter']['y'],
            y: shole['ocenter']['x'],
          }
        }
        for (var si in slots) {
          var slot = slots[si]
          slot['side'] *= -1
          slot['pt1'] = {
            x: slot['pt1']['y'],
            y: slot['pt1']['x'],
          }
          slot['pt2'] = {
            x: slot['pt2']['y'],
            y: slot['pt2']['x'],
          }
          slot['opt1'] = {
            x: slot['opt1']['y'],
            y: slot['opt1']['x'],
          }
          slot['opt2'] = {
            x: slot['opt2']['y'],
            y: slot['opt2']['x'],
          }
        }

        for (var si in sslots) {
          var sslot = sslots[si]
          sslot['side'] = d[sslot['side']]
          sslot['opt1'] = {
            x: sslot['opt1']['y'],
            y: sslot['opt1']['x'],
          }
          sslot['opt2'] = {
            x: sslot['opt2']['y'],
            y: sslot['opt2']['x'],
          }
        }

        const holeAllPenetrate = holes.every(
          (it) => it.deep * 1 + 0.001 >= thick
        )
        const curveHolesAllPenetrate = curveHoles.every(
          (it) => it.deep * 1 + 0.001 >= thick
        )
        const slotAllPenetrate = slots.every(
          (it) => it.deep * 1 + 0.001 >= thick
        )
        if (holeAllPenetrate && slotAllPenetrate && curveHolesAllPenetrate) {
          holes.forEach((hole) => {
            // 有此标志的孔槽不受（只存在通孔通槽默认在大阪正面）规则的影响
            //   if else 是为了解决生产报错 不要优化
            if (!hole?.specialRuleFlag) {
              hole.side = 1
            }
          })
          slots.forEach((slot) => {
            if (!slot?.specialRuleFlag) {
              slot.side = 1
            }
          })
          curveHoles.forEach((cHole) => {
            cHole.side = 1
          })
        }

        if (startPosition === '右上角') {
          switch (part['cutOrigin']) {
            case 'leftTop':
              part['cutOrigin'] = 'rightBottom'
              break
            case 'rightTop':
              part['cutOrigin'] = 'leftBottom'
              break
            case 'leftBottom':
              part['cutOrigin'] = 'rightTop'
              break
            case 'rightBottom':
              part['cutOrigin'] = 'leftTop'
              break
          }
        }
      }
      part['depth'] = 10
      part['edgeInfo'] = edgeInfo
      part['lightEdgeInfo'] = lightEdgeInfo
      part['model_front_edge'] = model_front_edge
      part['doorOpenSide'] = doorOpenSide
      part['sslotInfo'] = sslotInfo
      part['sholeInfo'] = sholeInfo
      if (!part.hasOwnProperty('simplePlankNum') || !part['simplePlankNum']) {
        part['simplePlankNum'] = ''
      }
    }
    if (xyReverse) {
      tmp = stock['plankWidth']
      stock['plankWidth'] = Number(stock['plankHeight'])
      stock['plankHeight'] = Number(tmp)

      var surplusInfo = stock['surplusInfo']
      if (surplusInfo) {
        tmp = surplusInfo['width']
        surplusInfo['width'] = Number(surplusInfo['height'])
        surplusInfo['height'] = Number(tmp)
      }
    }
  }
  // TODO 靠边板槽 代码暂时留下
  // if (true) {
  //   for (let i in tmpData) {
  //     let parts = tmpData[i].parts
  //     for (let j in parts) {
  //       let part = parts[j]
  //       // 异形板件不进行处理
  //       if (
  //         store.state.ncSetting['sideBoardPlacing'] == 'outside' &&
  //         !part.path
  //       ) {
  //         let needRota = changeSlotsPositionByNCsetting(
  //           part,
  //           tmpData[i].plankWidth,
  //           tmpData[i].plankHeight
  //         )
  //         if (needRota) {
  //           rotatePlankWhenSlotAtTheEdge(
  //             part,
  //             tmpData[i].plankWidth,
  //             tmpData[i].plankHeight
  //           )
  //           rotatePartInternal(part)
  //           rotatePartInternal(part)
  //         }
  //       } else if (
  //         store.state.ncSetting['sideBoardPlacing'] == 'inside' &&
  //         !part.path
  //       ) {
  //         // 异形板件不进行处理
  //         rotatePlankWhenSlotAtTheEdge(
  //           part,
  //           tmpData[i].plankWidth,
  //           tmpData[i].plankHeight
  //         )
  //       }
  //     }
  //   }
  // }
  return tmpData
}

//云端生成NC文件 班鲁NC
function generateNCOnline(layoutResult, isSinglePlank, cb) {
  banluFiles = [] //初始化，存放不会在界面上显示的文件数组
  var tempOrderCollect = [] //搜集板件所属的订单序列

  var loResult = viewDataToNCData(layoutResult)
  changeDataStartPoint(loResult)

  //gg.saveByteArrayToFile(JSON.stringify(loResult),gg.getTempRoot()+ "saveLayoutList.txt");

  var params = {
    uid: userService.uid,
    data: JSON.stringify(loResult),
    id: curPLineIndex === -1 ? -1 : exNcConfig[curPLineIndex].serverId,
    keepKnifeOrigin: true,
  }

  //获取余料数组
  var surplusInfoArr = {}
  for (var i in loResult) {
    surplusInfoArr[loResult[i]['stockNum']] = loResult[i]['surplusInfo']
  }

  var cutKnife = NCFile.getCutKnife()
  if (cutKnife === 'NIL') {
    main_msgTips.open(gg.getTrans('未找到开料刀')) //未找到开料刀
  } else if (!cutKnife.diameter) {
    main_msgTips.open(gg.getTrans('开料刀未设置刀径'))
  }

  loadIndicator.start()
  globalJSTool.generateNC(
    params,
    function (data) {
      loadIndicator.hide()
      var path = getSaveDir(false)
      //进入页面，清空文件夹
      if (!isSinglePlank) {
        gg.rmdir(path)
        gg.mkdir(path)
        ncs = {}
      }
      var tempStr = '' //用于储存每个NC文件的内容
      var length = data.length

      //对加载组件中的数组做唯一性处理
      var progressModel = loadingBoard.progressModels //加载进度条数组
      var progressName = 2 //加载进度条id
      for (var i in progressModel) {
        if (progressModel[i]['id'] === progressName) {
          progressModel.splice(i, 1) //移除id为2的数组
          break
        }
      }
      loadingBoard.progressModels = progressModel
      loadingBoard.open(length + 1, '正在加载中!....', progressName)
      //对data进行排序，反面板件排在前面
      for (var i = 1; i < data.length; i++) {
        var tempArr1 = data[i]
        var tempArr2 = data[i - 1]
        if (
          tempArr1['stock_num'] === tempArr2['stock_num'] &&
          tempArr1['direction'] === 'opposite'
        ) {
          data[i] = tempArr2
          data[i - 1] = tempArr1
          i++
        }
      }

      var ncInfos = {}
      banluFilesNC = {}

      for (var i in data) {
        //当前NC有错误信息时，需要报错
        if (data[i]['error_info']) {
          main_msgTips.open(gg.getTrans(data[i]['error_info']))
          if (data[i]['filetype'] === 'ptp') {
            continue
          }
        }

        var fileName = data[i]['filename']
        var fileDislpayName = data[i]['file_display_name']
        var direction = data[i]['direction']
        var specifications = data[i]['specifications']
        var stockNum = data[i]['stock_num']
        var ossUrl = 'https://eggrj.oss-cn-hangzhou.aliyuncs.com/' + fileName
        //导出时修改文件名称
        var specArr = data[i]['stock_key'].toString().split(':')
        var texture = specArr[0]
        var matCode = specArr[1]
        var thick = specArr[2]
        var spec = texture + ' ' + matCode + ' ' + thick + '板'

        //是否使用余料
        var surplusInfo = surplusInfoArr[stockNum]
        var superInfoName = ''
        if (surplusInfo && Object.keys(surplusInfo).length > 0) {
          if (
            surplusInfo.hasOwnProperty('shape') &&
            surplusInfo['shape'] == 'lshape'
          ) {
            //  L形余料数据
            surplusInfo['width'] = surplusInfo['x5']
            surplusInfo['height'] = surplusInfo['y3']
            surplusInfo['min_width'] = surplusInfo['x3']
            surplusInfo['min_height'] = surplusInfo['y5']
            superInfoName =
              '_' +
              gg.getTrans('L型余料') +
              '\n' +
              surplusInfo['width'] +
              '(' +
              surplusInfo['min_width'] +
              ')' +
              surplusInfo['height'] +
              '(' +
              surplusInfo['min_height'] +
              ')'
          } else {
            //矩形余料数据
            surplusInfo['width'] = surplusInfo['width']
            surplusInfo['height'] = surplusInfo['height']
            superInfoName =
              '_' +
              gg.getTrans('矩形余料') +
              ' ' +
              surplusInfo['width'] +
              ' ' +
              surplusInfo['height'] +
              ' ' +
              0 +
              ' ' +
              0
          }
        }

        var localName =
          stockNum +
          '_' +
          (direction === 'front' ? 'Z' : 'F') +
          '@' +
          spec +
          superInfoName
        var name = localName
        var localNameKey = localName
        var pathArr = path.split('/')
        var projectName = pathArr[pathArr.length - 3]

        if (fileDislpayName) {
          fileName = fileDislpayName
        }
        //记录不用显示在nc界面的文件，导出时直接复制文件到导出目录
        if (data[i]['filetype'] !== 'nc') {
          var index = fileName.lastIndexOf('/')
          localName = fileName.substring(index + 1, fileName.length) //获取服务器上的文件名称
          index = localName.lastIndexOf('.')
          name = localName.substring(0, index) //去除文件名后缀
          path = isSinglePlank ? gg.getTempRoot() + 'NCDownload/NC/' : path
          if (genSimpleLabelBarcode && data[i]['filetype'] === 'ptp') {
            var pGgData = deepCopy(ggData)
            var simpleItm = pGgData.filter(function (curItm, index) {
              if (curItm.hasOwnProperty('plankNum')) {
                var realLocalName = localName
                //反面板件文件名后是否+K
                var sideFlag = ''
                if (localName.indexOf('K') > -1) {
                  realLocalName = localName.split('K')[0]
                  sideFlag = 'K'
                }
                if (
                  curItm['plankNum'] === realLocalName &&
                  curItm['simplePlankNum']
                ) {
                  localName = curItm['simplePlankNum'] + sideFlag
                  return curItm
                }
              }
            })
          }

          var savePath = path + (isSinglePlank ? '班鲁NC/' : '') + localName
          fileSvc.downloadFile(ossUrl, savePath, 'none')
          banluFiles.push({ path: savePath, fileType: data[i]['filetype'] })
          continue
        }

        if (exportUsingBanlu) {
          var index = fileName.lastIndexOf('/')
          localName = fileName.substring(index + 1, fileName.length) //获取服务器上的文件名称
          index = localName.lastIndexOf('.')
          name = localName.substring(0, index) //去除文件名后缀
          path = isSinglePlank ? gg.getTempRoot() + 'singlePlank/NC/' : path
          var savePath = path + (isSinglePlank ? '班鲁NC/' : '') + localName
          fileSvc.downloadFile(ossUrl, savePath, 'none')
          if (!isSinglePlank && data[i]['filetype'] === 'nc') {
            tempStr = gg.loadFileToString(savePath)
            ncs[name] = tempStr
            banluFilesNC[name] = savePath
            localNameKeys[name] = localNameKey
          }
        } else {
          ncInfos[name] = data[i]
        }
      }

      doubleStationsort(ncInfos)
      for (var i in ncInfos) {
        data = ncInfos[i]
        fileName = data['filename']
        fileDislpayName = data['file_display_name']
        ossUrl = 'https://eggrj.oss-cn-hangzhou.aliyuncs.com/' + fileName

        fileName = fileDislpayName
        var suffix = '.' + getSuffix()
        localName = genExportNewFileName(i, projectName, 'nc') + suffix
        localName = isSinglePlank ? genExportFileName(i) + '.nc' : localName

        path = isSinglePlank ? gg.getTempRoot() + 'singlePlank/NC/' : path
        savePath = path + (isSinglePlank ? '班鲁NC/' : '') + localName
        fileSvc.downloadFile(ossUrl, savePath, 'none')
        banluFilesNC[i] = savePath

        if (!isSinglePlank) {
          tempStr = gg.loadFileToString(savePath)
          ncs[i] = tempStr
        }

        for (var j in loResult) {
          if (loResult[j]['stockKey'] === data['stock_key']) {
            if (data['direction'] === 'front') {
              loResult[j]['ncFront'] = ossUrl
            } else {
              loResult[j]['ncBack'] = ossUrl
            }
          }
        }
        loadingBoard.updateProgress(progressName)
      }

      loadingBoard.forceClose()
      if (cb && typeof cb === 'function') {
        cb()
      }
    },
    function (e) {
      loadIndicator.hide()
    }
  )
}

//用于服务器上输出NC的数据转换
export function changeDataStartPoint(data) {
  function rotatePt(pt, opt) {
    var x = pt['x'] - opt.x
    var y = pt['y'] - opt.y

    pt['x'] = y + opt.y
    pt['y'] = -x + opt.x
  }
  function rotatePt180(pt, opt) {
    pt['x'] = opt.x * 2 - pt['x']
    pt['y'] = opt.y * 2 - pt['y']
  }
  function rotateSSlot(sslot, oo) {
    var side = sslot['side']
    var opt1 = sslot['opt1']
    var opt2 = sslot['opt2']

    rotatePt180(opt1, oo)
    rotatePt180(opt2, oo)

    side = (parseInt(side) + 2) % 4
    if (side == 0) {
      side = 4
    }

    sslot['side'] = side
  }
  function rotateSHoles(shole, oo) {
    var side = shole['side']
    var ocenter = shole['ocenter']

    rotatePt180(ocenter, oo)

    side = (parseInt(side) + 2) % 4
    if (side == 0) {
      side = 4
    }

    shole['side'] = side
  }

  var ncSetting = store.state.ncSetting

  var startPosition = ncSetting.startPosition
  var xyReverse = ncSetting.xyReverse
  for (var i in data) {
    var tData = data[i]
    var plankWidth = tData.plankWidth
    var plankHeight = tData.plankHeight
    //如果为新增的板件
    if (plankWidth === undefined || plankHeight === undefined) {
      plankWidth = tData.diyWidth
      plankHeight = tData.diyHeight
    }
    //将板件信息的编号重新计数
    var stockNum = i * 1 + 1
    data[i]['stockNum'] = stockNum
    var parts = tData['parts']

    //对开始X,Y,孔位,异形，槽的基准坐标进行修改
    for (var j in parts) {
      var part = parts[j]
      part['stockNum'] = stockNum
      if (startPosition === '左上角') {
      } else if (startPosition === '左下角') {
        part['startY'] = plankHeight - part['rect'].height - part['startY']
      } else if (startPosition === '右上角') {
        part['startX'] = plankWidth - part['rect'].width - part['startX']
      } else if (startPosition === '右下角') {
        part['startY'] = plankHeight - part['rect'].height - part['startY']
        part['startX'] = plankWidth - part['rect'].width - part['startX']
      }

      //修改孔槽位置改变，都是y轴变换  客户端原点为左上，云排版原点为左下
      if (
        xyReverse &&
        (startPosition === '左下角' || startPosition === '右上角')
      ) {
        var oo = {
          x: part.oRect.width / 2,
          y: part.oRect.height / 2,
        }

        for (var k in part['holes']) {
          const hole = part['holes'][k]
          hole['center']['x'] = part['rect'].width - hole['center']['x']
          rotatePt180(part['holes'][k]['ocenter'], oo)
          // 对 direction 做侧槽 side 相同处理
          if (hole.others) {
            let direction = hole.others.direction
            direction = (parseInt(direction) + 2) % 4
            if (!direction) {
              direction = 4
            }
            hole.others.direction = direction
          }
        }
        for (var k in part['curveHoles']) {
          for (var i in part['curveHoles'][k]['path']) {
            part['curveHoles'][k]['path'][i]['x'] =
              part['rect'].width - part['curveHoles'][k]['path'][i]['x']
          }
        }
        for (var k in part['millInfo']) {
          for (var i in part['millInfo'][k]['shape']) {
            part['millInfo'][k]['shape'][i]['x'] =
              part['oRect'].width - part['millInfo'][k]['shape'][i]['x']
          }
        }
        for (var k in part['oriCurveHoles']) {
          for (var i in part['oriCurveHoles'][k]['path']) {
            part['oriCurveHoles'][k]['path'][i]['x'] =
              part['rect'].width - part['oriCurveHoles'][k]['path'][i]['x']
          }
        }
        for (var k in part['slots']) {
          part['slots'][k]['pt1']['x'] =
            part['rect'].width - part['slots'][k]['pt1']['x']
          part['slots'][k]['pt2']['x'] =
            part['rect'].width - part['slots'][k]['pt2']['x']

          rotatePt180(part['slots'][k]['opt1'], oo)
          rotatePt180(part['slots'][k]['opt2'], oo)
        }
        for (var k in part['handleSlopes']) {
          part['handleSlopes'][k]['pt1']['x'] =
            part['rect'].width - part['handleSlopes'][k]['pt1']['x']
          part['handleSlopes'][k]['pt2']['x'] =
            part['rect'].width - part['handleSlopes'][k]['pt2']['x']

          rotatePt180(part['handleSlopes'][k]['opt1'], oo)
          rotatePt180(part['handleSlopes'][k]['opt2'], oo)
        }
        for (var k in part['sslots']) {
          rotateSSlot(part['sslots'][k], oo)
        }
        for (var k in part['sholes']) {
          rotateSHoles(part['sholes'][k], oo)
        }

        for (var k in part['realCurve']) {
          rotatePt180(part['realCurve'][k], oo)
        }
        // realCurve旋转时内部的realCurveEx也该旋转
        part['realCurveEx'] &&
          part['realCurveEx'].forEach((realCurveEx) => {
            for (var k in realCurveEx) {
              rotatePt180(realCurveEx[k], oo)
            }
          })

        for (var k in part['path']) {
          for (var m in part['path'][k]) {
            part['path'][k][m]['x'] =
              part['rect'].width - part['path'][k][m]['x']
          }
        }
        for (var k in part['oPath']) {
          for (var m in part['oPath'][k]) {
            part['oPath'][k][m]['x'] =
              part['oRect'].width - part['oPath'][k][m]['x']
          }
        }
        for (var m in part['cutCurve']) {
          part['cutCurve'][m]['x'] =
            part['rect'].width - part['cutCurve'][m]['x']
          if (part['cutCurve'][m]['b']) {
            part['cutCurve'][m]['b'] = -part['cutCurve'][m]['b']
          }
        }
        for (var k in part['cutCurveEx']) {
          for (var m in part['cutCurveEx'][k]) {
            part['cutCurveEx'][k][m]['x'] =
              part['rect'].width - part['cutCurveEx'][k][m]['x']

            if (part['cutCurveEx'][k][m]['b']) {
              part['cutCurveEx'][k][m]['b'] = -part['cutCurveEx'][k][m]['b']
            }
          }
        }
      } else {
        for (var k in part['curveHoles']) {
          for (var i in part['curveHoles'][k]['path']) {
            part['curveHoles'][k]['path'][i]['y'] =
              part['rect'].height - part['curveHoles'][k]['path'][i]['y']
          }
        }
        for (var k in part['millInfo']) {
          for (var i in part['millInfo'][k]['shape']) {
            part['millInfo'][k]['shape'][i]['y'] =
              part['oRect'].height - part['millInfo'][k]['shape'][i]['y']
          }
        }
        for (var k in part['holes']) {
          const hole = part['holes'][k]
          hole['center']['y'] = part['rect'].height - hole['center']['y']
        }
        for (var k in part['slots']) {
          part['slots'][k]['pt1']['y'] =
            part['rect'].height - part['slots'][k]['pt1']['y']
          part['slots'][k]['pt2']['y'] =
            part['rect'].height - part['slots'][k]['pt2']['y']
          if (sessionStorage.getItem('thinkerx_url')) {
            part['slots'][k]['opt1']['y'] =
              part['realRect'].height - part['slots'][k]['opt1']['y']
            part['slots'][k]['opt2']['y'] =
              part['realRect'].height - part['slots'][k]['opt2']['y']
          }
        }
        for (var k in part['handleSlopes']) {
          part['handleSlopes'][k]['pt1']['y'] =
            part['rect'].height - part['handleSlopes'][k]['pt1']['y']
          part['handleSlopes'][k]['pt2']['y'] =
            part['rect'].height - part['handleSlopes'][k]['pt2']['y']
          if (sessionStorage.getItem('thinkerx_url')) {
            part['handleSlopes'][k]['opt1']['y'] =
              part['realRect'].height - part['handleSlopes'][k]['opt1']['y']
            part['handleSlopes'][k]['opt2']['y'] =
              part['realRect'].height - part['handleSlopes'][k]['opt2']['y']
          }
        }
        for (var k in part['path']) {
          for (var m in part['path'][k]) {
            part['path'][k][m]['y'] =
              part['rect'].height - part['path'][k][m]['y']
          }
        }
        for (var m in part['cutCurve']) {
          part['cutCurve'][m]['y'] =
            part['rect'].height - part['cutCurve'][m]['y']
          if (part['cutCurve'][m]['b']) {
            part['cutCurve'][m]['b'] = -part['cutCurve'][m]['b']
          }
        }
        for (var k in part['cutCurveEx']) {
          for (var m in part['cutCurveEx'][k]) {
            part['cutCurveEx'][k][m]['y'] =
              part['rect'].height - part['cutCurveEx'][k][m]['y']

            if (part['cutCurveEx'][k][m]['b']) {
              part['cutCurveEx'][k][m]['b'] = -part['cutCurveEx'][k][m]['b']
            }
          }
        }
      }

      var slots = part['slots']
      pretreatmentSlots(slots) //预处理槽，把宽>长的槽数据变成宽<长
      //获取封边
      var edgeInfo = part['edgeInfo']
      var leftEdge = Number(
        edgeInfo.substring(edgeInfo.indexOf('←') + 1, edgeInfo.indexOf('↓'))
      )
      var bottomEdge = Number(
        edgeInfo.substring(edgeInfo.indexOf('↓') + 1, edgeInfo.indexOf('→'))
      )
      var rightEdge = Number(
        edgeInfo.substring(edgeInfo.indexOf('→') + 1, edgeInfo.indexOf('↑'))
      )
      var topEdge = Number(edgeInfo.substring(edgeInfo.indexOf('↑') + 1))

      for (var index in slots) {
        var slot = slots[index]
        if (slot['symbol'] === 'STRENTCHTWO' || slot['symbol'] === 'MORTISE') {
          //一般的槽长会算上封边,此处拉槽刀(直径为slot["width"])与板件切割边相切,要减去封边值(榫槽同理)
          let extendDis = slot['width'] / 2

          if (slot['pt1']['x'] === slot['pt2']['x']) {
            if (slot['pt1']['y'] < slot['pt2']['y']) {
              slot['pt1']['y'] = slot['pt1']['y'] - (extendDis - topEdge)
              slot['opt1']['y'] = slot['opt1']['y'] - (extendDis - topEdge)
              slot['pt2']['y'] = slot['pt2']['y'] + (extendDis - bottomEdge)
              slot['opt2']['y'] = slot['opt2']['y'] + (extendDis - bottomEdge)
            } else {
              slot['pt2']['y'] = slot['pt2']['y'] - (extendDis - topEdge)
              slot['opt2']['y'] = slot['opt2']['y'] - (extendDis - topEdge)
              slot['pt1']['y'] = slot['pt1']['y'] + (extendDis - bottomEdge)
              slot['opt1']['y'] = slot['opt1']['y'] + (extendDis - bottomEdge)
            }
          } else if (slot['pt1']['y'] === slot['pt2']['y']) {
            if (slot['pt1']['x'] < slot['pt2']['x']) {
              slot['pt1']['x'] = slot['pt1']['x'] - (extendDis - leftEdge)
              slot['opt1']['x'] = slot['opt1']['x'] - (extendDis - leftEdge)
              slot['pt2']['x'] = slot['pt2']['x'] + (extendDis - rightEdge)
              slot['opt2']['x'] = slot['opt2']['x'] + (extendDis - rightEdge)
            } else {
              slot['pt2']['x'] = slot['pt2']['x'] - (extendDis - leftEdge)
              slot['opt2']['x'] = slot['opt2']['x'] - (extendDis - leftEdge)
              slot['pt1']['x'] = slot['pt1']['x'] + (extendDis - rightEdge)
              slot['opt1']['x'] = slot['opt1']['x'] + (extendDis - rightEdge)
            }
          }
        }
      }

      parts[j] = part
    }
  }
  return data
}

//预处理槽，当槽的宽度大于槽的长度时，交换长宽
function pretreatmentSlots(slots) {
  for (var i in slots) {
    var slot = slots[i]
    var pt1 = slot['pt1']
    var pt2 = slot['pt2']
    var width = slot['width']
    var opt1 = slot['opt1']
    var opt2 = slot['opt2']
    var knifeName = slot['knifeName']
    if (knifeName) {
      continue
    }

    if (Math.abs(pt1.x - pt2.x) < 0.001) {
      var height = Math.abs(pt1.y - pt2.y)

      if (width > height) {
        var centerY = (pt1.y * 1 + pt2.y) / 2
        var ocenterY = (opt1.y * 1 + opt2.y) / 2

        slot['width'] = height
        slot['pt1'].x -= width / 2
        slot['pt2'].x += width / 2
        slot['pt1'].y = centerY
        slot['pt2'].y = centerY

        slot['opt1'].x -= width / 2
        slot['opt2'].x += width / 2
        slot['opt1'].y = ocenterY
        slot['opt2'].y = ocenterY
      }
    } else if (Math.abs(pt1.y - pt2.y) < 0.001) {
      height = Math.abs(pt1.x - pt2.x)

      if (width > height) {
        var centerX = (pt1.x * 1 + pt2.x) / 2
        var ocenterX = (opt1.x * 1 + opt2.x) / 2

        slot['width'] = height
        slot['pt1'].y -= width / 2
        slot['pt2'].y += width / 2
        slot['pt1'].x = centerX
        slot['pt2'].x = centerX

        slot['opt1'].y -= width / 2
        slot['opt2'].y += width / 2
        slot['opt1'].x = ocenterX
        slot['opt2'].x = ocenterX
      }
    }
  }
}

//获取保存文件的路径
function getSaveDir(isBackupNc) {
  var path = ''
  var result = getNameList(projectInfo, roomInfos, layoutType)
  var projectNameList = result['projectNameList']
  var roomNameList = result['roomNameList']
  var day = Qt.formatDateTime(new Date(), 'yyyy-MM-dd')
  var pathSuffix = 'NCDownload' + '/' + day + '/'
  for (var i in projectNameList) {
    projectNameList[i] = projectNameList[i].replace(':', '-')
    projectNameList[i] = projectNameList[i].replace(':', '-')
  }
  var saveDir = 'NC文件'
  if (isBanlu) {
    saveDir = '班鲁NC'
  }

  if (projectNameList.length > 1) {
    pathSuffix += projectNameList.join('_') + '/' + saveDir + '/'
  } else if (roomNameList.length > 0) {
    var rooms = roomNameList
    if (rooms.length > 1) {
      pathSuffix += projectNameList[0] + '/' + saveDir + '/'
    } else {
      pathSuffix += projectNameList[0] + '_' + rooms[0] + '/' + saveDir + '/'
    }
  } else {
    pathSuffix += 'other' + '/' + saveDir + '/'
  }

  pathSuffix = pathSuffix.replace(' ', '-')
  pathSuffix = pathSuffix.replace(new RegExp('\\|', 'g'), '')
  return gg.getTempRoot() + pathSuffix + '/'
}

function simulationNC() {
  var ossUrls = []

  if (isBanlu) {
    for (var i in banluOSSPath) {
      var fileName = banluOSSPath[i]['fileName']
      fileName = fileName.substring(
        fileName.lastIndexOf('/') + 1,
        fileName.length
      )
      ossUrls.push({
        name: fileName,
        ossPath: banluOSSPath[i]['ossPath'],
      })
    }
  } else {
    var path = gg.getTempRoot() + 'layout/NC/'

    gg.rmdir(path)
    gg.mkdir(path)

    if (singleStationCombine) {
      var files = exportNCSingleStation(path)
    } else if (doubleStationCombine) {
      files = exportNCDoubleStation(path)
    } else if (doubleEdgeCombine) {
      files = exportNCDoubleEdge(path)
    } else {
      files = exportNC(path)
    }

    for (var i in files) {
      var file = files[i]
      var url = dbProjectObj.uploadFileToOssDir(file, 'nc')
      var tmpUrl = 'https://eggrj.oss-cn-hangzhou.aliyuncs.com/'
      url = url.substring(tmpUrl.length, url.length)
      ossUrls.push({
        name: file.substring(file.lastIndexOf('/') + 1, file.length),
        ossPath: url,
      })
    }
  }

  var params = {
    uid: userService.uid,
    nc_list: ossUrls,
  }
  globalJSTool.saveTmpNC(
    params,
    function succeed(oData) {
      if (oData['status']) {
        var data = oData['data']
        if (data['nc_key']) {
          Qt.openUrlExternally('http://nc.eggrj.com?key=' + data['nc_key'])
        }
      }
    },
    function fail() {
      main_msgTips.open(gg.getTrans('NC上传失败'))
    }
  )
}

//对于历史排版的订单，勾选加工文件夹按订单号命名，此处获取订单号
function getOrderNoList(list) {
  var projectCodeList = [],
    projectNameList = []
  for (var index = 0; index < list.length; index++) {
    var stock = list[index]
    var parts = stock['parts']
    parts.filter(function (part) {
      if (projectCodeList.indexOf(part['orderNo']) === -1) {
        projectCodeList.push(part['orderNo'])
      }
      if (projectNameList.indexOf(part['address']) === -1) {
        projectNameList.push(part['address'])
      }
    })
  }
  var result = {
    projectCodeList: projectCodeList,
    projectNameList: projectNameList,
  }
  return result
}

//背景：南兴贴标机服务器返回的数据没有色卡，材质信息。此处与开料文件大板序号对应，得到开料大板的颜色，材质信息，
//满足贴标开料文件根据大板颜色，厚度，材质分不同文件夹需求
function getNanXingLabelPath(path, prop) {
  if (!sortNCByMaterail) {
    return path
  }
  var fileName = prop.substring(prop.lastIndexOf('/') + 1, prop.length)
  var index = fileName.lastIndexOf('.')
  var name = fileName.substring(0, index)
  //P_4_18.7_anlHCSNO.anc
  var stockIndex = name.split('_')[1]
  var dirName = ''

  var bncs = libGG.parseJson(JSON.stringify(ncs))
  if (isBanlu && exportUsingBanlu && localNameKeys) {
    var lastUnderLine = name.lastIndexOf('_')
    name = name.substr(lastUnderLine + 1)
    bncs = localNameKeys
  }

  for (index in bncs) {
    if (isBanlu && exportUsingBanlu && localNameKeys) {
      index = bncs[index]
    }
    var stockNum = String(index).split('_')[0]
    if (String(stockIndex) === String(stockNum)) {
      var firsrIdx = String(index).indexOf('@')
      var info = String(index).substr(firsrIdx + 1)
      if (info.indexOf('余料') > -1) {
        index =
          info.indexOf('矩形余料') > -1
            ? info.indexOf('矩形余料')
            : info.indexOf('L型余料') > -1
            ? info.indexOf('L型余料')
            : info.length
        info = info.substring(0, index - 1)
      }
      var strimIndexs = getStrimIndex(info)
      var texName = info.substring(0, strimIndexs[strimIndexs.length - 2])
      var specName = info.substring(
        strimIndexs[strimIndexs.length - 1],
        strimIndexs[strimIndexs.length - 2]
      )
      var thickStr = info.substr(strimIndexs[strimIndexs.length - 1])
      if (thickStr.indexOf('余料') > -1 && thickStr.indexOf('_') > -1) {
        thickStr = thickStr.split('_')[0]
      }

      var thickName = thickStr.replace(/[^0-9\\.\\^0-9]/gi, '')
      if (thickName.indexOf('.') < 0) {
        thickName = parseInt(thickName).toFixed(1)
      }
      dirName = texName + '_' + specName.trim() + '_' + thickName.toString()
      return path + dirName + '/'
    }
  }
  return path
}

//获取字符串中(空格)的下标，方便截取字符串
function getStrimIndex(prop, type) {
  var comparedStr = ' '
  if (type && type === 'underline') {
    comparedStr = '_'
  }
  var strimIndexs = []
  for (var i = 0; i < prop.length; i++) {
    if (prop[i] === comparedStr) {
      strimIndexs.push(i)
    }
  }
  return strimIndexs
}
