export default {
  common: {
    checkPlankIsConflict: 'Панель күйі тексерілуде',
    preferenceSaveErr: 'Қалауларды сақтау қатесі',
    guimen: 'Кабинет есігі',
    preferenceLoadTip: 'Сіздің қалауларыңыз жүктелуде...',
    preferenceLoadErrTip:
      'Қалауларды жүктеу сәтсіз аяқталды. Қате қалауларды болдырмау үшін бетті жаңартып, қайта көріңіз!',
    slotKnifeErr:
      'Ұяшыққа арналған арнайы өңдеу құралы жоқ. Өндіріс желісінің баптауларын тексеріңіз! 【{msg}】',
    downImageErrTip:
      'Фотоны шығару баталды, өйткені улгама тақтадан бөлмелерге бөлінген шыңырақ суреттерді шығару арзандыруы қол жетімді етілді, бірігі міндетті түрде бейне тақталарын таңдау керек',
    downNcErrTip:
      'NC файлдарын жүктеу баталды, өйткені улгама тақта бойынша суреттерді бөлмелерге бөлу қызметі қосылды. Бірігі міндетті түрде бейне тақталарын таңдау керек.',
    localFileIsBeingWrite: 'Жергілікті файл жазылуда',
    taskMonitor: 'Тапсырманы бақылау',
    downloadByType: '{type} жүктеу',
    anewChooseCatalog: 'Каталогты қайта таңдау',
    downloadSetting: 'Жүктеу параметрлері',
    attention: 'Назар аударыңыз',
    errpb: 'Қате орын алды, қайта көріңіз',
    unit: 'Единица',
    defaultUse: 'Әдетті пайдалану',
    defaultChooseUse: 'Әдетті таңдау',
    requestFail: 'Сұрау сәтсіз аяқталды',
    downloadDoneByType: '{type} жүктеу аяқталды',
    sysTip: 'Жүйе ескертуі',
    pleaseChoose: 'Таңдаңыз',
    confirmDelete: 'Жоюды растау',
    notDataTipByType: 'Деректер жоқ, алдымен {msg} жасаңыз',
    paginationTotalTip: '{total} элементтің {current}-{page} жолдары',
    chooseAmountData: '{number} деректер таңдалды',
    moreOperate: 'Қосымша',
    transferByType: '{type} ауыстыру',
    clickCopy: '{type} көшіру үшін басыңыз',
    currentLine: 'Ағымдағы жол',
    remark: 'Ескертпе',
    customFunction: 'Теңшелетін функция',
    limitedTimeTrial: 'Шектеулі уақытқа сынақ',
    startDate: 'Басталу күні',
    endDate: 'Аяқталу күні',
    startTime: 'Басталу уақыты',
    endTime: 'Аяқталу уақыты',
    lastMonth: 'Соңғы ай',
    lastSixMonth: 'Соңғы алты ай',
    lastYear: 'Соңғы жыл',
    searchByType: '{type} енгізіп іздеңіз',
    addTime: 'Уақыт қосу',
    startArrange: 'Орнатуды бастау',
    select: 'таңдау',
    name: 'аты',
    plankNo: 'тақта нөмірі',
    plankName: 'тақта атауы',
    orderNo: 'тапсырыс нөмірі',
    order: 'тапсырыс',
    plankRemark: 'тақта туралы қосымша ақпарат',
    room: 'бөлме',
    orderAddress: 'жобаның мекен-жай',
    addressEditErr: 'Қос тапсырыстарға жобаның мекенжайін өзгертуге болмайды',
    customerEditErr:
      'Қос тапсырыстарға клиенттің деректерін өзгертуге болмайды',
    factoryEditErr: 'Қос тапсырыстарға зауыттың номерін өзгертуге болмайды',
    roomNameEditErr: 'Қос тапсырыстарға бөлменің атауын өзгертуге болмайды',
    remarkEditErr: 'Қос тапсырыстарға тапсырыс ескертуін өзгертуге болмайды',
    matCode: 'материал',
    matCode2: 'матеріал',
    plankMatCode: 'тақта матеріал',
    count: 'сан',
    customer: 'клиент',
    customerInfo: 'клиент туралы ақпарат',
    factoryOrderNo: 'фабрика тапсырыс нөмірі',
    roomName: 'бөлме атауы',
    color: 'түс',
    plankColor: 'тақта түсі',
    plankThick: 'тақта қалыңдығы',
    plankHighGloss: 'Атрибуттарды бөлектеу',
    thick: 'қалыңдығы',
    front: 'алдыңғы',
    back: 'артыңғы',
    slot: 'шұңқыр',
    hole: 'тесік',
    hsInfo: '{hole} тесігі {slot} шұңқыр',
    diameter: 'диаметр',
    finishedWidth: 'тамамшық ені',
    finishedHeight: 'тамамшық биіктігі',
    texture: 'текстура',
    normal: 'тік текстура',
    reverse: 'қайта текстура',
    notCare: 'текстурасыз',
    holeSlotsStastic: 'тесік-шұңқыр саны',
    edge: {
      title: 'кенет',
      info: 'кенет туралы ақпарат',
      front: 'алдыңғы кенет',
      back: 'артыңғы кенет',
      left: 'сол kenet',
      right: 'оң kenet',
      up: 'юғары kenet',
      down: 'түшіңгі kenet',
    },
    isSpecial: 'арнайы',
    notSpecial: 'арнайы емес',
    isDoorPlank: 'есік тақта',
    notDoorPlank: 'есік тақта емес',
    width: 'ен',
    long: 'биіктігі',
    plankWidth: 'тақта ені',
    plankHeight: 'тақта биіктігі',
    shortLong: 'қысқа биіктігі',
    shortWidth: 'қысқа ені',
    plankShape: 'Тақтайдың пішіні',
    detail: 'Толығырақ',
    cancel: 'жокко ету',
    confirm: 'тастықтау',
    alreadyKnow: 'Мен бұл жөнүндӓ білеймін',
    Noprompts: 'Ешқандай көрсеткі бермей',
    deep: 'тереңдік',
    other: 'басқа',
    heighGloss: 'жоғары глянс',
    heighGlossPlank: 'жоғары глянс тақта',
    notHeighGloss: 'жоғары глянс емес',
    notHeighGlossPlank: 'жоғары глянс емес тақта',
    saveSuccess: 'сақталды',
    deleteSuccess: 'жойалды',
    addSuccess: 'қосалды',
    editSuccess: 'өзгертілді',
    engraving: 'оюлу машина',
    electronicSaw: 'электрондық кескіш',
    glassEquip: 'шыны кескіш машина',
    labelingMachine: 'Таптама машина',
    copy: 'Көшіру',
    paste: 'Қою',
    undo: 'Кері ал',
    move: 'Қозғалт',
    PTP: 'PTP',
    dock56FDrill: 'алтышақтық бұрғалау машина',
    sideHoleDrill: 'жақшалық тесік бұрғалау машина',
    combination: 'комбинациялық машина',
    integration: 'бірліктегі машина',
    standardPlank: 'стандартты тақта',
    standardMaterial: 'Стандартты материал',
    specialMaterial: 'Арнайы материал',
    specialPlank: 'арнайы тақта',
    singlePlank: 'біржақты тақта',
    previous: 'алдыңғысі',
    next: 'келесісі',
    rate: 'пайдалану көрсеткіші',
    paibanBatch: 'баспа топтығы',
    return: 'қайта',
    FGNo: 'келісім нөмірі',
    refresh: 'жаңарту',
    inde: 'нөмірі',
    productionLine: 'производственная линия',
    operation: 'операция',
    colorPlaceHolder: 'Ентерді басып, автоматты түрде түстік картасын құру',
    saveAndContinue: 'сақтау және жалғастыру',
    reset: 'қайта орнату',
    check: 'тексеру',
    inputPlaceholder: 'кіріңіз',
    filter: 'сіру',
    all: 'барлығы',
    exportPDF: 'PDF экспорттау',
    exportPic: 'сурет экспорттау',
    add: 'қосу',
    edit: 'өзгерту',
    delete: 'жою',
    save: 'сақтау',
    saveSetting: 'параметрлерді сақтау',
    isHeightGloss: 'Жоғары глянстық тақта екенін таңдаңыз',
    noSync: 'синхронization іштемеу',
    sync: 'синхронизация',
    formatSuccess: '{type} тастықталды',
    dataLoading: 'Деректер жүктеледі......',
    warmTip: 'қызметті кеңес',
    wellcome: 'Қолдануға қош келдіңіз! Кейіптік жобалау',
    timeOut: 'Жобалау уақыты асып кетті, жаңадан жобалау керек пе?',
    warning: 'ескерту',
    export: 'экспорттау',
    mark: 'белгілеу',
    noMark: 'белгілемеу',
    sawArrange: 'кескіш жобалау',
    directArrange: 'тікелей кескіш жобалау',
    area: 'ауқым',
    plankNum: 'тақта нөмірі',
    loc: 'шкаф',
    cutHeight: 'кесу биіктігі',
    cutWidth: 'кесу ені',
    defaultProduction: 'әдетті өндіріс линиясы',
    tip: 'ұсынысы',
    history: 'тарих',
    hinge: 'қаптар сақинасы',
    CCPXHole: 'Үш бирлик эксцентрик от',
    stretchHinge: 'Түзөткүч слоты жана гишт тамақ отверстие',
    hingeScrew: 'Аспалы есік панелі пилоттық тесіктері',
    stretch: 'тіреуіш',
    stretcher: 'тіреуіш сақинасы',
    clothole: 'киімдік сақинасы',
    backPlank: 'арқалық тақта сақинасы',
    ALS: 'қараңғы тұтқасы сақинасы',
    doorSlot: 'есик тақта металл сақинасы',
    drawerSlot: 'тартма металл сақинасы',
    KC: 'штантар сақинасы',
    stealth: 'Көрінбейтін бөлшек',
    lkSlot: 'Құлып ойығы',
    lightSlot: 'Жеңіл науа',
    allHole: 'арнайы тақтадағы барлық тесіктер',
    allSlot: 'арнайы тақтадағы барлық сақиналар',
    millInfo: 'bûріш туралы ақпарат',
    FSDrill: 'алтышақтық/бесшақтық бұрғалау машина',
    engraving2: 'қырқызу машина',
    xyReverse: 'XY осьдері ауысу',
    xyReverseTip:
      'XY ассау айналасу функциясының L-шапағатты қалайтын қалпына келтіруге үйлесімді емес, қақтықты қолданыңыз',
    rect: 'Тік пішін',
    Ltype: 'L пішін',
    archive: 'Архив',
    tellPlaceholder: 'Ұялы телефон нөміріңізді кіріңіз',
    knivesStore: 'Кескіш қорап',
    roll: 'Кескіштің бетін ауыстыру',
    pause: 'Тынықтау',
    editTrans: 'Тәрҗеме редакторы',
    translateManager: 'Аударма басқару',
    gratingSlot: 'Қамау',
    toMaterialList: 'Қайрау тізіміне өту',
    deleteFail: 'Жою сәтсіз аяқталды, кейінірек қайталап көріңіз!',
    batchDelete: 'Топпен жою',
    workShop: 'Цех өндірісі',
    FGAdrress: 'Жоба мекенжайы/Шарт нөмірі',
    cuttingSize: 'Кесу өлшемі',
    finishedSize: 'Дайын өлшемі',
    labelImg: 'Жапсырма сурет',
    layoutImg: 'Тәртіп суреті',
    file: 'Файл',
    folder: 'Қаптама',
    reName: 'Атауын өзгерту',
    to: 'Кімге',
    plankUnit: 'Дана',
    tagTemp: 'Тегтің үлгісі',
    roomRemark: 'Бөлме туралы ескертпелер',
    noModificationPermission: 'Өзгертуге рұқсат жоқ',
    modificationRecord: 'Жазбаны өзгерту',
    page: 'бет',
    strip: 'жолақ',
    jump: 'секіру',
    freeAgreement: 'Бағдарламалық қамтамасыз етуден бас тарту туралы келісім',
    preStep: 'Алдыңғы',
    nextStep: 'Келесі',
    upload: 'жүктеп салу',
    uploading: 'жүктеп салу',
    iAgree: 'Мен келісемін',
    pleaseSelectPlank: 'Кем дегенде бір тақтайшаны таңдауыңызды сұраймын',
    saveToStoreTip:
      'Кем дегенде бір кесілмеген тақтайшаны таңдауыңызды сұраймын',
    fileIsNotExist: 'Файл жоқ',
    selectAll: 'Барлығын таңдау',
    pdfTag: 'PDF Тегі',
    tagImage: 'Тег суреті',
    projectName: 'Бұлт баспашылық',
    guigui: 'guigui',
    gratingSlot: 'Торлы ойық',
    deleteTip:
      'Бұл деректерді жоюдан кейін олар қалпына келтірілмейді, деректерді жоюға жоспарлануда мұрайсыз ба?',
    deleteError: 'Có hay không xác nhận việc xóa dữ liệu',
    cancelDelete: 'Жоюді тоқтатты',
    confirmDeleteTip:
      'Жойғаннан кейін тақта беттің төменгі оң жақ бұрышына «Тақта типтес болуға тиіс – сақталмайды» деген бетке енгізіледі, деректердің жойылғанын растаудың орындылығы бар ма?',
    deleteEmptyTip: 'Бұл үлкен тақтаны жою керек пе',
    notCanDelete: 'Қиып алынған қалған материалдар қоймалауды қолдамайды',
    new: 'қосылды',
    innerBluneFailure: 'қосылды',
    deleteBigPlankTip:
      'Үлкен плитаны материал түсінің ағымдағы қалыңдығының астында ұстау керек және оны жоюға болмайды!',
    continueExport: 'Елемеу және шығыс файлына өту',
    toCheck: 'Тексеруге өту',
    pleaseCheck:
      'Көрсөтүлгөн тизме кескин: Эгемендик борбордук панельде тешик жойлашуы/учак майдалары ажырады/тереңдик аномалиясы бар. Баарын текшериңиз!',
    clampHandCheck:
      'Кейбір панельдеріңіздегі саңылаулар қысу күйінен аулақ бола алмайтыны анықталды. Оны қолмен реттеңіз.',
    conflictPartDetail: 'Thông tin chi tiết về các thành phần xung đột',

    colorNotUndefined: 'Түс бос болуы мүмкін емес',
    materialNotUndefined: 'Материал бос болмауы керек',
    newSetting: 'қосылды',
    yes: 'Яс',
    no: 'Жоқ',
    checkColorPrint:
      'Қазіргі уақытта түс белгісі қосылды, басып шығару үшін түсті принтерді пайдалануыңызды сұраймыз©~',
    colorRules:
      'Түрлі-түсті жапсырмалардың буынын қосқаннан кейін тапсырыстар/бөлмелер/шкафтар/есік панельдері түсі бойынша ажыратылады,kазіргі уақытта деректердің бір түрі үшін қолдау көрсетілетін түс белгілерінің ең көп саны 12, нақты ережелер мынадай:',
    greyPart: 'Жапсырманың оң жағындағы сұр бөлігі',
    region: 'аймақ',
    notSame: '{regionName} аймағының түсі әр түрлі',
    doorTip: 'Сұр — есік панелі, ақ — есіксіз панель',
    exist: 'бар',
    roomName: 'бөлме',
    loc: 'Шкафтар',
    limitNotEffect:
      'Егер 12-ден астам деректер болса, түс белгісі күшіне енбейді',
    hoverTip:
      'Bmp жұрнағы бар суреттер қара-ақ түспен кодталады және түс белгілерімен шығарылуы мүмкін емес',
    lock: 'блоктау',
    unlock: 'блоктаудан ажырату',
    batchLock: 'томарлау блоктау/блоктаудан ажырату',
    surplusLock: 'қалайтын материалдарды блоктау',
    surplusUnlock: 'қалайтын материалдарды блоктаудан ажырату',
    is: 'Иә',
    not: 'Жоқ',
    savingHistory: 'Тарих файлын сақтау',
    saveHistorySuccess: 'Тарих файлын сәтті сақтаңыз',
    saveHistoryFail: 'Тарих файлын сақтау сәтсіз аяқталды',
    bw: 'Қара-ақ жапсырмаларды экспорттаудың орындылығы',
    modular: 'Бөлімше шкафтары',
    autoAdjust: 'Авто реттеу',
    cutOrderTip:
      '【{errorCutOrdersString}】 плитасында қате анықталды! NC файлын жүктеу алдында түзетулер енгізуіңізді сұраймын',
    lockedCutOrderTip:
      '【{errorCutOrdersString}-арттық тақта】блокланды, өздіктіңіз бұлын өзгерту үшін өтіңіз',
    autoAdjustWarning: 'Ағымдағы типтеу нәтижесі автоматты реттеуді қолдамайды',
    checkNetwork: 'Интернет байланысыңызды тексеріңіз',
    moreInfo: 'Толығырақ ақпарат',
    continueLayout: 'Орналасуды жалғастыру',
    adjustPosition: 'Орналасуды реттеу',
    specialAndFiveSix:
      'Бес/алты жақты бұрғылау арқылы тақталарды өңдеу кезінде, дұрыс емес ұя салуды қосу тақтаның кесіліп кетуіне әкелуі мүмкін. Орналасуды жалғастыруды растайсыз ба?',
    plankPathErr:
      'Пластина ішінде дұрыс емес деректер қатесі, құралдың сипаттамаларына сәйкес келетінін тексеріңіз!【{plankIDList}】',
  },
  home: {
    head: {
      production: 'Өнімді таныстыру',
      resolve: 'Шешімдер',
      about: 'Біз туралы',
      equipment: 'Машиналар жабдықтары',
      tell: 'Шикаять телефон',
      enter: 'Бұлт баспашылық кіріңіз',
    },
    swiper: {
      case1: {
        tag: 'Жаңа жаңарту версиясы',
        title: 'Бұлт баспашылық',
        subTitle: 'Машинаның интеллектуалды ми',
        description:
          'Өнеркәсіптік бастырақті онлайн баспашылық оптималдау бағдарламалық жасақтамасы',
      },
      case2: {
        title: 'Шкафтар өндірісі',
        subTitle: 'Технологиялық жаңартулар',
        description:
          'Көптеген этикеткалеу машиналар, кескіш машиналар, جانболы отық машиналары, бесжақты PTP, бес/алтышақты бұрғалау машиналармен қосу',
      },
      case3: {
        title: 'Өнеркәсіптік ми',
        subTitle: 'Шешімдер',
        description:
          'Интеллектуалды баспашылық алгоритмі тақта материалдарды тиімдірек пайдалануға мүмкіндік береді',
      },
    },
    statistics: {
      today: 'Бүгінгі күннің жұмыс плані',
      toMounth: 'Бүгінгі айдің жұмыс плані',
      outturn: 'Өнімділік көрсеткіші',
      cumulative: 'Жалпы пайдаланушылар саны',
    },
    intro: {
      title:
        'Корпоративтік біг деректері Бұлт онлайн баспашылық бағдарламалық жасақтамасы',
      subTitle:
        'Өнеркәсіптік ситуацияны бақылау, клиенттердің қажеттіліктеріне назар аудару, корпоративтік цифрлық трансформация мен автоматтандыруды іске асыру, Интернет-өнімдер өндірісінің индустриялық өзгерістеріне иішінарыс. Қазіргі板式 мебел өнімділіктеріне арналған AI қосымша алгоритмдері ұсынылады, олар корпоративтік өсімге жеткізуге мүмкіндік береді',
      menue: {
        case1: {
          title: 'Онлайн баспашылық',
          subTitle: 'Кез келген уақытта баспашылық жасауға болады',
          description:
            'Бағдарламалық жасақтама жүктемей, кез келген уақытта баспашылық жасауға болады, компьютерге байланыс болмай-ақ кіріңіз',
        },
        case2: {
          title: 'Көптін өнімділік',
          subTitle: 'Өнімділік сызықты тез ауыстыру',
          description:
            'Көптеген өнімділік сызықтарын бір мезгілде тез өңдеуге және ауыстыруге қол жетімді',
        },
        case3: {
          title: 'Ең нәзік өнімділік',
          subTitle: 'Ең назик баспашылық, параметрлерді назик баптау',
          description:
            'Ең назик баспашылық, параметрлерді назик баптау, тақталар өнімділігін назик етуге мүмкіндік береді',
        },
        case4: {
          title: 'Жеке өнімділік',
          subTitle: 'Жеке этикеткалар, бес/алтышақты бурғылау, қалдық пішін',
          description:
            'Жеке этикеткалар, жеке бес/алтышақты бурғылау, жеке қалдық пішін, комбинациялық жабдықтармен өңдеу',
        },
      },
    },

    fangan: {
      title: 'Өнеркәсіптік кәсіпорындарға арналған профессионалды шешімдер',
      subTitle:
        ' Төменгі бағасымен, жоғары өнімділікпен, интеллектуалды алгоритмдер арқылы тақта материалдарды тиімдірек пайдалануға болады. Бұл шешімдер көптеген дизайн бағдарламалармен үйлесімділікке ие және өнімділікті арттыруға мүмкіндік береді ',
      menue: {
        case1: {
          title: 'Онлайн ордерлік өндіріс біріктірілу',
          description:
            ' Қашықтан онлайн операциялар, кеңістіктің шектеулерін асып, кез келген уақытта ордерлерді зауытқа жіберу және дизайн мен өндіріс синхрондықтай бастау',
        },
        case2: {
          title: 'Бір батырмамен өндіріс желісі ауысу',
          description:
            'Өзіндікеше ондіріс желісі, бірнеше өндіріс желісі мен көптілік құрылғыларды біріктіруге қолдайды',
        },
        case3: {
          title:
            'Интеллектуалды алгоритмдер тақта материалдарды тиімдірек пайдалануға мүмкіндік береді',
          description:
            'Традиционалды алгоритмдерде тақта материалдарды пайдаланудың тиімділігі төмен. Интеллектуалды алгоритмдермен параметрлерді нарықтай баптау және өнімділікті арттыруға арналған шешімдер',
        },
        case4: {
          title: 'Профессиональды кесу шесімі',
          description:
            'Кесу реттілігі мен кесу бастамасын оптималдау, кесу жолдарын қысқа және кесудің тұрақтылығын арттыру',
        },
      },
    },
    aboutUs: {
      title1: 'Шинэгэр Үй-Жай Технологиясы',
      subTitle: 'Өрек жасау өнеркәсіптің трансформациялық лидер',
      description:
        'Шинэгер компаниясы традиционалды өрек жасау өнеркәсібін интернетке трансформациялауға маманданған',
      culture: {
        case1: {
          title: 'Корпоративтік мақсаты',
          subTitle: 'Заводтарға үш есе өсім беру',
        },
        case2: {
          title: 'Культуралық құндылықтар',
          subTitle:
            'Клиенттерге бағытталған, еңбекшілерге негізделген, қиын еңбекпен, халққа файда әкелу',
        },
        case3: {
          title: 'Нұсқаулық бағыт',
          subTitle:
            'Халык жолымен, сатуу, өнімділік, ұйымдастықпен біріктірілген элита командасын құру',
        },
      },
      feature: {
        case1: 'Кәсіби топтық техникалық қызметтеу',
        case2: 'Үнемі жаңарту және итерлеу',
        case3: 'Бір-біріне қарастық кезімді қызмет көрсету',
        case4: 'Функцияларды жекешелендіру қызмет',
      },
    },

    cooperation: {
      title: 'Қатты ынтымақтастық, болашақтықтық құру',
      subTitle:
        'Құрылғы брендтерінің 90% ішкі қоммунистік құрал-жабдықтарға қосылады, 107554 сандық саудагерлер бізбен бірге істеп жатыр ',
    },
    footer: {
      contact: {
        title: 'Бізбен байланысыңыз',
        adrres:
          'Адресі: Цзянсу қаласының Биньху ауданыңіздеги Сюйси 50-ші көшесі',
        subAdrres: 'K-park сервис орталығы 1-ші ғимараттың 16-ші қабат',
        tell: 'Телефон',
      },
      business: {
        title: 'Шинэгердің негізгі бизнесі',
        department1: 'Есік-шарпа шбақ бағдарламалық қамтамасыз ету бөлімі',
        department2: 'Өрек бағдарламалық қамтамасыз ету бөлімі',
        department3: 'Құрылыс-монтаж бөлімі',
        department4: 'Шинэгердің штаб-пәтерінің официалды сайты',
      },
      brand: {
        title: 'Шинэгердің саубақ брендтері',
        brand1: 'Цайшэнь цзиньфу',
        brand2: 'Менту ван',
        brand3: 'Динксин инвестишн',
        brand4: 'Саньчжанту технология',
        brand5: 'Шанхай фэньмò технология',
      },
      organization: {
        title: 'Шинэгердің жұмыс істеу орындары',
        organization1: 'Шинэгер-Жаньяй бюросы (Фошань филиалы)',
        organization2: 'Шинэгер-Шығыс бюросы (Линьи филиалы)',
        organization3: 'Шинэгер-Жантық бюросы (Чэнду филиалы)',
        organization4: 'Шинэгер-Гуigui батыс сервис орталығы',
        organization5: 'Шинэгер-Гуigui шығыс сервис орталығы',
        organization6: 'Шинэгер-агенттер топтығы',
        organization7: 'Шинэгер-халықаралық нарық бөлімі',
      },
      follow: {
        title: 'Гуigui-діңізге шықырыңыз',
      },
      friendLink: {
        link1: 'Шинэгер бағдарламалық қамтамасыз ету',
        link2: 'K шоу сауда-сатық бағдарламалық қамтамасыз ету',
        link3: 'Менту ван',
        link4: 'Толық алюминий шкаф бағдарламалық қамтамасыз ету',
        link5: 'ZOL бағдарламалық қамтамасыз ету жүктеу',
        link6: 'Хуа цзюнь бағдарламалық қамтамасыз ету орталығы',
        link7: 'Жүктеу үй',
        link8: 'ZOL бағдарламалық қамтамасыз ету жүктеу',
        link9: 'Жасыл ресурстар тобы',
        link10: 'Кішеньдіңізге қосылу',
        link11: 'Үй жихазына қосылу',
        link12: 'Мэйбiao вэнэй қосылу',
      },
    },
  },
  login: {
    title: 'Кіру',
    tell: 'Ұялы телефон нөмірі',

    password: 'Құпия сөз',
    passwordPlaceholder: 'Құпия сөзді кіріңіз',
    forget: 'Құпия сөзді ұмыттым',
    register: 'Тіркелу',
    backLog: 'Кіруге қайта оралу',
    verifyCode: 'Тексеру код',
    getVerifyCode: 'Тексеру кодты алу',
    confimPassword: 'Құпия сөзді тастықтау',
    confimPasswordPlaceholder: 'Құпия сөзді тастықтайңыз',
    editPassword: 'Құпия сөзді өзгерту',
    register: 'Тіркелу',
    reSend: 'соңынан қайта алу',
    loginLoadText: 'Кіру процесіне кірісіңіз, солтүшіңіз...',
    loadingPageTextL: 'Кіру сәтті, беттің құрылымын жүктеуде...',
    loginSuccess: 'Кіру сәтті',
    tellErr: 'Ұялы телефон нөмірінің пішімі дұрыс емес',
    passwordErr: 'Құпия сөзді кіріңіз',
  },
  main: {
    head: {
      title: 'Bluen',
      subTitle: 'Уақытты, материалдарды және күш-жігерді сақтайды',
      task: 'Місія алу',
      arrange: 'Орнату',
      surplus: 'Қалдық материал',
      setting: 'Құрамды орноту',
      find: 'Табу',
      upgrade: 'жаңарту',
      editNickName: 'Атауды өзгерту',
      editPassword: 'Құпия сөзді өзгерту',
      logout: 'Кіруден шығу',
      notice: 'Жарнама',
      enterOfficial: 'Официалды сайтына кіру',
      equipment: 'Машиналар және құрылғылар',
      help: 'Қолдау',
      enterThinkerOfficial: 'Жинэрдің официалды сайтына кіру',
      history: 'Қалптықтық сақтау',
      plankManager: 'Бастапқы материалдарды басқару',
      tagManager: 'Тексеруді басқару',
      suplumentManager: 'Толтыру материалдарды басқару',
      fileManager: 'Файлдарды басқару',
      productionLine: 'Құрылғылармен қосылу',
      workeShop: 'Құрылғылар онлайн',
      useLesson: 'Қолдану оқулығы',
      videoLesson: 'Видео оқулығы',
      prolineManager: 'Өндіріс желісін басқару',
      materialManager: 'Материалдарды басқару',
    },
    sideBar: {
      title: 'Өндіріс оптималдау',
      materialList: 'Материалдар тізімі',
      paibanResult: 'Орнату жоспары',
      printTag: 'Тексеруді басып шығару',
      history: 'Қалптықтық сақтау',
      limitDate: 'Уақытша сынау',
      surplusManager: 'Қалдық материалдарды басқару',
      moreSettings: 'Қосымша параметрлер',
    },
    allRead: 'Бәрі оқып шықты',
    learnMore: 'Толық мәлімет',
    noticeEmpty: 'Жарнамалар жоқ...',
    noticeErr: 'Жарнама мазмұнын алуда қателік',
    feedBack: {
      tab1: 'Пікірді беріңіз',
      tab2: 'Пікірлер тізімі',
      type: 'Пікір түрі',
      typePlaceholder: 'Қызметті функцияны таңдаңыз',
      description: 'Сипаттама',
      descriptionPlaceholder:
        'хайр! Толық пайдалану сценарийін сипаттау үшін 15 аз емес әріптерді толтыруңыз, біз сізге жақсы көмек көрсетеміз, спасибо!',
      uploadPic:
        'Суреттерді қосу, ең көп 6 суретті, әр суреттің көлемі 10 МБ-ден аспайды)',
      tell: 'Қолдану телефоны',
      submit: 'Беріңіз',
      admin: 'Бұлттарды орастыру әкімшісі:',
      picTip: 'Сурет: (суреттішекті ашу)',
      empty: 'Пікірлер деректері жоқ',
      picErr: 'Әр суреттің көлемі 10 МБ-ден аспайды',
      contentErr: 'Әріптер саны 15 - 300 аралығында болуы тиіс',
      necessary: 'Бұл параметрді толтыру керек',
    },
  },
  taskPage: {
    tab1: 'Өндірістік тапсырыс',
    tab2: 'Серілік өндіріс кезектесі',
    filter: {
      title: 'Сүзгілеу',
      unArrange: 'Орнатусіз',
      arranged: 'Орнату',
      allArranged: 'Барлығы орналастырылды',
      preLayout: 'Алдын ала орналастыру',
    },
    search: {
      placeholder: 'Іздеу мазмұнын енгізу',
      orderName: 'Тапсырыс атауы',
      sender: 'Жіберуші',
    },
    importTask: 'Тапсырыс імпорту',
    refuseTask: 'Тапсырысті қабылдамау',
    refreshList: 'Тізімді жаңарту',
    refuseRecord: 'Қабылдамай тіркеу',
    taskCard: {
      sender: 'Імпортталған материалдар тізімі',
      arranged: 'Орнату',
      matCode: 'Материалды код',
      texture: 'Текстура',
      delivery: 'Келісімдеуіші мерзім',
      color: 'Түс',
      delete: 'Жою',
      addToBatch: 'Серілікке қосу',
      save: 'Архивдеу',
      arrange: 'Тікелей орнату',
      deleteTitle: 'Тапсырысты жою',
      checkDetail: 'Егжейінің ақпараттарын қарау үшін басыңыз',
      refuse: 'Қабылдамай',
      refuseTime: 'Қабылдамай уақыты',
      refuseReason: 'Қабылдамай себебі',
      refuseTask: 'Тапсырысты қабылдау',
      refuseTaskPlaceholder: 'Қабылдау себебін енгізіңіз',
      addErr: 'Қосу сәтсіз, кейінірек қайталап көріңіз!',
    },
    batchNo: 'Серілік нөмірі',
    batchArrange: 'Серілік орнату',
    clearBatch: 'Кезектесті босатау',
    taskCount: 'Қазіргі тапсырыстар саны: ',
    checkAllTask: 'Барлық материалдар тізімін қарау',
    clearSuccess: 'Босату табысты',
    record: {
      batchNo: 'Серілік нөмірі',
      saveDate: 'Архивдеу мерзімі',
      materialList: 'Материалдар тізімі',
      arrangedRecord: 'Орнату жоспар',
      delete: 'Жою',
      searchPlaceholder: 'Серілік нөмірін енгізу',
      tipTitle: 'Ескерту',
      tipText: 'Бұл архивті жою ма?',
      confirm: 'Тастықтау',
    },
    errTip:
      'Құжаттарды өңдеу үшін мүмкіндіктер жоқ, тапсырыс тізіміне өту үшін қосу керек!',
    emptyErr:
      'Тапсырыс төлемінде тақталардың мәліметтері жоқ, жасау бағдарламасына жалдау көрсетілмейді!',
    operationSuccess: 'Орындалу сәтті',
    operationFail: 'Орындалу сәтсіз, кейінірек қайталап көріңіз!',
    saveSuccess: 'Сақтау сәтті',
    plankMaxLimit:
      'Кіші тақталардың саны ең көп бағдарлама шектесінен асып кетті',
    batchWarning: 'Бөлекшелер арқылы өндіру керек',
    batchEmptyErr:
      'Тапсырыс тізімінде тақталар мәліметтері жоқ, бөлек өндіруді жалдау мүмкін емес!',
    noRoomTip: 'Оданыштар жоқ, бөлекше өндіруге мүмкін емес!',
    refuseHistory: 'Қабылдау тарихы',
  },
  materialPage: {
    labelBackProcTip:
      'Бұл апдейлама большай панелнің артындағы белгілік методын оптималдаştırdı. Энди, белгінін ізе бойынша тегіп, дырластап және патықластап көрсеткен боласыз. Білесіз деп тұлаганмын!',
    edgeEmptyTip: 'Қию мәні бос болмауы керек',
    edgeNumberLimit: 'Қию мәні тек сан болуы керек',
    chooseSurplusQuantity:
      'Қол жетімді артық материалдардың {total} түрі бар, {quantity} түрі таңдалды',
    chooseBujianStoreQuantity:
      'Барлығы {total} қосымша бөлік, {quantity} тақтайларды қосуға болады, таңдалғаны {chooseQuantity} тақтай',
    isConfirmPushToAwaitStore:
      'Таңдалған тақтайларды орналастыруды күту қоймасына қосуға сенімдісіз бе?',
    enterAutoCreateMatCode:
      'Материалды автоматты түрде жасау үшін Enter басыңыз',
    chooseAwaitStoreQuantity:
      'Орналастыруды күту қоймасында {total} шағын тақтай бар, орналастыруға {quantity} тақтай қосуға болады, таңдалғаны {chooseQuantity} тақтай',
    defaultFold: 'Панельдік деректер әдепкі бойынша жабылады',
    startArrange: 'Орнатуды бастау',
    importMaterial: 'Материалдар тізімін імпорту',
    saveToHistory: 'Тарихқа сақтау',
    addPlank: 'Панельді қосу',
    deletePlank: 'Панельді жою',
    batchEdit: 'Топтық өңдеу',
    moreSetting: 'Қосымша орноту',
    plankSize: 'Панельдің өлшемдері',
    plankSize2: 'Өлшемдер (Ұзындығы * Ені * Қалыңдығы)',
    searchPlaceholder: 'Панельдің атауы/панель нөміріні іздеу',
    checkArranged: 'Орнатуды қарау',
    checkErr: 'Орнатуды жасалмады, қарауға болмайды!',
    downloadSheet: 'Үлгі парақ жүктеу',
    downloadSheetShowScientific:
      'Үлгі парағын жүктеу (ғылыми нотацияны көрсету)',
    exportHoleSlotPDF: 'Панельдің тесіктері-жұмсақ шабаларын экспорттау',
    style1: 'Стиль 1',
    style2: 'Стиль 2',
    style3: 'Стиль 3',
    importNoCover: 'Материалдар тізіміңіздің імпортуі қаптамау',
    openBujianPlankRecommend: 'Patch Miniplate ұсынысын ашу',
    openAwaitStorePlankRecommend:
      'Түрге жататын кітапхананың ұсыным тақтасын ашу',
    openSurplusPlankRecommend: 'Қалған материал плитасын ашу ұсынылады',
    pushToAwaitStore: 'Түрге жататын кітапханадағы депозит',
    setTableHead: 'Материалдар тізімінің таблица бастығы орнату',
    syncConfirm:
      'Қоршау мен Бұлттарды орастырудағы панельдердің деректері сәйкес келмейді! Қоршау деректеріне синхрондеуді тұра ма?',
    handleSync:
      'Ескерту: Панельдерді басқаруда қолмен синхрондеуді істеуге болады',
    noMoreTip: 'Дараамай ескерту',
    checkMore: 'Қосымша қарау',
    noHistory: 'Тарихқа сақталмаған деректер жоқ...',
    sameOrder:
      'Өнім файлды экспорттаған тапсырыс бар, қайталап өнім шығаруға тықсуыдын ескерту!',
    markOrder: 'Тапсырыс бетінде өнімді белгілеу ма?',
    editErrTip: 'Көптеген тапсырысдерді ақпаратты өңдеуге қол жетімді емес',
    autoSaveSuccess: 'Автоматты түрде тарихқа сақталды!',
    goAdd: 'Қосу барады',
    noProductionLine:
      'Бұл жазбаның өндірістік желісі жойылған, материалдар тізіміні қайтадан орнатуға болады',
    fileErr: 'Файл мазмұнын талдау сәтсіз, файл мазмұнын тексеріңіз!',
    noCheckData: 'Деректер табылмады!',
    noArrangeTip:
      'Орнатуды тікелей қарауға болмайды, орнатуды бастауға таңдау!',
    tooOld: 'Алтыжақты бұрғылау орнотуы ескі',
    contactUs: 'Техникалық қызмет көрсетушімен байланысып, қайтадан орнату',
    pathErr:
      'Панель ішінде абайлы деректер бар (панель нөмірі: {errorPathPlankID}), тексеріңіз!',
    sizeErr:
      'Тақтада әдеттен тыс деректер бар, тексеріңіз! Кішкентай тақта өлшемі：{width}-{height}',
    noKnife:
      'Кескіш діаметрі орнатылмаған, орама машинасына кескіш орнатуға барады!',
    sendFactorySuccess: 'Үйіштерге тапсырыс беруді сәтті',
    sendFactoryErr:
      'Үйіштерге тапсырыс беруді сәтсіз, техникалық қызмет көрсетушімен байланысып көмек сұраңыз',
    createTaskCard: 'Қайталап тапсырыс картасын yaratу ма?',
    surplusStatistics: 'Қолданылатын қалдық материалдар',
    surplusStatistics2: 'таңдалды',
    suplementStatistic:
      'қосымшалар, жалған барлығымен қосымша тақталарымен бірге',
    expand: 'Кеңейту',
    packUp: 'Қайтарату',
    importErr: 'Дұрыс файл таңдаңыз!',
    sendLoadingText: 'NC файлін өңдеу және жіберуде...',
    arrangeLoading: 'Барлығы',
    arrangeLoading2: 'Шағын панельдердің орнатуын оңтайландыруда...',
    surplusHead: {
      surplusName: 'Қалдық материалдың атауы',
      shape: 'Көрінісі',
      type: 'Түрі',
      remark: 'Ескерту',
      area: 'Өлшем (м²)',
    },
    surplusTip: 'Қолданылатын қалдық материалдар жоқ, алдын ала',
    creatSurplus: 'Қалдық материал жаңадан құру',
    orderInfo: 'Тапсырыс ақпараты',
    suplement: {
      statistic:
        'Жалпы {total} پارça толықтырушы материал, {useable} پارча толықтырушы материалды бірге жіберуге болады, {selected} парча таңдалды',
      exportSuplement: 'Толықтырушы материалдар тізімін экспорттау',
      suplementLoading: 'Толуктырушы материалдар деректері жүктеледі',
      exportSuplementIng: 'Қосымша тізімді экспорттау',
      noMatchSup: 'Пайдаланылуға қолдау келтіретін қосымша деректер табылмады',
      exportTip: 'Экспорттау алдында кем дегенде бір қосымша таңдаңыз',
    },
    empty: 'Панельдердің деректері жоқ',
    paibanSetting: {
      title: 'Орнатуды орноту',
      selectProduction: 'Өндірістік желі таңдау',
      arrangeWay: 'Орнату схемасы таңдау',
      suitText: 'Қолданылады',
      suitEquipmentNormal: 'Қалыпты орама машина',
      suitEquipmentSpecial: 'Шыны кескіш машина, пісік кескіш машина',
      lessRoll: 'Аз айналдыру',
      computedSpecial: 'Кешелікті есептеу',
      specialTip: 'Төмен жылдамдық',
      irregularNesting: 'Анықтылықсыз Күміс',
      primaryNesting: 'Бастапқы Күміс',
      specialShapedPanel: 'Арнайы Шәке',
      limitedTimeTrial: 'Мұндай Уақытты Сынау',
      seniorShape: 'Басшылық Шәке',
      heteromorphicInlay: 'Өзгеріс Шәке',
      autoCutSurplus: 'Қалдық материал автоматты кесу',
      cutWay: 'Кесу әдісі',
      maxRect: 'Ең үлкен тікбұрышты кесу',
      fixedRect: 'Фиксирленген өлшемге кесу',
      minRectSetting: 'Ең кіші өлшемге кесу',
      firstCutL: 'L-пішінді қалдықты бірінші орындау',
      firstCutLTip:
        'Қосылған кезде шарттарға сай тікбұрышты парақтарды L-пішінді қалдыққа кесуді бірінші орынға қояды',
      shortLine: 'Қысқа жақ',
      longLine: 'Ұзын жақ',
      surplusTemplate: 'Қалдық материалдардың жиі қолданылатын үлгілер',
      add: 'Қосу',
      templateErr: 'Үлгілердің кемісіңізді таңдаңыз',
      cutLong: 'Кесу ұзындығы',
      cutWidth: 'Кесу ені',
      baseInfo: 'Негізгі ақпарат',
      thick: 'Панельдің қалыңдығы',
      continue: 'Тастықтап, жалғастыру',
      standardSelect: 'Стандартты үлкен плитканы таңдау',
      customPlankOrder: 'Таңдауыңызша үлкен тақта бөлімдеу реті',
      customPlankOrderDetail: 'Жоқ болып тұрған кіші тақта тізімі',
      customPlankOrderTip:
        'Ішке қосылған ауыл кеңістігі бұл функция үшін іске асырылмайды',
      surplusPlank: 'Қалып құрылған кіші тақта тізімі',
      overSizePlank: 'Үлкен өлшемді тақта нәрсесі тізімі',
      plankOrder: 'Бөлімдеу реті',
      plankType: 'Тақта түрі',
      plankSize: 'Тақта өлшемдері',
      plankEdgeOff: 'Қысқарту мәні',
      amount: 'Құрту саны',
      amountMax: 'Құрту саны максималды',
      numAndOverSizeTip:
        'Кіші тақталар өлшемінен асып, үлкен тақталардың қолданған мөлшері жеткіліксіз, бөлімдеу мүмкін емес',
      numErrorTip:
        'Үлкен тақталардың қолданған мөлшері жеткіліксіз, бөлімдеу мүмкін емес',
      overSizeTip: 'Кіші тақталар өлшемінен асып, бөлімдеу мүмкін емес',

      restPartList: 'Таңдалмаған кіші тақталар тізімі',
      layoutOrder: 'Орналастыру тәртібі',
      arrangeAmount: 'Кесу саны',
    },
    filter: {
      title: 'Панельдерді сүзгілеу',
      search: 'Іздеу',
      all: 'Бәріні таңдау',
      resetFilter: 'Сүзгілеуді жою',
    },
    edit: {
      title: 'Көпшілікті өзгерту',
      target: 'Өзгерту объекті',
      onlineSelectRow: 'Тек таңдалған жолдар',
      allRow: 'Барлық жолдар',
      editKey: 'Өзгерту керектілігі',
      editValue: 'Өзгерту дегенде',
      warningTip: 'Ескерту: Өзгертуден кейін тесіктір мен кескіштер ауыстырады',
      allEdge: 'Барлық жағалар',
    },
    add: {
      title: 'Панельді қосу',
      continue: 'Тастықтап, жалғастыру',
      matCodeErr: 'Материалды код бос болмайды',
      thickErr: 'Қалыңдық бос болмайды',
      colorErr: 'Түс бос болмайды',
      longErr: 'Жабдықтардың ұзындығы бос болмайды',
      widthErr: 'Жабдықтардың ені бос болмайды',
      countErr: 'Саны кем дегенде 1 болуы керек',
    },
    delete: {
      title: 'Ұсау ескерту',
      tip: 'Бұл деректерді жоюдан кейін қалпына келтіруге болмайды, деректерді жоюға тастықтанасыз ба?',
      errTip: 'Бұл операцияны жасау үшін кем дегенде бір панельді таңдаңыз!',
      confirm: 'Деректерді жою',
    },
    save: {
      errTip: 'Сақтайтын деректер жоқ!',
      title: 'Схема сақтау',
      subTitle: '(Ескерту: Жабық сақтайтын деректер бір жылға сақтайды)',
      name: 'Схема атауы',
      historyName: 'Жабық схема',
      successTip: 'Сақтау сәтті!',
      fieldTip: 'Сақтау сәтсіз!',
    },
    exportErr: 'Панельдер жоқ, экспорттау мүмкін емес',
    exportPicErr:
      'Панельдердің тесіктір шемасын экспорттау кезінде белгісіз хата كелді, бізбен хабарласыңыз',
    plankNotFound:
      'Панельдерді таба алмады! Панельдерді сызуды тастықтайсыз ба?',
    noPlankName: 'Панельдің атауы табылмады',
    plankDataErr:
      'Панельдердің деректері қателік бар, мұқият тексеріңіз, өндіріске залал келмесін дегенді қамтамасыз етуіңіз',
    exportThreeViewErr:
      'Тесіктір шемасының үш көрінісіні экспорттау сәтсіз, техникалық қызметке хабарласыңыз',
    heighGlossWarning:
      'Ескерту: Материалдың мазасы жоғары бағыты болған кезде, материалды өзгертудің кейін плақтарды мазадан көтеру мүмкін емес!',
    reset: 'Кез-келгенді қалпына келтіру',
    settingHead: {
      title: 'Кескіштердің тізімдіңіздің басты қисмы',
      head: 'Басты қисм',
      example: 'Үлгі',
      tip: 'Қолдану сүзі: Басты қисмнің деректер таңбашасын сол жаққа немесе оң жаққа сүйретуге болады',
    },
    copyLine: 'Төмендегіге тегін қоймасын шертуңыз',
    copySuccess: '{name} деректері копирілді',
    arranging: 'Жалпы {count} кіші кескіштің оптималдау қалыптасуда......',
    overSize: {
      title: 'Кескіштің өлшемі асыпты',
      subTitle: 'Өлшемі асыпты кескіштердің тізімі',
      overSizeTip: 'Кескіштің өлшемі асыпты туралы ескерту',
      nonWorkInfo: 'Өңдеуге болмайды кескіштердің деректері',
      conflictWorkInfo: 'Қарама-қайшы тақтайшалардың бөлшектері',
      warning:
        'Кіші кескіштердің өлшемі тым ұзын, қалыптастыруға болмайды! Кіші кескіштердің өлшеміні өзгертіңіз немесе тиісті өлшемдігіттіңіз қосыңыз',
      checkInfo: 'Кескіштердің деректеріні қараңыз',
      tipContent:
        'Система кіші кескіштердің қалыптасуында өлшемі асыпты кескіштерді табады. Кескіштердің кеңді мен бейіктігіңізді өзгертеңіз, содан кейін табліцаның астындағы “Бірден қосу” батырмасын басып, қалыптасуды тездіріңіз немесе “Оригиналды кескіштер - Әрікеше кескіштер” дегенге кіріңіз және кескіштерді қосыңыз',
      add: 'Бірден қосу',
      addPlank: 'Оригиналды кескішті қосу',
      error: 'Кескіштің өлшемі асыпты, қалыптасу сәтсіз',
      successTip: 'Кескіштіңіз қосты! Қалыптасуда...',
      warningTip: 'Кескіштің кендігіңізді толтыруңыз!',
    },
    arrangeErr:
      'Кескіштердің ішкі деректері асыпты, қалыптасу және өндірісуге болмайды',
    nonDelete: 'Жою мүмкін емес',
    addOverSizeTip: 'Үлкен өлшем бар, оны қайта қосыңыз.',
    addPlankSuccess: 'Арнайы тақта сәтті қосылды',
    addPlankFail: 'Арнайы үлкен тақта қосылмады',
    openDir: 'Есіктің ашылу бағыты',
  },
  arrangedPage: {
    slotErrTip:
      'Ағымдағы панельде жиекті өңдеу үшін ойықтар бар, қайта орналастыру қолдау көрсетілмейді!',
    batchBujianErrTip:
      'Ағымдағы бөлшек бастапқы материалдар тізімі деректерінде жоқ, оны қайта жоспарлау үшін қосымша/жоспарлауды күтіп тұрған кітапханадан таңдауға болады',
    useFolderIsNot:
      'Файлды жүктеу сақталатын орын "{type}" жоқ екені анықталды! Файлдың жойылғанын тексеріңіз. Егер USB дискісін пайдалансаңыз, оның дұрыс қосылғанын тексеріңіз',
    useFolderIsNot1:
      'Файл жүктеу орнын орнатпағаныңыз анықталды, алдымен каталогты таңдаңыз',
    downloadNC: 'NC жүктеу',
    downloadNC1: 'NC файлын жүктеу',
    printTag: 'Етикеттерді басып шығару',
    reArrange: 'Қалыптасуды қайтадан орноту',
    saveToHistory: 'Тарихқа сақтау',
    moreSetting: 'Қосымша',
    followExportTag: 'NC жүктеу кезінде етикеттер файлды экспорттау',
    tagTemplateSelect: 'Етикеттер шаблонды таңдау',
    showCuttingSequence: 'Кесу sırасын көрсету',
    showPlankSize: 'Кіші кескіштің өлшемін көрсету',
    sendToFactory: {
      title: 'Өңдеу цехіне жіберу',
      fileType: 'Етикеттер файл түрі',
    },
    checkData: 'Деректердің толықтығын тексеру...',
    dataLoading: 'Жүктеу...',
    addToBujianStore: 'Қосымша қойым қойылымына қосу...',
    updateTaskStatus: 'Тапсырыс мәртебесін жаңарту...',
    sending: 'Цехке жіберу...',
    waitLoadding: 'Күтеу',
    loadding: 'Жүзеге асыру...',
    finish: 'Ақырындау',
    loadSetting: 'Қалайтын жүктеу',
    loadPreference: 'Арақашты жүктеу',
    dealTemplate: 'Шаблонды өңдеу',
    initTagData: 'Етикеттер деректерді ініциализациялау',
    prepareDrawData: 'Етикеттерді сызу деректерін дайындау',
    drawDataText: 'Белгілерді суреттеу деректері',
    genPdfTagFile: 'PDF етикеттер файлді генерау',
    genPicTagFile: 'Жапсырма кескін файлын жасау',
    uploadFile: 'Файлды жүктеу',
    dealNCFile: 'NC файлды өңдеу',
    zipFile: 'Жергілікті жип файлді генерау',
    plankCount: 'Өріктегіш кескіштердің саны',
    rollCount: 'Қайтадан орноту сан',
    totalRate: 'Жалпы оңтайландыру шығырлылыгі',
    rate: 'Оңтайландыру шығырлылыгі',
    useArea: 'Оңтайландырылған аудан',
    materialList: 'Материалдар тізімі',
    saveSurplus: 'Қалдықтарды қоймаға сақтау',
    workeShop: 'Сатып алу ордері',
    holeSlotErr:
      'Кескіштердің ішіндегі отындар мен саңылаулардің тереңдігінің асыптысы байқалды!',
    knivesErr: `Пластина үшін өңдеу құралын табу мүмкін емес екені анықталды!`,
    checkDetail: 'Егжектілікті қарау',
    deepErr: {
      title: 'Кескіштің отындар мен саңылауларінің тереңдігінің асыптысы',
      errStatistic: 'Кіші кескіштердің {count} жерінде асыптысы бар',
      errText: 'Асыптық бөлік',
      markErrPart: 'Асыптық бөлікті қоршау',
    },
    warningTip:
      'Екі жақты өңделген глаукоз қаптамасы: алдымен глаукоз беті өңдеңіз, содан кейін матты бет өңдеңіз; Бір жақты өңделген глаукоз қаптамасы: тікелей матты бет өңдеңіз',
    showDetail: {
      tip1: 'Бір кескішті таңдау үшін бір рет шертіңіз',
      tip2: 'Кескіштің этикеттерін қараңыз',
      edgeInfo: 'Кескіштің kenaпталу жұмысы туралы ақпарат',
    },
    arrangeTip: '(Қалыптасуды атайын пішімделгенді қолдайды)',
    plankOpration: 'Кескіштің операциясы',
    rotate: '90 градусқа айналдыру',

    deletePart: 'Кескішті өшіру',
    notCare: 'Кескіштің текстури жоқ',
    editRemark: 'Ескертуді өңдеу',
    clearRemark: 'Ескертуді тазалау',
    recalcIndex: 'Кескіштің кесу орнын және кесу реттілігін қайта есептеу',
    recalcIndexSuccess:
      'Түсіру нүктесін қайта есептеу және кесу тізбегін сәтті жүргізу',
    storageArea: {
      title: 'Кескіштердің айналма сақтау орны',
      empty: 'Кескіштердің айналма сақтау орны бос',
      storageTip: 'Кескіштерді осы орынға сүйретуге болады',
    },
    subtleArrange: 'Теккіл қалыптасу',
    addPlank: 'Өріктегіш кескішті қосу',
    deletePlank: 'Өріктегіш кескішті өшіру',
    deletePlankErr:
      'Өріктегіш кескіштің кіші кескіштер бар, өріктегіш кескішті өшіруге болмайды!',
    useSurplus: {
      title: 'Қалдықтарды пайдалану',
      search: 'Іздеу',
      placeholder: 'Кіріңіз',
    },
    dragWarning: 'Кескішті өріктегіш кескішке сүйретуңіз!',
    areaWarning:
      'Кескішті орналастыруға болмайды, бос орынның көлеміне назар аударыңыз',
    subtlePage: {
      cut: 'Қалдықтарды кесу',
      cancelCut: 'Кесуді тоқтату',
      cutPoint: 'Қалдықтарды кесудің сүйреу нүктесі',
      saveSurplus: 'Қалдықтарды қоймаға сақтау',
      save: 'Планды сақтау',
      storageArea: 'Айналма сақтау орны',
      cutErr:
        'Кесу тек өріктегіш кескіштегі болады, кіші кескіштерге жамау болмайды!',
      saveErr: 'Қалдықтарды кесу үшін бір кескіш таңдаңыз',
      cutErr2: 'Үлкен бағанада тексеру жасалады, бағанадан тыс қалмайды!',
      selectTip: 'Таңдаңыз түс қылу қалдық плақтар',
      noCutPath: 'Қалыпты бөлімдер қвадратты қиып алу мүмкін емес',
      cutOperate: 'қалайтын материалдарды қиып шығару әрекеті',
      line: 'қолмен қоршау',
    },
    shortCut: 'Қысқа шықымдар',
    updownRoll: 'Жоғары-төмен слайд',
    leftRightRoll: 'Сол-оң слайд',
    zoomScale: 'Чоңайту-кішірейту',
    dragCanvas: 'Бетті сүйрету',
    mouseRoll: 'Мышыңыздың роликтікі',
    mouseRight: 'Мышыңыздың оң батырмақ',
    doublePlank: 'Өріктегіш кескішті екі рет шақыру',
    keyboard: 'Клавиатура',
    picSymble: 'Сүретті белгі',
    funished: 'Жазық',
    holeSlotConflict1: 'Отындар мен саңылаулардың қақтығысы',
    plankErr: 'Кескіштердің қақтығысы',
    frontSlot: 'Алдыңғы жақта саңылау',
    backSlot: 'Артыңғы жақта саңылау',
    frontHole: 'Алдыңғы жақта отын',
    backHole: 'Артыңғы жақта отын',
    resetScale: 'Өлшемді қайта орноту',
    maxLong: 'Ең ұзын',
    tempErr:
      'Қосу сәтсіз, шаблонның орналасуы дұрыс емес немесе шаблон тым үлкен!',
    sendErr:
      'Цехке жіберу сәтсіз, тегіннің үлгісі мен плақ түзетілгені дұрыс па, содан кейін қайта жіберіңіз',
    someTips:
      'Сіз уақытша әдісеті аспаптыру өнімін пайдаланудаңыз, әрекеттердің аспаптарды таласып кетуден қорғаңыз!',
    cutOrderErr:
      'Кесу тәртібі дұрыс емес, қайдан кесетін нүктелер мен кесу тәртібін қайта есептеу үшін басыңыз!',
    holeSlotConflict:
      'Тешик жойлашуы кескин: Борбордук панельде тешик жойлашуы же учак майдалары ажырады',
    exportSetting: 'Експорттау параметрлері',
    specialPaibanErr:
      'Плақтардан кейін көрсетілген белгілі мамандар бар, әрекетті қайта аспаптай алмас!',
    moreResult: 'Көбірек аспаптыру нәтижесі',
    moreResultTip:
      'Улуу панельде қырғау үшін шаруашылық бар, сондықтан улуу панель қайта орналастыруда қатысмайды',
    batchBujian: 'Жиынтықты толтыру',
    disPlaySetting: 'Көрсету түрін тандау',
    stored: 'Жиі қойылды',
    onlySurplus: 'Тек қалдық',
    UnuseSurplus: 'Пайдалану коэффициенті қалған материалды есептемейді',
    usePlankAdsorption: 'Тақтайдың адсорбциялық функциясы',
    dataDefaultCollapsed: 'Дереккізді түсіндірілген деректер жабылған',
    unit: 'Единица',
    cutSurplus: 'Қалдықты кесу',
    frontWarningTip:
      'Екі жаға өңдеу: Әріптің мәтінін аяқтап, сәтті өңдеу<span class="font-style">матты</span>, кейін<span class="font-style"> мәтін</span>ты өңдеу<span>; &nbsp; Бір жаға өңдеу: дәл<span class="font-style"> мәтінді</span> өңдеу',
    selectTip: 'Төмендегіде күнделікті жапқа қосу қажет плақтарды таңдаңыз',
    plankKeyWordPlaceholder: 'Плақтың атауын немесе нөмірін енгізіңіз',
    surplusStoreSuccess: 'Жапкан сәтті қойылды',
    editCutOrder: 'Кесу тәртібін өңдеу',
    setCutPoint: 'Қойым кесу нүктесін көрсету',
    specialPaibanChangeErr:
      'Плақтардан кейін көрсетілген белгілі мамандар бар, әрекетті аспаптап алмас!',
    storedSuccess:
      'Жапкан сәтті толтырылды! Таңдалған плақтар қосымша құрылымдықтарға қойылды!',
    noSurplus: 'Қол жетімді қалдық табылмады',
    selectErrTip: 'Қалдық плақтарды қосу мүмкін емес, тегін плақтарды таңдаңыз',
    noRoomIDTip:
      'Осы плақтарда өнім ID жоқ, себебі қаптамаға қосу мүмкін емес, төмендегі плақтарды таңдаңыз',
    noPlank: 'Плақтар табылмады',
    noPaibanData: 'Аспаптама деректері жоқ, аспаптау мүмкін емес',
    historyNameErr: 'Құжат атын енгізіңіз',
    tempErrTip:
      'Сіз бес / алты жаға жүйесін қолданбай алдыңғыдай өтінішті плақтар үшін шаблондарды қоспай қалдықтың әдепкі қосымша жұмыс жасауды қаламаймыз, ескертіп: қосу аяқталған кейін өтінішті аспаптап көріңіз',
    changeDataTip:
      'Ескертіп көріңіз: деректеріңізде өзгерістер табылды, жаңа тарихтар файлын құру үшін үшін сақтаңыз',
    upgrade: 'Жаңарту',
    upgradeTip: ', төмендегі түймеге басып жаңартуға болады',
    plankOverEdge:
      'NC файл жүктеу сәтсіз! Плақтардың бірінше көліңіз жақты олтандықта немесе плақтардың шеттерін тексеріңіз',
    useSurplusTip: 'Қалдықты кестеу керек пе',
    noDataExport: 'Експортталатын аспаптық деректер жоқ',
    noPlankExport: 'Експортталатын плақтардың деректері жоқ',
    lockTip1: 'Шығу үшін басыңыз, шығудан кейін бағанышты редакциялауға болады',
    lockTip2: 'Бағанышты плақтардың орны мен атрибуттарын редакциялау',
    unlockTip1: 'Басқанда блоктау, блоктағаннан кейін әлі өтілмейді',
    unlockTip2: 'қысқартының орны мен қасиеттерін түзету',
    plankLockErr: 'Бұл үлкен бағана құлыпталған, оны орналастыру мүмкін емес',
    lockPartEditTip: 'Плақтар құлыпталған, оларды редакциялау мүмкін емес',
    glassEquipmentTip:
      'Өнім қуғыштық машинасы болған жағдайда плақтар жылжытпай алмайды',
    dragErrTip:
      'Орналастыру мүмкін емес, плақтардың материалын, түсін және міндеттілігін тексеріңіз!',
    subtleSaveTip:
      'Ағымдағы аспаптарды өңдеу жасалды, бірақ сақталған жоқ, шығу керек пе?',
    rollTip: 'Үлкен бағандарда отвор/шығыс бар, торға бұрылуға ұсынылмайды!',
    glossRollErr: 'Сәнгі аспаптар торға бұрылмайды',
    lockDeleteErr: 'Плақтар құлыпталған, оларды жою мүмкін емес',
    deleteConfirmTip: 'Жою операциясы қайтарымсыз емес',
    deleteContinueTip: 'Сіз жалғасаңыз ба?',
    deleteConfirm: 'Жоюды растау қажет',
    selectPlankTip: 'Плақтарды таңдаңыз және осы әрекетті орындаңыз',
    storeFailTip:
      'Қалдықтарды кестеу сәтсіз! Плақтардың бірінше көліңіз жақты олтандықта немесе плақтардың шеттерін тексеріңіз',
    repeatStoreErr: 'Ағымдағы кесілген қалдық плақтар алдында кестелген',
    surplusStoreFail:
      'Кестеу сәтсіз! Плақтардың түрі және L-формасын тексеріңіз!',
    plankConfilictErr:
      'Осы ауданға бағанышты орнатуға қолдау көрсетілмейді, ол тағы да плақтармен қайшылық пайдалайды',
    surplusErrTip:
      'Мұнда нүктелер орнатуға қолдау көрсетілмейді, жарамсыз қалдық жасайды',
    changeArrageSuccess: 'Аспапты өзгерту сәтті',
    trialProduction: {
      trialProductionSample: 'Өндіріс алдындағы сынамалар шкафы',
      trialProduced: 'Сынақ өндірісін жүргіздім',
      trialWarning:
        'Сіз өзіңізге, басқаларға және үлгі шкафтың өндірісін тексермеуіңізден туындаған бұлтты теру бағдарламалық жасақтамасына барлық шығындар мен қолайсыз салдарлар үшін ғана жауап бересіз',
      pleaseUploadTrial:
        'Құрастырылған прототип шкафының суретін кері жүктеуіңізді сұраймын',
      uploadTrial: 'Үлгі шкафын кері жүктеу',
    },
    awaitPlankStore: {
      store: 'Тақтайшалар тақтай қоймасында орналасуға тиіс',
      notTip: 'Сақтауға қойылмағанын енді талап етпейді',
      justLookCurrent: 'Тек ағымдағы тапсырыс тақтасын қарап шығыңыз',
      notInPushStorage: 'Қордаланбаған',
      beInStorage: 'Қоймалау',
      searchPlaceholder:
        'Түрге жататын кітапхана тақтасын іздеу үшін тапсырыс пішінін енгізіңіз',
      plankUploading: 'Тақтайша сақталуда',
      oneClickStorage:
        'Реттелуі тиіс тақта кітапханасына сақтау орнын бір нұқу',
      notUsePlank: 'Кішкентай тақтайшалар жоқ',
      notPlank: 'Пластинка қойылымының болмауы',
      dragPlankInHere: 'Тақтайшаларды тақтай қою үшін осында апаруға болады',
      surplusCannotBeStorage: 'Қалдықтар сақтауға қойыла алмайды',
      plankHasBeenDeduction:
        'Шағын тақта шегерілді немесе бастапқы деректер жоқ және қоймада сақтала алмайды',
      plankLoadErr: 'Тақта ақпараты жүктелмеді',
      taskTitle: 'Пластиналарды типтес болу үшін кітапханаға кері жүктеу',
      dataGenErr: 'Деректерді генерациялау қателері',
      pushSuccess: 'Қоймалау сәтті өтті',
      pushErr: 'Белгісіз қате орын алды және қоймалау жаңылысы',
      awaitBoxExistPlank:
        'Егер дестелеу мүкәммалы сақтауға қойылмаған шағын тақтада болса, қоймадан кейін пайдалану ұсынылады',
      checkPlankStatus: 'Басқарманың мәртебесін есептеу...',
    },
    batchSetCut: 'Топтық кесу нүктелерін орнату',
    applyTo: 'Қолдану',
    ncDownloadLifeText: {
      checkData: 'Деректерді тексеру',
      checkDone: 'Деректерді тексеру аяқталды',
      checkDataText: 'Деректерді анықтау',
      loadSetting: 'Параметрлерді жүктеу',
      settingLoadDone: 'Параметрлер жүктелді',
      loadSettingText: 'Деректерді анықтау',
      downloadNc: 'NC жүктеу',
      ncDownloadDone: 'NC жүктеу аяқталды',
      downloadText: 'NC жүктеп алу',
      loadPreferenceSetting: 'Қалаулы параметрлерді жүктеу',
      PreferenceSettingLoadDone: 'Қалаулы параметрлер жүктелді',
      loadPreferenceText: 'Қалауларды жүктеу',
      dealTemp: 'Үлгіні өңдеу',
      TempDealDone: 'Үлгіні өңдеу аяқталды',
      dealTempText: 'Үлгіні өңдеу',
      beBeingGenTagFile: '{fileType} файлын жасау',
      genTagFileDone: '{fileType} файлы жасалды',
      genTagFileText: '{fileType} файлын жасау',
      zip: 'Жергілікті сығылған файлды жасау',
      zipDone: 'Жергілікті сығылған файл жасалды',
      genZipText: 'Сығылған файлды жасау',
      fileTypeText: 'Сурет',
    },
    surplusCommonTemp: 'Қалған жалпы үлгі',
    plankErrTip:
      'Ағымдағы материал жоспары бойынша кіші тақтайшаны орналастыруға жарамды үлкен тақтайша жоқ!',
    overSizeTip: 'Үлкен тақталардың саны жеткіліксіз немесе өлшемі үлкен',
    uselessPart: 'Ағымдағы үлкен тақтада пайдалануға жарамды шағын тақта жоқ',
    splitArranged:
      'Кішкене тақталар тым көп, оларды топтап орналастыру ұсынылады',
    arrangedErrTip:
      'Үлкен тақталардың жеткіліксіздігі немесе шағын тақталардың үлкендігі салдарынан орналасу сәтсіз аяқталды',
    knivesModalTitle: 'Тесік ұясы бар және құралды табу мүмкін емес',
    cannotDownloadByKnivesErr:
      'NC жүктеп алу мүмкін болмаса, жүйе келесі тесіктер мен ұяларды анықтайды және құралды таба алмайды.',
    viewPart: 'Мәселелер тақтасын қараңыз',
    deductionModalTitle: 'Тындау объектін таңдаңыз',
    bujianDeduction: 'Тындау жоламаға кіші платка',
    paibanDeduction: 'Тындау уақытша сақтау аймағының кіші платка',
    surplusDeduction: 'Қалысқы материалдарды тындау үлкен платка',
    unlockPaiban:
      'Тypes жүйесіне қосылған қалысқы материалдардың блоктауды алып тастау',
    maintain: 'Тypes жүйесіне қосылған қалысқы материалдардың блоктауды сақтау',
    lockModalText:
      'Қалысқы материалдардың үлкен платкаға қосу түрде басқа тапсырыстармен арналған, тypes жүйесін қайта жасау керек пе',
    lockNewSModalText:
      'Ағымдағы қалысқы материалдардың үлкен платка басқа тапсырыстармен арналған, NC-файлдарды жүктеп алуды жалғастыру керек пе',
    rePaibanWarn:
      'Қалысқы материалдардың үлкен платка басқа тапсырыстармен арналған, қолжетерлік үлкен платка жеткіліксіз, ашу жайлық тізіміне қайта types жасау үшін қарай',
    sawRePaiban:
      'Аларым беріңіз: Сіз құрыш дәңгейіні қолданудасыз, қайшылық плиттардың түрлі құрылымын қосдыңыз, NC файлды жіберу үшін қайта түрлендіру керек',
  },
  historyPage: {
    download: 'Жергілікті жүктеу',
    allType: 'Барлық сақтау',
    handleSave: 'Қолмен сақтау',
    autoSave: 'Автоматты сақтау',
    allArrange: 'Барлық қалыптасу',
    arranged: 'Қалыптастырылған',
    unArrange: 'Қалыптастырылмаған',
    searchPlaceholder: 'Тапсырыс атауын іздеу/баспа топтығы',
    trailProduction: {
      title: 'Сынақ өндіріс үлгі шкаф',
      lastTime: 'Ақырғы сынақ өндіріс күні',
      recordList: 'Сынақ өндіріс жазбалары',
      start: 'Сынақ өндірісін бастау',
      date: 'Уақыт',
      outputFrom: 'Шығу орны',
      empty: 'Деректер жоқ',
      record: 'Жүктелген сынақ өндіріс үлгі шкаф суреттерінің жазбалары',
      tip1: 'Назар аударыңыз: Қазіргі сынақ өндірісі тапсырысы әдепкі өндірістік деректерді пайдаланады',
      tip2: 'Назар аударыңыз: Қазіргі сынақ өндірісі тапсырысы ең соңғы технологиялық параметрлерді пайдаланбайды, сынақ өндіріс бөлмесіне өтіп, қайта сақтауға болады!',
      download: 'Өңдеу файлын жүктеу',
    },
    rename: 'Қайта атау',
    historyName: 'План атауы',
    deleteTip: 'Толық тарихи жазбаларды жоюға жоспарлануда мұрайсыз ба?',
    startDate: 'Басталу күні',
    endDate: 'Аяқталу күні',
    lastWeek: 'Соңғы апта',
    lastMonth: 'Соңғы ай',
    lastSeason: 'Соңғы үш ай',
    toArrangePage: 'Тәртіп бетіне өту',
    notArrangedTip:
      'Бұл жазбада тәртіп жасалмаған, алдымен материалдар тізіміне өтіңіз!',
    toMaterialPage: 'Материалдар тізімі бетіне өту',
    lessSelectTip: 'Бұл әрекетті орындау үшін кем дегенде бір жазбаны таңдаңыз',
    downloadNc: 'Өңдеу файлын жүктеу',
  },
  workShopPage: {
    warningTip:
      'Бұл функциясы уақытша сынақты, тапсырыс өндірісінің рационалдықтыны қамтамасыз етіңіз!',
    fileManager: 'Өндіріс файлдарын басқару',
    date: 'Сақтау уақыты',
    allSelect: 'Барлығын таңдау',
    selected: 'таңдалды',
    suplement: 'Қосымша бөлшек',
    size: 'Өлшем',
    saveToStore: 'Қосымша бөлшектер қоймасына қосу',
    enterArrange: 'Түзетуге кіріп жатыр...',
    arrange: 'Қалыптасу бастау',
    suplement: 'Қосымша бөлшектер қоймасы',
    deleteTip:
      'Бұл деректерді жоюдан кейін олар қалпына келтірілмейді, деректерді жоюға жоспарлануда мұрайсыз ба?',
    arrangeTip: 'Қосымша бөлшектерді талдайды, ұсталыңыз...',
    store: 'Қосымша бөлшектер қоймасы',
    downloadFile: 'Өнім файлын жүктеу',
    notBujianFile: 'Қосымша файл табылмады',
    productLineDelete:
      'Бұл өндірістік желі жойылды, NC файлын жүктеу мүмкін емес!',
  },
  basePlankManager: {
    saveTip: 'Ескерту: Қайта басу арқылы сақтау',
    offsetSize: 'Кескіштің өлшемі',
    overSizeTip:
      'Өлшемі тым үлкен болған кезде, автоматты түрде үлкен өлшемді кескіштің қалыптасуын таңдайды',
    addTip:
      'Қалыптасу кезінде кескіштің өлшемі тым үлкен болса, автоматты түрде арнайы үлкен кескіштің қосу диалогтық пенересісіні ашады',
    onlyLayoutTooBig:
      'Өте үлкен өлшемді шағын тақталар тек үлкен өлшемді үлкен тақталардың жанында орналасады',
    settingRemark:
      'Ескертпе: Мұндағы бастапқы функция оң жақтағы параметрлерге реттеледі.',
    searchPlaceholder: 'Кескістің матеріал/түсті іздеу',
    syncData: 'Шкафтардың деректерін синхрондау',
    deletePlank: 'Арнайы үлкен кескіштің жою',
    addPlank: 'Жаңа арнайы кескіштің құру',
    matCodePlaceholder: 'Кескістің матеріалін таңдаңыз',
    thickPlaceHolder: 'Кескістің қалыңдығын таңдаңыз',
    widthErr: 'Кескістің ені бос болмау',
    heightErr: 'Кескістің ұзындығы бос болмау',
    matCodeErr: 'Кескістің матеріалы бос болмау',
    colorErr: 'Кескістің түсі бос болмау',
    editPlank: 'Кескістің өңдеу',
    deleteTip:
      'Бұл деректерді жоюдан кейін олар қалпына келтірілмейді, деректерді жоюға жоспарлануда мұрайсыз ба?',
    plankKnifeSelect: 'Оригиналды кескістің кесу пышағыны таңдаңыз',
    knifeSelectTip: 'Ескерту: Ішкі қалыптасуда осы функция қолданылмайды',
    knife: 'Кесу пышағы',
    matcodeInput: 'Кескістің матеріалын енгізіңіз',
    thickInput: 'Кескістің қалыңдығын енгізіңіз',
    default: 'Системаның әдеттісі',
    unessential: 'Бұл бағаны: міндетті емес',
    emptyTip:
      'Ескерту: Бұл бағаны толтырмау кезде оригиналды кескістің сәйкестігін бос қалдырылады!',
    defaultUse: 'Әдеттісімен таңдаңыз',
    editMatcode: 'Матеріалын өңдеу',
    syncTip:
      '{fromMc} деректерін аліп, қазіргі болмашы кескістің басқару деректеріне ауыстырады, синхрондауды жоспарлануда мурайсыз ба?',
    sameErr: 'Бұл деректердің оқшаш деректері бар',
    cancelDelete: 'Жоюді тоқтатты',
    syncErr: 'Синхрондау сәтсіз',
    syncSuccess: 'Синхрондау сәтті',
    syncDataTip: '${fromMc} деректерін синхрондау',
    editErrTip: 'Толтырмау деректер бар, тексеріңіз!',
    numberCheck: '{type} тек сан кіргізілуі тиіс!',
    deleteStandardPlate: 'Стандартты тақтаны жояды',
    createStandardPlate: 'Жаңа стандартты тақтайшалар',
    editStandardPlate: 'Стандартты тақтаны өңдеу',
    trimmingDimension: 'Жиегін кесу өлшемі',
  },
  bujianAndStore: {
    bujianStore: 'Қосалқы бөлшектер қоймасы',
    awaitStore: 'Тақтайларды орналастыруды күту қоймасы',
    depositAwaitStore: 'Орналастыруды күту қоймасына сақтау',
    pushToAwaitStore: 'Орналастыруды күту қоймасына салу',
    useTwoTableArrange: 'Екі кестені пайдаланып орналастыру',
    confirmUseTwoTableTip:
      'Ағымдағы таңдалған шағын тақтайлар қосалқы бөлшектер қоймасы мен орналастыруды күту қоймасынан тұрады. Орналастыруды жалғастыруды қалайсыз ба?',
    confirmAddPlankToAwaitStore:
      'Таңдалған тақтайларды орналастыруды күту қоймасына қосуға сенімдісіз бе?',
    pushStoreSuccess: 'Қоймаға сәтті қосылды',
    dataGenErr: 'Деректерді жасау қатесі',
    unknownErr: 'Белгісіз қате пайда болды, қоймаға қосу сәтсіз аяқталды',
  },
  surplusPage: {
    surplusMaterial: 'Қалған материалдар тізімі',
    surplusStore: 'Қалған материалдар қоймасы',
    surplusShape: 'Қалған материалдардың пішіні',
    batchTransfer: 'Жаппай ауыстыру',
    createNewSurplusStore: 'Жаңа қалған материалдар қоймасын құру',
    pushStoreSetting: 'Қалған материалдарды сақтау параметрлері',
    surplusStoreName: 'Қалған материалдар қоймасының атауы',
    surplusStoreName2: 'Қалған материалдар қоймасының атауы',
    addSurplusStore: 'Қалған материалдар қоймасын қосу',
    inventoryCeiling: 'Қойма шегі',
    autoTransferTip:
      'Жойылғаннан кейін, қоймадағы қалған материалдар автоматты түрде қалған материалдар тізіміне ауыстырылады',
    asIndexPushStore: 'Қойманың сақталу жағдайына сәйкес тәртіппен сақтау',
    pushToSurplusMaterial: 'Қалған материалдар тізіміне сақтау',
    surplusStoreTable: {
      number: 'Нөмір',
      name: 'Қалған материалдар қоймасының атауы',
      quantity: 'Сақталған қалған материалдар саны (дана)',
      capacity: 'Қалған материалдар қоймасы шегі (дана)',
      remark: 'Ескертпе',
      operation: 'Операция',
    },
    editSurplusStore: 'Қалған материалдар қоймасын өңдеу',
    addSurplus: 'Қалдықтарды қосу',
    deletSurplus: 'Қалдықтарды жою',
    editSurplus: 'Қалдықты өзгерту',
    surplusNoRoll: 'Қалдықтарды қайтадан кесуді іске қосу',
    type: {
      all: 'Барлық пішіндер',
    },
    allMatcode: 'Барлық матеріалдар',
    allThick: 'Барлық қалыңдықтар',
    surplusName: 'Қалдықтар атауы',
    rectSurplus: 'Тікбұрышты қалдық',
    LTypeSurplus: 'L-тәрізді қалдық',
    noMatchSup: 'Қатысты деректер табылмады',
    jumpTo: 'Өту',
    page: 'Бет',
    newMatcode: 'Жаңа материал жасау',
    newColor: 'Жаңа түс жасау',
    autoCreateColor:
      'Enter батырмасын басқанда автоматты түрде түстік карта жасалады',
    nameLimit: 'Қалдық атауы ең көп дегенде 10 таңбадан тұруы мүмкін',
    nameLimitNameErr: 'Қалдық атауы бос болмауы керек',
    tempLongTip: 'Тақтай ұзындығы тек сандардан тұруы керек',
    minLongLimit: 'Қысқа жақ ұзындығы тек сандардан тұруы керек',
    minLongEmptyTip: 'Қысқа жақ ұзындығы бос болмауы керек',
    minLongLimitMax: 'Қысқа жақ ұзындығы тақтай ұзындығынан аспауы керек',
    widthLimit: 'Тақтай ені тек сандардан тұруы керек',
    minWidthLimit: 'Қысқа жақ ені тек сандардан тұруы керек',
    minWidthEmptyLimit: 'Қысқа жақ ені бос болмауы керек',
    minWidthLimitMax: 'Қысқа жақ ені тақтай енінен аспауы керек',
    thickLimit: 'Тақтай қалыңдығы тек сандардан тұруы керек',
    thickEmptyLimit: 'Тақтай қалыңдығы бос болмауы керек',
    countInputLimit: 'Саны оң бүтін сан болуы керек',
    countEmptyLimit: 'Саны бос болмауы керек',
    remarkMaxlimit: 'Ескертулер ең көп дегенде 50 таңбадан тұруы мүмкін',
    createTime: 'Thời gian thêm',
    branchName: 'Kho dư liệu',
    lockStatus: 'блоктау күйі',
    lockOrder: 'тапсырысты блоктау',
    enableSurplusLock: 'Қалысқан материалдарды бұғаттау функциясын іске қосу',
    isCloseSurplusLock:
      'Функцияны өшірілгеннен кейін барлық қалысқан материалдар бұғатталып тасталады, жалғастырмақсыз ба',
    confirm: 'Жауапты түрде жабу',
    surplusTransfer: 'Қалысқы материалдарды түрлендіру',
    targetSurplusStore: 'Мақсатты қалысқы материалдар қоймасы',
    enableSurLockTip:
      'Активация алдында, әрбір нүктелікте қолданылатын қалдырылған материалдар бұған түрде блокталады, басқа тапсырмалар оларды алмауға тырысады',
  },
  printPage: {
    print: 'Қашықтыру',
    defaultTemplate: 'Әдетті шаблон（60 x 40）',
    editTemplate: 'Шаблонды өңдеу',
    deleteWarning: 'Қайырлы ескерту',
    deleteTip:
      'Шаблонды жоюдан кейін оны қалпына келтіруге болмайды, шаблонды жоюға жоспарлануда мурайсыз ба?',
    tagSetting: 'Тегтерді орнату',
    rememberSetting: 'Орнатудан кейін орнатуларды сақтайды',
    genCutingTag: 'Кесу бөлшектеріне тегтерді құру',
    genSurplusTag: 'Қалдықтарға тегтерді құру',
    genPlankTag: 'Кескіштерге тегтерді құру',
    genPartTag: 'Кіші тақта белгілерін жасау',
    tagPosition: 'Тегтерді орналастыру орны',
    tagPositionTip:
      '(Тегтерді орналастыру машинасына туалды X/Y координаталарын толтыру керек)',
    Xposition: 'X координата',
    Yposition: 'Y координата',
    genPlankSuffix: 'Кескіштерге тегтерді орналастыру үшін файл аты суфіксі',
    arrangeMirror: 'Тегтердің суреттеріні көзге айналдыру',
    drillPlankBase: 'Бес-алты жақталық бұралу үстелдің базасы',
    suitAuto: 'Тегтерді автоматты түрде жоғалту/қосуға бейімделу:',
    colorLabel: 'Түс белгілерін жасау',
    up: 'Жоғару',
    down: 'Төмен',
    default: 'Әдетті',
    printSetting: 'Қашықтыру орнату',
    oneToMore: 'Бір бетті көбірек бетке бölшектеу',
    perPage: 'Әр бет',
    coloumn: 'Столбец',
    tagMargin: 'Тегтер арас',
    coloumnMargin: 'Столбец арасы',
    specialMark: 'Арнайы белгі',
    opacity: 'Ашықтық',
    suite: 'Аутоматты бейімделу',
    size: 'Өлшем',
    symble: 'Диаграмма',
    lightSide: 'Көрінетін кескіш',
    locFrontSide: 'Шкафтың алдыңғы жағы',
    openSide: 'Ешуішін ашу дірексія',
    extraTip:
      ' Ескерту: Ешіңізді бірден басып шығаруға тырысқанда, принтердің параметрлерінде қолданылатын өлшемді және оның ені мен кескішін қалпына келтіруішінмен жаңа парақатты қосыңыз, содан кейін жаңа парақатты басып шығаруға болады (мысалы: алдын ала парақат өлшемі 60×40, жаңа парақат өлшемі 40×60)',
    noMore: 'Бөлімдердің барлығы көрсетілген',
    prepareData: 'Деректер дайындалуда...',
    genTagFile:
      'Тегтер жасалуда, беттерді ауыстырмаңыз, браузерді кішірейтпеңіз, тегтерді жоғары-төмен айналдырмаңыз!',
    waitGen: 'Тегтердің жүктелуін күтіп, содан кейін жүктеуді орындаңыз',
    tempLoading: 'Тег үлгісі жүктелуде, күтіңіз',
    front: 'Алдыңғы',
    open: 'Ашу',
    toEdit: 'Өңдеуге өту',
    simplifyOneCodeTip:
      'Назар аударыңыз: бұл тег үлгісіндегі штрих-код қасиеті "Қарапайым штрих-код" функциясын қолдамайды, штрих-код қасиетін өзгертіңіз (ұсынылған штрих-код қасиеті: CODE128)',
    plankTemp: 'Шағын тақта тегінің үлгісі',
    surplusTemp: 'Қалдық тегінің үлгісі',
    tempNamePlaceHolder: 'Үлгінің атауын енгізіңіз',
    editTip:
      'Өз қажеттіліктеріңізге сәйкес сол жақтағы түймелерді оң жақтағы тег қағазына жылжытып, тег үлгісін өңдеуді аяқтауға болады',
    oneCode: 'Штрих-код',
    image: 'Кескін',
    editPage: {
      title: 'Тегтердің шаблонды өңдеу',
      reset: 'Әдетті параметрлерге қалпына келтіру',
      exit: 'Өңдеуді тоқтату',
      tagInfo: 'Тегтердің ақпараті',
      tagName: 'Тегтің атауы',
      tagSize: 'Тегтің өлшемі',
      tagData: 'Тегтердің деректері',
      dragTip: 'Қолмен бастап, деректердің тегтерін сүйреп тастаңыз',
      dataTip: 'Деректердің ақпараттарын оң жақтағы հատтада таңдаңыз',
      text: 'Мәтін',
      dataSource: 'Деректердің ақпараттар',
      graph: 'График',
      typograpyPic: 'Қалыптасу суреті',
      barCode: 'Штрих-код',
      QRCode: 'Құрлы штрих-код',
      plankMark: 'Кескіштің белгісі',
      holeSlotPic: 'Тешіктің суреті',
      holeSlotPicZoom: 'Тесік ойық суретін үлкейту',
      rotateDeg: 'Бұрылу бұрышы',
      colorDeep: 'Түстің қанықтығы',
      surplusMark: 'Қалдық белгісі',
      surplusSizePic: 'Қалдық өлшем суреті',
      dataSize: 'Деректер өлшемі',
      extraList: 'Қосымша тізім',
      QRCodeFormat: 'QR код форматы',
      formatQR: 'QR форматы',
      formatDM: 'DM форматы',
      locIndex: 'Кабинет индексі',
      plankFrontSign: 'Панель белгісі',
      genNCSuffix: 'NC жалғауын жасау',
      table: 'Кесте',
      inputPlaceholder: 'Мәтінді енгізу үшін екішерті басыңыз',
      attribute: 'Қасиеттер',
      fontFamily: 'Қаріптің түрі',
      fontSize: 'Қариптің кемелді',
      fontWeight: 'Қариптің қалınдығы',
      autoRow: 'Автоматты түрде жаңа жол құру',
      rowMargin: 'Жолдар арас',
      autoFontSize: 'Қорытпаған мәтіндің кемелді автоматты түрде реттелу',
      statisticsDimension: ' Статистикалық өлшемдер',
      noLimit: 'Сінірсиз',
      sameOrderNo: ' Жекелей тапсырыс',
      sameRoom: 'Жекелей төбес',
      sameWardrobe: 'Жекелей кабинет',
      alignWay: 'Қарастыру әдісі',
      JustifyLeft: 'Сол жаққа қарастыру',
      JustifyRight: 'Оң жаққа қарастыру',
      JustifyCenter: 'Ортаға қарастыру',
      otherOpration: 'Басқа операциялар',
      attributeReset: 'Қасиеттерді қалпына келтіру',
      barCodeSize: 'Штрих-кодтың өлшемі',
      installPic: 'Қорынту суреті',
      plankCode: 'Кескіштің штрих-код',
      plankCode2: 'Кескіштің штрих-код (noMark)',
      frontCode: 'Алдыңғы жақ штрих-код',
      backCode: 'Арқа жақ штрих-код',
      orderRemark: 'Тапсырыс туралы ескерту',
      NCFileName: 'Кескіштің файлының аты',
      NCFileNameTip:
        'NC файл атауында қытай символдары болмауы және ұзындығы 10 символдан аспауы тиіс',
      showOpenSide: 'Қақпа ашу бағытын көрсету',
      limitFixed: 'Десятич сандық орнын шектеу',
      showFrontHS: 'Алдыңғы түйіндік-ойықтарды көрсету',
      showBackHS: 'Артқы түйіндік-ойықтарды көрсету',
      showPlankSize: 'Панельдің өлшемін көрсету',
      tempNamePlaceholder: 'Тег атауын енгізіңіз',
      deleteAreaTip: 'Жою кеңесін таңдаңыз',
      noHoleSlot: 'Тешіксіз де штрих-код көрсетілуішін таңдаңыз',
      rotate: 'Штрих-кодты айналу',
      barcodeAtrribute: 'Штрих-кодтың қасиеттері',
      imgSize: 'Суреттің өлшемі',
      noEdgeDisplay: 'Кескістің шеті 0 болса штрих-код көрсетілмейді',
      allEdgeNoDisplay: 'Жиектерді топтау көрсетілмейді',
      imgRotate: 'Суретті айналдыру',
      circleD: 'Дөңгелек жақтау диаметрі',
      roomNo: 'Бөлме нөмірі',
      roomOrder: 'Шкаф нөмірі (бөлме бойынша реттеу)',
      alphabetically: 'Әріп бойынша сұрыптау',
      numberOrder: 'Сан бойынша сұрыптау',
      specialMarkup: 'Ерекше белгілеу белгілері',
    },
    autoSaveToHistory: 'Тарихқа автоматты түрде сақталды!',
    firstPrintTip:
      'Тікелей басып шығаруды бірінші рет пайдаланғанда, басып шығару параметрлерінде ұзындығы мен ені ауыстырылған жаңа қағаз өлшемін қосып, содан кейін жаңадан қосылған қағазды пайдаланыңыз',
    tagDownloading: 'Белгісі жүктелуде...',
  },
  fileManagerPage: {
    notOpeFolderPermission: 'Каталогпен рұқсат етілмеген әрекет',
    notFindFolder: 'Ағымдағы каталог ағымдағы клиентте анықталмады',
    productLineUniversal: 'Барлық өндіріс желілеріне бірдей қолданылады',
    effectCondition:
      'Бұл параметр өзгергеннен кейін қайта орналасу ғана күшіне енеді',
    addPath: 'Жолды қосу',
    downloadPositionChangeByType:
      'Жасалған файлдардың жүктеу орны {type} болып өзгертілді',
    isDeleteFolder: 'Пайдаланылған файл сақтау орнын жоюға сенімдісіз бе?',
    netWorkIsNotSafe:
      'Сіздің желі ортаңыз қауіпсіз емес сияқты, біз бұл мүмкіндікті қосып алмаймыз, сіз бізге кері байланыс жасай аласыз',
    NotSupportBrowse:
      'Сіздің браузеріңіз бұл функцияны қолдамайды. Пайдалану үшін <a href="https://www.microsoft.com/kk-kz/edge/download" target="_blank">Edge</a> браузерінің ең соңғы нұсқасын жүктеп алыңыз.',
    notSupportDisk:
      '[Жұмыс үстелі] және [C дискісі] сияқты жүйелік деңгейдегі каталогтарды таңдай алмайсыз',
    useFolderDownload: 'Өндірістік файлдар қысылмаған пішімде жүктеледі',
    downloadPosition: 'Файл жүктеу орны',
    changeUseFolder: 'Өзгерту',
    folderNameSetting: 'Қапшық атауін орнату',
    mainFolder: 'Бірінші деңгейдегі қапшық',
    exportNameSetting:
      'Экспортталған өңдеу файлдарыні тапсырыс нөміріне сәйкес атау',
    fileMax: 'Файл атауының ең көп',
    char: 'Символ',
    overTip:
      'Қосымша бөліктер көрсетілмейді (таг суреттерінің қысқартмасы мен json файлдары бірінші деңгейдегі қапшық атауына сәйкес атайды)',
    example: 'Үлгі',
    engravingFolder: 'Оюл машинасы қапшық',
    electronicSawFolder: 'Электронды пил машинасы қапшық',
    labellingFolder: 'Етикеткалау машинасы қапшық',
    sideHoleDrillFolder: 'Жақ таңбалау машинасы қапшық',
    fiveDrillFolder: 'Бес таңбалау машинасы қапшық',
    sixDrillFolder: 'Алты таңбалау машинасы қапшық',
    PTPFolder: 'PTP қапшық',
    labellingTagFolder: 'Етикетка суреттері қапшық',
    folderMergeSetting: 'Қапшықтарды біріктіру орнату',
    folderMerge: 'Қапшықтарды біріктіру',
    mergeName: 'Біріктіру атау',
    subMergeFolder:
      'Материалдің түсі, түсі және қалыңдығы бойынша кіші қапшыққа біріктіру:',
    subFolderName: 'Кіші қапшық атауы',
    splitChar: 'Бөлшектің белгісі',
    splitTip: '（/ : ? < > | . ” * және басқа да символдар енгізуге болмайды)',
    folderAutoAdd: 'Қапшық санының автоматты түрде өсу бастапқы мәні',
    resetTip: '(Күн сайын таңертеңге бастапқы мәнге қайта орнату)',
  },
  // 更多设置页面
  moreSettings: {
    generalSetting: 'Жалпы параметрлер',
    outSyncTip:
      'Бұлтты типтеудің бастапқы деректері шкафпен синхрондалмаған кезде қалқымалы терезе жасалады',
    secondarySwitchShow:
      'Ою машинасының параметрлерінде ажыратқышпен басқарылатын қайталама мазмұн ажыратқыш өшірілгеннен кейін әлі де көрсетіледі',
    exPaibanTip:
      'Қыздықты және ерді қосымша түрде орналастырғанда ұйарым попапы пайда болады',
    surpTagThresholdTextHalf1:
      'Кiçik бөлік материалының (кесілген көппе материалынан бөлік) аянты',
    surpTagThresholdTextHalf2:
      'дан кішкентай болганда, бөлік материалының таңбағын жасамай.',
  },
  lineManager: {
    add: 'Өндіріс желісіні қосу',
    engraving: 'Кескіш машина',
    holeSlotEquip: 'Тесік және слотты өңдеу машина',
    workSetting: 'Өңдеу параметрлер',
    noUse: 'Қолданылмайды',
    reName: 'Қайта атау',
    warningTip:
      '* Ескерту: Төмендегі функцияларды орнатқаннан кейін, кескіш клиенттерімен қосылу бетінде сақтамаңыз, параметрлерді қалпына келтіруге болмайды!',
    engravingSetting: 'Кескіш машина параметрлер',
    workWay: 'Өңдеу әдісі',
    cutedSpecial: 'Өңделген тесік және слоттары:',
    noWork:
      'Бұл параметр бес/алты таңбалау машинасының әдетті шаблонына әсер етпейді',
    onlyCut: 'Тек кескіш',
    onlyFrontHoleSlot: 'Тек панельдің алдыңғы жағындағы тесік және слоттары',
    onlyHoleSlot:
      'Тек панельдің алдыңғы және артқы жағындағы тесік және слоттары',
    sideHoleSetting: 'Жақ таңбалау машина параметрлер',
    cutHoleSlot: 'Өңделген тесік және слоттары',
    minSize: 'Қысқа жақ өлшемі',
    maxSize: 'Ұзын жақ өлшемі',
    minSize2: 'mm-дегі барлық тесік, слоттар және бұрмақ бөлшектер',
    singleHole: 'Бір тесік',
    singleSlot: 'Бір слот',
    diameterRange: 'Тесіктің диаметрінің диапазоні',
    hDeepRange: 'Тесіктің тереңдігінің диапазоні',
    widthRange: 'Слоттың кеңдігінің диапазоні',
    sDeepRange: 'Слоттың тереңдігінің диапазоні',
    to: '—',
    specialHS: 'Бұрмақ бөлшектер мен бұрмақ слоттары',
    allowRoll:
      'Кескіш машинаның бұрмақ бөлшектер мен бұрмақ слоттары өңдеуіне рұқсат ету',
    engravingPath: 'Кескіш машинасында панельдің бұрмақ бөлшектері өңделу',
    drillSetting: 'Бес/алты таңбалау машина параметрлер',
    engravingCuted:
      'Кескіш машинасында өңделген тесік және слоттары бес/алты таңбалау машинасында',
    noGen: 'Қалыптысу жоқ',
    genCut: 'Қалыптысу және өңдеу',
    genNoCut: 'Қалыптысу, бірақ өңдеу жоқ',
    cutTip:
      ' Ескерту: Бұл параметр тек өзіңіздің бес/алты таңбалау машина файлына әсер етеді',
    maxShortSize: 'Ең үлкен қысқа яқтың өлшемі',
    maxLongSize: 'Ең ұзын жақ өлшемі',
    normalSlot: 'Қалыпты слот',
    specialSlot: 'Бұрмақ слот',
    combinSetting: 'Комбинациялық жабдықтар параметрлер',
    allowShortSize: 'Қысқа яқтың өлшеміне рұқсат ету',
    equipment1: 'Бірінші жабдық',
    equipment2: 'Екінші жабдық',
    noSameTip: ' Ескерту: Екі жабдықты да бірдей таңдауға болмайды',
    workRange: 'Бірінші жабдықтың өңдеу диапазоні',
    rangeWarning:
      ' Ескерту: Тек бірінші жабдықтың оңдеу диапазонды таңдауға болады. Бірінші жабдықтың оңдеу диапазонынан тыс болатын бөлшектер екінші жабдықта оңделуге болады. мұны мұқият істеу!',
    deleteWarning:
      'Бұл өндіріс желісіні жоюдан кейін қалпына келтіруге болмайды (желісінің барлық параметрлері жойылады), онда жоюды тастықтайсыз ба?',
    top: 'Жоғарғы',
    inputCheck: '{msg} ең кішісі зортық мәннен аспайды',
    lockTip: 'Өндіріс желісі бұғатталды, операцияларға тысыңыз!',
    reNameTip: 'Әдетті өндіріс желісінің атауын өзгертеуге болмайды',
    permissonTip:
      'Кімلیک bilgisi үтіп кетті, қайтадан кабинеттен осы бетті ашу!',
    permissonErr: 'Бұл рұқсат сізге берілмеген, аккаунт нұсқасын жаңарту!',
    permissionOneMacErr:
      'Сіз қазір қол жетімді бір бұйрыq құрылғының жинақ саныңыз {idx} болып тұр. Қосу керек болса, satıcıға хабарласыңыз!',
    newLine: 'Жаңа өндіріс желісі {idx}',
    deleteCheck: 'Бәрін де жоюға болмайды',
    deving: 'Қазіргіде әзірлеуде',
    combinTip:
      'Өңдеу құрылғыларының комбинациясын орнату үшін төмендегі орнына басыңыз',
    noUseEquip: 'Өңдеу құрылғысы іске қосылмаған',
    equipmentSelectTip:
      'Комбинациялық жабдықтарда кескіш машина таңдалған болса, кескіш машинасы электронды пісіру машинасы таңдалуыға болмайды',
    lineLoading: 'Деректер жүктеледі',
    strenchType: 'Түзеткіш слот типі',
    aioProcessTip:
      'Сіз "Барлығын бірден" құрылғысын қосқансыз, өңдеу режимі әдепкі бойынша тақтайлардың екі жағын да тесіктермен және ойықтармен өңдеу болып табылады',
    aioProcessTip2:
      'Барлығын қосқаннан кейін арнайы тесіктер мен ойықтар кесу машинасы бөлімінде өңделеді',
    aioProcessTip3:
      'Ескерту: Бұл функция XY осін ауыстыру функциясын қосқан кесу машиналарымен үйлесімді емес. Оны мұқият қолданыңыз',
    specialHoleTip:
      'Ескерту: Жоғарыдағы "арнайы тесіктерді өңдеу" функциясын қолданған кезде "сквозное сверление" және "сквозное фрезерование" параметрлерін бір рет бұрғылап өту үшін орнатыңыз',
    specialHoleTip2:
      'Ескерту: Жоғарыдағы "арнайы тесіктерді өңдеу" функциясын қолданған кезде кесу машинасының "сквозное сверление" және "сквозное фрезерование" параметрлерін бір рет бұрғылап өту үшін орнатыңыз',
    fiveSixNotTake:
      'Бұл параметр бес/алты жағынан бұрғылау үшін әдепкі шаблондарға қолданылмайды',
    onlyFrontSide: 'Тек алдыңғы жағын өңдеу',
    onlyBackSide: 'Тек артқы жағын өңдеу',
    bothFrontBack: 'Алдыңғы және артқы жағын өңдеу',
    shareDataErrTip:
      'Бұл деректер {operatorPhone} арқылы қолданылады, ip: {actionIp}',
    restoreParams: 'Параметрлерді қалпына келтіру',
    loadMore: 'Көбірек жазыңыз',
  },

  cuttingDock: {
    title: 'Кескіш машинаны қосу',
    other: 'Басқа',
    autoStockCode: 'Автоматтық жоғары және төменгі материалдарды қосу код',
    stationStart: 'Станция басталу',
    stationStartCode1: '1-стансия басталу коды',
    stationStartCode1Placeholder: '1-стансия басталу кодын енгізу',
    stationStartCode2: '2-стансия басталу коды',
    stationStartCode2Placeholder: '2-стансия басталу кодын енгізу',
    stationEnd: 'Стансия аяқталу',
    stationEndCode1: '1-стансия аяқталу коды',
    stationEndCode1Placeholder: '1-стансия аяқталу кодын енгізу',
    stationEndCode2: '2-стансия аяқталу коды',
    stationEndCode2Placeholder: '2-стансия аяқталу кодын енгізу',
    frontBackStart: 'Алдыңғы және артқы бет басталу',
    frontBackEnd: 'Алдыңғы және артқы бет аяқталу',
    frontStartCode: 'Алдыңғы бет басталу коды',
    frontStartCodePlaceholder: 'Алдыңғы бет басталу кодын енгізу',
    frontEndCode: 'Алдыңғы бет аяқталу коды',
    frontEndCodePlaceholder: 'Алдыңғы бет аяқталу кодын енгізу',
    backStartCode: 'Артқы бет басталу коды',
    backStartCodePlaceholder: 'Артқы бет басталу кодын енгізу',
    backEndCode: 'Артқы бет аяқталу коды',
    backEndCodePlaceholder: 'Артқы бет аяқталу кодын енгізу',
    plankStart: 'Планканың ішіна бұрылуы басталу',
    plankEnd: 'Планканың ішіна бұрылуы аяқталу',
    plankRollCode1: '1-стансияның ішіне бұрылу коды',
    plankRollCode1Placeholder: '1-стансияның ішіне бұрылу кодын енгізу',
    plankRollCode2: '2-стансияның ішіне бұрылу коды',
    plankRollCode2Placeholder: '2-стансияның ішіне бұрылу кодын енгізу',
    autoBaitingCode: 'Автоматтық кескіш коды',
    autoBaitingCode1: '1-стансияның автоматтық кескіш коды',
    autoBaitingCode1Placeholder: '1-стансияның автоматтық кескіш кодын енгізу',
    autoBaitingCode2: '2-стансияның автоматтық кескіш коды',
    autoBaitingCode2Placeholder: '2-стансияның автоматтық кескіш кодын енгізу',
    stationEnd: 'Стансия аяқталу',
    stationEndCode1: '1-стансия аяқталу коды',
    stationEndCode1Placeholder: '1-стансия аяқталу кодын енгізу',
    stationEndCode2: '2-стансия аяқталу коды',
    stationEndCode2Placeholder: '2-стансия аяқталу кодын енгізу',

    preStartEquipment: 'Алдын ала іске қосу жабдық',
    firstKnifePreCode: 'Бірінші кескіш алдын ала іске қосу коды',
    secondKnifePreCode:
      'Екінші және одан әрбір кескіш алдын ала іске қосу коды',
    rowInsert: 'Сатыр енгізу',
    pumpPause: 'Вакуумдық сорғы тынықтау',
    pumpPauseTip:
      'Ескерту: Бір станциялық жабдық үшін 1 және 2 станциялар бірдей «автоматты жүктеу коды» және «екі жақты өңдеудің алдыңғы түсініктеме коды» арқылы толтырылуы керек.',
    pumpPause2Tip:
      'Ескертпе: Бір станциялық жабдық үшін 1-станция да, 2-станция да бірдей «вакуумдық сорғыны кідірту кодымен» толтырылуы керек.',
    pumpPauseCode1: '1-стансияның вакуумдық сорғы тынықтау коды',
    pumpPauseCode1Placeholder:
      '1-стансияның вакуумдық сорғы тынықтау кодын енгізу',
    pumpPauseCode2: '2-стансияның вакуумдық сорғы тынықтау коды',
    pumpPauseCode2Placeholder:
      '2-стансияның вакуумдық сорғы тынықтау кодын енгізу',

    upSpeedTip: 'Қосалқы жылдамдығын толтыруңыз',
    cutThickTip: 'Қосалқы кесу қалыңдығын толтыруңыз',
    sizeErrTip:
      'Этикеткалау машинасында бастырушы планшеттің дұрыс өлшемін орнатуңыз!',
    offEdgeKnifeDiameter: 'Кескіштің кескіш бıрақ диаметріні толтыруңыз',
    slowDownSpeedTip:
      'Кесу көлемін кеміту жолында тездету параметрлері дұрыс емес',

    permissionErr: 'Қазіргі тапсырмалық нұсқада, сақтауға болмайды',
    labellingTempErr: 'Этикеткалау машинасының шаблонды таңдаңыз',
    autoRollInputTip:
      'Етикетка автоматты түрде айналуды қосу үшін, тиімді мәнді енгізіңіз',
    offlineTempFormat:
      'Қазіргі этикеткалау машинасының файл пішімі қолданылмайды, растаңыз',
    autoStockCode1: '1-орын автоматтық жүктеме коды',
    autoStockCode1PlaceHolder: '1-орын автоматтық жүктеме кодын енгізіңіз',
    autoStockCode2PlaceHolder: '2-орын автоматтық жүктеме кодын енгізіңіз',
    autoStockCode2: '2-орын автоматтық жүктеме коды',
    pauseCode: 'Тынықтау коды',
    pauseCodePlaceHolder: 'Тынықтау кодын енгізіңіз',
    annotationCode1:
      '1-ші орынның екі жақты өңдеуіш алдың жақ түсініктеме коды',
    annotationCode1Tip:
      'Әртүрлі командалармен автоматтық қосуға арналған жабдыққа сәйкес келеді',
    annotationCode1Placeholder:
      '1-ші орынның екі жақты өңдеуіш алдың жақ түсініктеме коды енгізіңіз',
    annotationCode2:
      '2-ші орынның екі жақты өңдеуіш алдың жақ түсініктеме коды',
    annotationCode2Placeholder:
      '2-ші орынның екі жақты өңдеуіш алдың жақ түсініктеме коды енгізіңіз',

    cuttingParams: {
      title: 'Кескіш параметрі',
      holeSlotMainSide: 'Тесік және ойықтықтардің шоғырлануы',
      holeMainSide: 'Тесік шоғырлануы',
      slotMainSide: 'Ойықтық шоғырлануы',
      workPriority:
        '(Қызметтілік басымдығы: кесу тесіктері мен ойықтықтарды картаданың осы бетіндегі тесіктерге сәйкес кесу > Ойықтық шоғырлануы > Тесік шоғырлануы)',
      hingeRollWork:
        'Тіктегіш пен шарнирлік ойықтықтарды ірі картаның артқы бетінде кесу',
      followWork:
        'Кесу тесіктері мен ойықтықтарды картаданың осы бетіндегі тесіктерге сәйкес кесу',
      autoMatchSide:
        'Ортақ-қарамақтық ойықтық пен тесіктерді пропорционалды қарай бөлшектеу',
      backHSRate: 'Артқы бетіндегі ойықтық пен тесіктердің қарай болушыны',
      matchTip:
        '（Жарық панелі немесе атайын пішімді планшеттерді есептеудің орналасу схемасы осы параметрді қолданбайды）',
      specIalHoleAndSlotSurface:
        'Специальные слоты и отверстия для пользовательской определения обрабатываемых поверхностей',
      specialRuleTip: 'Таҳлил этиш учун махсус отверстие ёки паз танланг',
      specialSlotsHoles: 'Қаҳраман тешиктер ва ўралар',
      FixedmachSurface: 'Туруктуу обрабатываемая поверхность',
      deleteRuleTip: 'Бу қоида жойдан алуы барбы',
      blankFront: 'Большой доски фронтальная сторона',
      blankBack: 'Большой доски задняя сторона',
      addRules: 'Үйләм қоида',
      addRulesTip:
        '(Бир идишке бир нече каражат тушса, иштелип чыккан номери эң алдыңкы каражат жооп берет. Бир өртебе сүйрөп, каражаттын тартибин өзгөртсө болот.)',
      highGlossSetting: 'Жарық панелі параметрлері',
      heighGlossFront:
        'Орналасу кезінде кіші панельдің жарық беті ірі панельге орналастырылады',
      sideSlotPut: 'Жақын панельге ойықтықтарды орналастыру',
      optimize: 'Қызметтілік оптималдауды орындауда',
      sysDefault: 'Системаның әдетті параметрлері',
      plankInSide: 'Ірі панельдің ішкі жағы',
      plankOutSide: 'Ірі панельдің сыртқы жағы',
      specialNotWork: 'Бұл функсиял ерекше пішімді планшеттерге қолданылмайды',
      commonSide: 'Ортақ кесу',
      plankThickRange: 'Панельдің қалыңдығы аз болса да',
      useCommonSide: 'ортақ кесуді қолдану',
      zeroTip:
        '（Орналасу арақашықтығы 0 болуына қолдану керек, кесу бұрынғы кесу қабылдаған жағадан ауысуға болады）',
      useTip:
        'Бұл функсияны қосқаннан кейін, бұрынғы кесудің бұрылышпен кесуді қолдануға болмайды, ескеріңіз!',
      commonSpeed: 'Ортақ кесу жылдамдығы',
      highSpeed: 'Жоғары жылдамдықпен кесу жылдамдығы',
      min: 'минут',
      notWorkTip:
        '（Панельдің кесуі ортақ кесуге сәйкес болса, ортақ кесудің жылдамдығы қолданылмайды）',
      changeStartPoint: 'Екі жұмыс орның бастамашы нүктелерін ауыстыру',
      changeStartPointTip:
        'Екі жұмыс орның бастамашы нүктелерін ауыстыруды қосқаннан кейін XY осьсімен ауысу функциясымен үйлесімсіз болады, мұны мұқият қолдану',
      flatOffset: 'Машина плосколығының ауысу',
      xRowChange: 'X осьінің координат бастық нүктелерін ауыстыру',
      bigplankPointChange:
        'Үлкен тақтаның артқы жағының координаталық негіздерін ауыстыру',
      reverseXRowChange: 'X координаттарын ауыстыру',
      changebigplankPointTips:
        'Бұл функцияны іске қосқаннан кейін "XY осін алмастыру" және "екі жұмыс орнының бастапқы нүктесін ауыстыру" функцияларымен үйлесімді емес. Оны абайлап қолданыңыз.',
      reverseYRowChange: 'Y координаттарын ауыстыру',
      plankMirrorTip:
        'Панельді кесу машинасында镜像 кесу кезінде осы функцияны қолдану',
      notSameSize: 'Екі жұмыс орның үстелдің өлшемдері бір-бірінен әртүрлі',
      maxSize1: 'Бірінші жұмыс орның ең үлкен өлшемі',
      notSameSizeTip:
        '(Екі жұмыс орның үстелдің өлшемдері бір-бірінен әртүрлі болса, екі жұмыс орның файлдарын біріктіру функциясымен үйлесімсіз болады, мұны мұқият қолдану)',
      maxSize2: 'Бі�ұрақтың ең үлкен өлшемі',
      autoSelectTip:
        '(Екі жұмыс орнының үстелінің өлшемдері бір-бірінен әртүрлі болғанда, осы функцияны қолданса, відповідің жұмыс орны ірі панельді кесуге автоматты түрде таңдалады)',
      bigFlatLimit:
        'Үлкен жұмыс орны кіші жұмыс орны кеспейтін ірі панельдерді кесуге тән:',
      surplusSetting: 'Қалдық орналасу',
      surplusPosition: 'Қалдық орналасу нүктесі',
      cutHoleWay: 'Тесіктің кесу әдісі',
      knifeFlat: 'Қашықтың сүреу бастық нүқтасы',

      flat: 'Үстел',
      plankFlat: 'Ірі панельдің беті',
      freeHeightZ: 'Бос уақытта Z бейіктігі',
      relativeHeight: 'Ірі панельдің бетіне қарайгі бейіктігі',
      cutDeepZ: 'Кесу тереңдігінің Z бейіктігі',
      cutDeepH: 'Толық тесіктің кесу әдісі',
      onceThrough: 'Бір рет кесу',
      rollThrough: 'Екі рет ауысу кесу',
      cutDeepS: 'Толық ойықтықтың кесу әдісі',
      plankCutDirection: 'Кіші панельдің кесу бағыты',
      sDirection: 'Сәулеткеш бағыт',
      nDirection: 'Сәулеткеш бағыттың керісі',
      curveReplenish:
        'Арқалық толтыру кесу командасы арқалық панельдерді кесуге',
      curveReplenishTip:
        'Арқалық панельдерді кесуде заңбырлар пайда болса, осы функцияны қолдану',
      noGen: 'G17/G18/G19 бетінің командасын жасамау',
      arcInstruction: 'Шеңісті өңдеу командаларының алдындағы белгі',
      arcExample: 'G17G03 X100 Y100',
      example: 'мисал',
      curveCutTip: 'Арқалық кесуде I, J белгілері қолданылады',
      coverRate: 'Ойықтық кесу кезінде кесудің қапталушылығы:',
      roughTip:
        'Қалдық пен ойықтық ішінде кесуде заңбырлар пайда болса, осы функцияны қолдану',
      clearCurve:
        'Ойықтық кесуде толтырушы кесуде тегіс бұрыштар автоматты түрде тазалау:',
      curveUseTip:
        'Ойықтық кесуде тегіс бұрыштар тазалау үшін осы функцияны қолдану, ескерту: осы функция жалғаушыларға қолданылмайды',
      slotCutWay: 'Ойықтық кесудің кесу бағыты',
      outToIn: 'Сырттан ішке ойықтық кесу',
      inToOut: 'Ішке сыртқа ойықтық кесу',
      zShapProcess:
        'Жогору және төменгі Z әріптің түсінік ішіндегі штыртқа айналдыру сұйықтасу жолы',
      zShapProcessTip:
        'Кірісті және көшірілген жолдар үшін әріптің толығындауына бейімделген, ножның жол жолмен қайталану деңгейі ножның радиусына тең болады',
      slotWorkLimit:
        '(Ойықтық кесудің кесу қатламдары > 1 болса ғана қолданылады)',
      workSpeedOp: 'Кесу жылдамдығіні оңтайландыру',
      obliqueCut: 'Кесу кезінде кесу қанатты басталу',
      backOblique: 'Арқалық кесуде кесу қанатты басталу',
      obliqueSpeed: 'Кесу қанатты басталу жылдамдығы',
      arcSpeed: 'Арқалық кесу жылдамдыгі',
      cuttingPreDistance: 'Кесу алдын ала қашықтығы',
      backKnife: 'Кесудің соққысын төмендету',
      openKnifeSpeed: 'Соққысын ашу жылдамдыгі',
      cutCornerSlow: 'Бұрыштар кесуде жылдамдыгі төмендету',
      addRow: 'Қосымша',
      bettwenDistance: 'Бұрыштардың алды-артқыштарі',
      cuttingSpeed: 'Кесу жылдамдыгі',
      stepCutting: 'Қатламдық кесу',
      splitSlot: 'Ойықтық екі рет кесу',
      splitPlank: 'Кіші панель екі рет кесу',
      maxWH: 'Ұзындық немесе ені аз',
      areaLimit: 'Және алаңдық аз',
      secondDeep: 'Екінші рет кесу тереңдігі',
      splitPlankThick: 'Қабатты кесу параметрлерін өлшемдеу',
      saveLayeredCuttingTip:
        'Үш қабатты кесінділердің қосындысы пластинаның қалыңдығына тең болуы тиіс',
      saveLayeredRepeatTip:
        'Бір материалдың бөлектеу атрибутының қалыңдығына әр түрлі қабатты деректерді орнату ұсынылмайды, артық деректерді жоюыңызды өтінемін!',
      splitPlankThick: 'Әр қатламда кесу әртүрлі қалыңдықта',
      movePlankSetting: 'Тайғанауға қарсы тақта параметрлері',
      newCutSequence: 'Жаңа кесу алгоритмі',

      newCutSequenceText:
        'Әдепкі бойынша, үлкен тақтаның ортасындағы вакуумдық сорғыш күші ең күшті, бұл аймақтағы кіші тақталар соңында кесіледі',
      positionTip: 'Таңдау үшін сәйкес ауданды басыңыз',
      suctionPosition: 'Құбылыс пішінің күшті қабылдамасы',
      strongArea: 'Құрғау аймағы',
      minSuctionPlank: 'Эң кіші қабылданатын дошка',
      safeWidth: 'Ені',
      safeHeight: 'Биіктігі',
      safeTip: 'Табан нұсқаны тұтынбаса ал бірінші резке қалдыратын',
      scattered_layout: 'Кішкентай табандарды бөліп орналастыру',
      totalThickLimit:
        'Үш рет қатламдық кесудің толық қалыңдығы панельдің қалыңдығына тең болуы тиіс',
      offsetFollowStep: 'Солтү кесу де қатламдық кесуге сәйкес келеді',

      longPlankTip1:
        '1. Бұл функция іске қосылғаннан кейін "Кесудің ірі панелісі - Вакуумдық сорғыны тоқтату" дегенде вакуумдық сорғыны тоқтату коды орнату керек, сондай болса ірі панельді кесудегі іілуіктілік проблемасын шешуге болады',
      longPlankTip2:
        '2. Бұл функция іске қосылғаннан немесе өшірілгеннен кейін, тарихидегі панельдердің орналасуын сақтау және NC файлды жүктеу кезінде қайтадан орналасу керек',
      secondCutting: 'Екінші рет кесу объекті',
      normalPlank: 'қалыпты панельдің',
      normalPlankTip:
        'қалыпты панель: шкафтің ішкі және сыртқы ірі панельдері және кесу тізімінде тікелей қосылған панельдер',
      plankLongLimit: 'Ірі панельдің uzun егісі',
      plankShortLimit: 'Қысқа егісі',
      cuttingSplit: 'Екі рет кесу',
      longExpand: 'Бірінші рет кесудің кеңейту дережесі: uzun егіс кеңейту',
      firstExpand: 'Алғашқы кесуде кеңейту',
      longExpandTip: 'Ұзын жағын кеңейту',
      longLimit: 'Ұзын жағы асып кетсе',
      cuttingSplitTip: 'Ұзын тақтайды екі рет кесу шарты',
      shortExpand: 'Қысқа егіс кеңейту',
      expandTip:
        '(ұзын/қысқа егістің өлшемдері әрқайсысы x мм-ге қосымша, ірі панельдің ірі және кіші егісінің кеңейту дережесі бірдей)',
      pumpOffWay: 'Вакуумдық сорғыны тоқтату тәсілі',
      afterOnePlank: 'Бір ірі панельді кесуден кейін',
      afterAllPlank: 'Бүкіл ірі панельді кесуден кейін',
      numberFixed: 'Кіші сандардың ұсталу дережесі',
      bufferCode: 'Кесудің соққысы панельдің беткісінің буферлік командасы',
      layoutGap: 'Панельдердің орналасу аралығы',
      layoutGapTip:
        'Назар аударыңыз: Орналасу алшақтығын 0-ге орнату орналасудың қабаттасуына әкелуі мүмкін',
      wasteCutting: 'Қалдықтарды автоматты түрде кесу',
      zkPointCustomize:
        'Z-түймесінің көлбеу тесігін өңдеудің айналу нүктесін теңшеу',
      sawSetting: 'Аралау параметрлері',
      cuttingLimit: 'Кесудің кесу дережесі',
      waste: 'қалдықтар',
      wasteSizeLimit: 'Ең кіші өлшемі 100 мм-ден кем болмайды',
      topLeft: 'Сол жақ, жоғарғы жақ',
      topLeftD: 'Сол жоғарғы көше',
      bottomLeft: 'Сол жақ, төменгі жақ',
      bottomLeftD: 'Сол төменгі köше',
      topRight: 'Үңгі жақ, жоғарғы жақ',
      topRightD: 'Үңгі жоғарғы köше',
      bottomRight: 'Үңгі жақ, төменгі жақ',
      bottomRightD: 'Үңгі төменгі köше',
      firstTime: 'Бірінші рет',
      secondTime: 'Екінші рет',
      thirdTime: 'Үшінші рет',
      startCutting: 'Кесуді бастау',
      thickRange: 'Қалыңдықтың жоғары немесе тең',
      longCut: 'Ұзақ тақта өңдеу',
      edgeSetting: 'Кеңістікті орнату',
      cuttingOrder: 'Кесу реттілігі:',
      cuttingOrderTip:
        'Бұл әдісте тақтадан тыс болу дурыны бар, оқиша пайдалану',
      plankFirst: 'Кіші тақталарды алдымен кесу',
      wasteFirst: 'Қалдықтарды алдымен кесу',
      offTip:
        'Жабу кезінде кесу параметрлеріндегі кесу параметрлеріне сәйкес келеді',
      edgeCutDirection: 'Кесу бағыты',
      onlyLong: 'Қашықты болшаты төнделу',
      backCutOrder: 'Қарсы тараптағы шетті кесу тәртібі',
      backFirst: 'Қарсы тараптағы шетті кесудің алғашқы қатар',
      backSecond:
        'Қарсы тараптағы тесік пен ойықтар өңделгеннен кейін шетті кесу',
      frontReCut: 'Алғашқы тараптағы шетті қайта кесу',
      frontReCutTip:
        '（Өрістету қылім шеттерін жылмақ етіп, із-қалдықтарды болдырмау үшін）',
      useNewCut: 'Жаңа шетті кесу ережелерін іске қосу',
      newCutTip:
        'Екі тараптағы ірі тақтаның алғашқы тараптағы шетті кесудің қосымша өлшеміні пайдалану үшін',
      fixedTip:
        'Назар аударыңыз: Қалдық мәні 0 немесе 1 болған жағдайда орналасу қателері болуы мүмкін',
      cuttingSlow: 'Тақтаның беткі қабатын баяу кесу',
      holeSlow: 'Тақтаның беткі қабатын баяу тесіп өту',
      slotSlow: 'Тақтаның беткі қабатын баяу арна/құбыр/тесікті өңдеу',
      slowCode: 'Баяу командасын қолдану',
      code: 'Команда',
      zkRotatePoint: 'Айналу нүктесі',
      zkRotateDir: 'Айналу бұрышының көбею бағыты',
      restSizeLimit: 'Қалдық материалдың қалған өлшем аз',
      useSaw: 'Ара пышағын қолдану',
      unGenSawPath: 'Ұзындық кесу жолын жасамау',
      suitMachine: 'Толық алюминий дәнекерлеу тақтасын өңдеу үшін жарамды',
      plankBack: 'Үлкен тақтаның артқы жағы',
      plankLeft: 'Үлкен тақтаның сол жағы',
      plankRight: 'Үлкен тақтаның оң жағы',
      plankFront: 'Үлкен тақтаның алдыңғы жағы',
      frontSide: 'Алдыңғы жағы',
      backSide: 'Артқы жағы',
      rightSide: 'Оң жағы',
      leftSide: 'Сол жағы',
      singleMachineLimit:
        'Бір жақты өңдеу тақтасының алдыңғы бетінде өңдеу жолын құру және алдымен өңдеу',
      singleMachineLimitTip:
        'Біріккен машиналар мен арамен кесу үшін бұл функция қолданыла алмайды',
      setBatchPositionWaring:
        'Кеңес: Егер сіз кесу нүктесін жылдам түзету режимін өшіруіңіз қажет болса, оң жақтағы «Кесу нүктесін түзету» түймесін алып тастаңыз',
      setBatchPosition: 'Кесу нүктелерін жаппай өзгерту',
      setBatchPositionAs: 'Кесу бағытын өзгерту:',
      activeBatchPosition: '{activePartCount}кішкентай тақта таңдалды',
    },

    labelingSettings: {
      title: 'Етикеттерди орнатуу',
      output: 'Автоматтык этикетка файлдарын чыгаруу',
      specification: 'Этикетка файл форматы',
      codeFrontGenerate:
        'Автоматтык этiketка коддор этикетка кодуңдун алдында түзүлөт',
      templateOffline: 'Колдонулган шаблон өчірди!',
      rollLabelling: 'Башка бетке этикетка орнотуу',
      flipSetting: {
        title: 'Башка бетке орнотуу',
        engravingFlipProcess:
          'Оюш машинасы этикетка орнотуу режимине өңдеуді қосуңыз',
        flipTip:
          'Оюш машинада башка бетке орнотуу керек болса, осы функцияны қосуңыз',
      },
      rotateSetting: {
        title: 'Этикеткаларды айналуу',
        rotateTip:
          'Таختанын көлөмү кескен же тик кескен өлчөмден кичине болсо, этикетка 90° маяттынай дайыма айналат',
        horizontal: 'Таختанын кескен көлөмү ≤',
        vertical: 'Тахтанын тик көлөмү ≤',
        either: 'Тахтанын кескен же тик көлөмү ≤',
      },
      countertopSetting: {
        title: 'Таختанын орнотуу',
        startPoint: 'Иштетүү орду:',
        upLeft: 'Жогору сол',
        upRight: 'Жогору оң',
        downLeft: 'Төмен сол',
        downRight: 'Төмен оң',
        baseExchange: 'Координат базасын алмаштуу:',
        exchangeX: 'X базасын алмаштуу',
        exchangeY: 'Y базасын алмаштуу',
      },
      offset: 'Этикетка координаттардын оффсет',
      offsetTip:
        'Адатта этикетка координаттары кичинекей тактанын борборунда, ал эми кээ бир шаймандарда корректирлөө талап кылынат',
      label_rotate_offset: 'Белгілердің өтіріктіктері өзгерген соң',
      label_rotate_offset_tip:
        'Белгілерді өтіріктік позициясы өзгерген соң көп айрылыс болса, өзгерту керек',
      label_rotate_offset_err_tip:
        'Жапсырманы айналдырғаннан кейін координаталар ығысуын қосқаннан кейін, X және Y осінің координаталық ауытқулары қажет.',
      imgSetting: {
        title: 'Байнама суреттерін орнату',
        suffix: 'Байнама суреттерінің суффикси:',
        code: 'Сурет кодирулеу әдісі:',
        codeTip:
          'Ескерту: png, jpeg пішімдеу суреттерінің кодирулеуін сынықтай өзгертеді, бірақ кірікті бұлт пішімдеуі осы функсияны қолданбайды',
        imgRatio: 'Байнама суреттерінің өлшемдері:',
        slowDown: 'Байнама суреттерін шығару ефективділігін төмендетеді',
        color: 'Нағыз',
        bit: 'Қара-ақ',
        resolution: 'Байнама суреттерінің өлшемдері',
        resolutionPlaceholder: 'Өлшем',
        labelPool: 'Деректердің бейнамалар қоры',
        dragTip: 'Бейнамалар қорінен бейнамаларды сүйреп тастауға болады',
        labelImgName: 'Байнама суреттерінің атауы',
        labelImgNameErrTip: 'Байнама суреттерінің атауы бос болмайды',
        labelTip1:
          'Ескерту: 1. "Бөлшектердің штрих-коды" немесе "Бейнамалардың нөмірі" дегенді өзіңіздің белгіліңізді пайдалануыңыз керек, болмаса суреттердің үстіне қалдыруысы проблема туындайды;',
        labelTip2:
          '2. Байнама машинасының жүйелік үлгісі осы функсияны қолданбайды',
        delimiter: 'Бөлшегіш:',
        inputTip:
          '/ : ? < > | . ” * және басқа да символдар енгізілуі мүмкін емес',
        numStart: 'Байнамалардің нөмірінің бастамақ сан',
        example:
          'Байнама суреттерінің атауларын өзіңіздің белгілеуіңіз (мисал)',
        divideFolders:
          'Байнама суреттері үлкен панельге сәйкес әртүрлі қапшықтарға бөлінуі',
        foldersTip1:
          'Ескерту: 1. Бұл функсия "Кесу машинасының файлдарын біріктіру" функциясымен бірге қолданылмайды;',
        foldersTip2: '2. Бұл функсия кірікті булт пішімдеуге әлі қолданылмайды',
        stickIntoFolders:
          'Байнама файлдарын тиісті үлкен панельдің байнама суреттері қапшықтарына қосу:',
        stickTip1:
          'Ескерту: 1. Бұл функсия "NC жүктемесін бірге бейнамаларды экспорту" функциясымен бірге қолдануыңыз керек;',
        stickTip2:
          '2. Байнама үлгісінің файлдарды құру әдісіне "Бір файлды құру" дегенді таңдауыңыз, сонда ғана бұл функсия қолданылады;',
        stickTip3: '3. Бұл функсия кірікті булт пішімдеуге әлі қолданылмайды',
      },
      paibanImgSetting: {
        title: 'Қалыптастыру суретті орнату',
        reparation:
          'Қалыптастыру суреттерінің нөмірі автоматты түрде толықтыру',
        figure: 'Орны',
        inputTip: '1-99 аралығындағы цифрларді енгізіңіз',
        paibanImg: {
          export: 'Қалыптастыру суреттерін экспорттау',
          prefix: 'Қалыптастыру суреттерінің атау префикси',
          size: 'Қалыптастыру суреттерінің өлшемдері',
          format: 'Қалыптастыру суреттерінің форматы',
          directional: 'Қалыптастыру бағыты',
          vertical: 'Вертикальді',
          horizontal: 'Горизонталды',
        },
      },
      autolabel: 'Автоматты жапсырма реттеу тәртібі',
      customTemp: 'Тек тапсырыс бойынша шаблондарға қолданылады',
      cuttingLabelOder: 'Кесу тәртібі бойынша жапсырма қою',
      labelToUp: 'Құрастыру нәтижесі бойынша төменнен жоғарыға жапсырма қою',
      labelToDown: 'Құрастыру нәтижесі бойынша жоғарыдан төменге жапсырма қою',
      labelDodge: 'Жапсырма автоматты түрде кедергілерден қашады',
      settingValue: 'Орнату мәнін таңдаңыз',
      enabelTip:
        'Қосылған кезде, кішкене тақтаның көрсетілген мәндерге сәйкес келуі кезінде, жапсырма айналады',
      fixedHori:
        'Жапсырманың көлденең шетіне тақтаның бекітілген шетіне параллельді таңдаңыз',
      fixedHoriTip:
        'Тек текстурасыз тақталарға қолданылады, қосылған кезде, жапсырма тақтаның бекітілген шетіне параллельді болады',
      tagHori: 'Жапсырманың көлденең шеті',
    },
    drillSettings: {
      title: 'Қалыптастыру бурғылау',
      enable: 'Қалыптастыру бурғылау தொகімін іске қосу',
      toolTable: {
        use: 'Іске қосу',
        number: 'Қалыптастыру бурғылау нөмірі',
        number2: 'Station 2 Drill Number',
        diameter: 'Бургылаң діаметрі',
        xOffset: 'X ауысу',
        yOffset: 'Y ауысу',
        moveZF: 'Z осьінің жылдам жылжымалы жылдамдығы',
        feedRate: 'Z осьінің өңдеу жылдамдығы',
        moveF: 'XY осьінің жылдам жылжымалы жылдамдығы',
        benchmarkDrill: 'Бастапқы бурғы',
        addKnife: 'Құрал қосу',
        deleteKnife: 'Құрал жою',
      },
      prefix: 'Қалыптастыру бурғылау префикси',
      suffix: 'Қалыптастыру бурғылау суффикси',
      startCode: 'Қалыптастыру бурғылау іске қосу коды',
      endCode: 'Қалыптастыру бурғылау тоқтату коды',
      combineKnife: 'Қалыптастыру бурғылау біріктіру',
      combineTable: {
        prefix: 'Қалыптастыру бурғылау нөмірінің префикси',
        suffix: 'Қалыптастыру бурғылау нөмірінің суффикси',
        mainHole: 'Негізгі тесік',
        extraHole: 'Қосымша тесік',
        notes: 'Біріктіру туралы ескерту',
        firstUseNotes: 'Біріктіруді алғашқы рет іске қосу туралы ескерту',
        addExtraHole: 'Қосымша тесік қосу',
        deleteExtraHole: 'Қосымша тесік жою',
        example: 'Үлгі',
      },
      processSetting: {
        title: 'Өңдеу параметрлері',
        mode: 'Қалыптастыру бурғылау режимі',
        processTip:
          'Бұл әдістің іске қосудан кейін қалыптастыру бурғылау біріктіру функциясы іске қосылмайды',
        recentHole: 'Жақын жерде тесік іздеу (бұрғыларды тез ауыстыру)',
        groupHole:
          'Топтама тесік іздеу (бір топтың тесіктерді іздегенде бұрғыларды ауыстыру)',
        drill_benchmark:
          'Інструменттің бағыттамасы комбинациясы (Instrument direction combination)',
        drill_benchmark_Tip:
          '（Диққат：Бу ыкманы танлагандан кейин, бур дрель комбинациясынын аспап бағыттамасы функциясы ишке ашпай калады）',
        mainHole: 'Бас бөлшек (Base section)',
        secondaryHole: ' Көмек бөлшек (Auxiliary section)',
      },
      changeBenchDrillTip:
        'Қалыптастыру бурғылау нөміріне енгізіңіз содан кейін таңдау',
    },
    NCExportSettings: {
      title: 'NC экспорттау параметрлері',
      tab1: 'Кескіш машинасының файл атау параметрлері',
      tab2: 'Кескіш машинасының файл атау біріктіру',
      tab3: 'Кескіш машинасының файлындағы ескерту',
      tab4: 'Кескіш машинасының файлындағы тип',
      tab5: 'Кескіш машинасының файл қапшығы',
      tab6: 'Өңдеу指令 параметрлері',
      tab7: 'Файл пішімі параметрлері',
      fileNameSettings: {
        twoStationAutoSort: 'Екі орны автоматты сұрыптау',
        reparation: 'Болшақтаның нөмірінің цифрліктарды толықтыру',
        figure: 'Орны',
        labelerBegin: 'Етикеткіштің бастамасы',
        machineCenter: 'Өңдеу орталығының бастамасы',
        front: 'Алғыс бет:',
        back: 'Арқасы бет:',
        customizeName: 'Қажетті файл атау (пішім):',
        delimiter: 'Айырмашы:',
        example: 'Қажетті файл атау (мысалы):',
        emptyTip:
          'Алғыс және арқасы беттің файл белгілері бір мезгілде бос болушы болмайды',
        labelPool: 'Деректердің белгісі топ',
        dragTip:
          'Белгісі топтағы белгілерді сүйреп немесе шерту арқылы кіріс қанатына көшіріңіз',
      },
      fileMerge: {
        engravingMerge: 'Кескіш машинасының файл біріктіру',
        zfMerge: 'Алғыс және арқасы беттің біріктіру',
        singleMerge: 'Бір орны біріктіру',
        doubleMerge: 'Екі орны біріктіру',
        fEndCode: 'Арқасы беттің аяқталу коды',
        zBeginCode: 'Алғыс беттің басталу коды',
        displayfbInfo:
          'Файл атауында алғыс және арқасы беттің ақпараті көрсетілу',
        fBeginCode: 'Арқасы беттің басталу коды',
        zEndCode: 'Алғыс беттің аяқталу коды',
      },
      marginalNotes: {
        addPlankInfo:
          'Кескіш файл бастамасына қазіргі үлкен тақта туралы ақпарат қосу:',
        plankInfo: 'Үлкен тақта туралы ақпарат:',
        addStationCodeFirst: 'Кескіш файл бастамасына станс коды қосу:',
        addStationCodeSecond:
          'Кескіш файлде зым ауыстыру алдында станс коды қосу:',
      },
      fileType: {
        zAddtion: 'Z координатасының толықтыру:',
        addSemicolon: 'Әрбір NC саптың соңына тоқтау таңбасын қосу:',
        semicolonTip:
          'Таңбашаны таңдағаннан кейін сарық қолтыңның өңдеу файлы де қолданылады',
        fileName: 'Файл атау',
        defaultName: 'Әдетті файл атау',
        dedicatedName: 'Жұлдызды құрылғыларға арналған файл атау',
        xhNotes: 'Жұлдызды E2-9 ескерту',
        format: 'Кескіш машинасының файл пішімі',
        example: 'Файл үлгі: NC файл',
        stillGenerate:
          'Арқасы бет өңделмейгенде да, арқасы беттің аяқталу коды бар арқасы бет NC файлы да жануарлықта екістіріледі',
        generateTip:
          'Бұл функсия файл біріктіру функциясымен бірге қолданылмайды',
        fileNameErrTip:
          'Файл атауға келесі символдарды қосуға болмайды: / : * ? "',
      },
      categorize:
        'Үлкен тақтаның түсі, қалыңдығы, матеріалды қайсы папкада (этикетка файлдарымен бірге) біріктіру:',
      simplifyG: 'Өңдеу G инструкциясының қысқаша пішімі',
      ZToW: 'Кодты Z осьінен W осіне ауыстыру',
      knives: 'Кескіш зымдары',
      lineBreakSetting: 'Жаңа саттардың пішімінің параметрлері',
      codeFormatSetting: 'Файл кодтың пішімінің параметрлері',
      utf8bom: 'BOM бар UTF-8',
      oneCodeSetting: 'Тақтаның штрих-кодтың параметрлері',
      simplifyOneCode: 'Қарапайым пішімдегі штрих-кодтың генерациясы',
      codeLocation: 'Кодтың орналасуының параметрлері',
      generateLocation:
        'Станс аяқталу коды арқасы беттің аяқталу коды алдында генералады',
      emergencySetting: 'Шұғылдық параметрлері',
      show: 'Шұғылдық параметрлерін көрсету',
      emergencyCode: 'Шұғылдық коды',
      params: 'Параметрлер',
      emergencyTip:
        'Сатушы қызметкерлермен бірге кейбір азиятты функцияларды уақытша іске асыруға болады',
      holeSetting: 'Делтірілеу файлдың параметрлері',
      holeFileCategorize:
        'Делтірілеу файлдарды әрбір тапсырыс бойынша отынша папкада біріктіру',
      holeTip: 'Клиенттің бұлтты тақталар үшін бұл параметрлерің кۈшінеді',
      specialCodeErr: 'Арнайы символдар енгізуге болмайды!',
      copySuccess: 'Көшіру ішке асырылды!',
      copyErr: 'Көшіру сәтсіз болды, Тегі сүйретуді сынап көріңіз!',
      lengthLimit: '15 цифрлықтар ғана енгізуге болады!',
      plankInfo: 'Тақтаның ақпараты',
      otherInfo: 'Басқа ақпарат',
      uniqueBarCode: 'Қаптама штрих-коды бірегей',
      uniqueBarcodeForPack: 'Панельдің штрих-коды бірегей (қаптамаға арналған)',
      machineOrder: 'Құрал-сайманның өңдеу нөмірін жасау үшін файл атауы',
      folderSetting:
        'Бірдей өлшемдегі үлкен тақталардың файлдарын бір папкада сақтау',
      labelImageINCFolder:
        'Қойылған белгілердің сураттарын және біргелескен белгілік файлдарды NC папkasына орналастырыңыз',
      custom: 'сварді',
      folderSettingTip:
        'Ескерту: Интеграцияланған орналастыру бұл функцияны қолдамайды',
      settingMoveTip: '"{func}" функциясы "{module}" бетіне көшірілді!',
      fileManagerFunction:
        'Тесіктер файлы әр түрлі тапсырыстар бойынша бөлек папкаларда сақталады',
      gotoSetting: 'Бағдарламаларға өту',
    },
    aioMachineSetting: {
      openFunction: 'Байланыстыру үшін келесі функцияларды қосу қажет',
      openCarving: 'Ою машинасын қосу',
      xyReverseFunction: 'XY осін ауыстыру функциясы',
      carvingStartSet: 'Ою машинасының басталу нүктесін орнату',
      rightTop: 'Жоғарғы оң жақ бұрыш',
      carvingEdgeSet: 'Ою машинасының алдыңғы жиегін кесу мәнін реттеу',
      finalEdgeSame: 'Жалпы жиегін кесу мәнімен бірдей',
      beginCode: 'Бағдарламаның басталу коды',
      endCode: 'Бағдарламаның аяқталу коды',
      drillHole: 'Тесік бұрғылау',
      pullTrough: 'Арнаны тарту',
      showDataTemp: 'Деректер үлгісін көрсету',
      dataTemp: 'Деректер үлгісі',
      title: 'Бірліктегі машина параметрлері',
      outputFile: 'Бірліктегі машина өңдеу файлын шығару',
      outputFileTips:
        'Бірліктегі машина іске қосілгенде, XY-ді орнамастыру, файлдарды біріктіру, атайын тіркелімді тесіктік шегуімен іске асыру қосымшалар міндетті емес, оқшамауыш пайдалану',
      fileType: 'Файл пішімі',
      select: 'Таңдау',
      diameter: 'Кескіш зымнің диаметрі',
      jidongTip:
        'Әржақ шаруашылық машинасын қолдануғанда, тазалоочу станциясының бастауыш нүктесініңызгі жоғарғы бұрышқа орналастырып,',
      skill: 'функциясын іске қосыңыз',
      clampHandLength: 'Ұстағыш ұзындығы(L)',
      clampHandWidth: 'Ұстағыш ені(W)',
      clampHandMargin1: 'Қолды шетінен 1 қашықтықта қысыңыз(H1)',
      clampHandMargin2: 'Қолды шетінен 2 қашықтықта қысыңыз(H2)',
      duchClampHand: 'Саңылау ұясы қысу күйін болдырмайды',
      clampHandDirection: 'Қысу бағыты',
      left: 'сол жағы',
      right: 'оң жағы',
      top: 'жоғарғы жағы',
      bottom: 'Төменгі жағы',
    },
    oneDragTwo: {
      title: 'Бірден екі кескіш машина',
      outputMachineFile: 'Бірден екі кескіш машинаның өңдеу файлын шығару',
      outputMachineFileTips:
        'Бірден екі кескіш машинаны іске қосілгенде, кескіш машинамен файлдарды біріктіру функциясымен іске асыру қосымшалар міндетті емес, оқшамауыш пайдалану',
      fileType: 'Файл пішімі',
      select: 'Таңдау',
      machineStart: 'Станс басталу нүктесі',
      positionClose:
        'Жабу кезінде станс басталу нүктесінің кесу параметрлеріне сәйкес орналастыру',
      engravingPlus: 'Жедел кескіш машина',
      a: '',
    },
    sawEngraving: {
      title: 'Қайтудық бұрысу және үстіндеу машинасы',
      preLayNav: 'Қайтару және қатау машинасының баптаулары',
      preLayKnifeInput: 'Құрыш дәңгейінің диаметрі',
      enableSaw: 'Қайта қосу жүктеу және орналастыру машинысы',
      cutSet: 'Қиыс баптау',
      sawUsing: 'Қайтау бұрыштың қолданысу',
      trim: 'жиегін өңдеу',
      cutBlank: 'жылжыту және ауыстыру',
      sawUseCond: 'Қатау бұйрығының қолдану шарттары',
      hasUsing: 'Қол жолына жүктеу',
      onlyUse: 'қажеттен және қолданыңыз',
      cutMethod: 'қиылу тәсілі',
      oneKnifePull: 'бір қиындық бұрышты жіберу',
      onlyOneKnifePull: 'Уақытыңызда қалайтын құрт бұрыс қиімдеу',
      enableSawThick: 'Серпе түю дейінің қатынауын іске қосу',
      edgedDir:
        'Едігін қайталмаған, қайталауды жоқ түрде бөгетілген үлкен тақта бұрышы',
      top: 'жоғары жақ',
      bottom: 'төмен жақ',
      left: 'сол жақ',
      right: 'оң жақ',
      dockTip:
        'Дүйсенбі тұрау бөлімдерін іске асыру үшін, түтілген тақтаның төрт жиекке түйіп, бүкіл жиекпейісінен айырылуының сәйкес көрсету керек, және қолмен өңдеу арқылы бір жолақты түрлендіру керек',
      tempPaiabanTip:
        'Жылжықты қиылуды іске қосқан соң, түрлі құрылғыларды түрлі өлшемдерді бұтаңдатын пайдалану мүмкін емес. Қосу қажет есінде, алмаз дискі бұтаңдықта қосу үшін, өңдеу тізіміне өту және түрлі құрылғыларды бұтаңдыңыз!',
    },
    firstSave:
      'Қолданушыған бұлшырымды тағйырламаңыз: Бұл қолданушыған ilkіші реттін buluт тағйырламамен жұмыс істеуде. Параметрлерді сақталғаннан кейін файлдардағы кескіш бағытының орынды екендігіне назар аударыңыз, заңылмайтын шығындардан сақтану үшін',

    knives: {
      xOffset1: 'X Coordinate Offset',
      yOffset1: 'Y Coordinate Offset',
      zOffset1: 'Z Coordinate Offset',
      title: 'Зым қоры',
      insert: 'Қатарды енгізу',
      delete: 'Қатарды өшіру',
      tip: '（Ескерту: Қырлық тақырыбының мағлуматын немесе настройкаларды өзгерткен кейін, кабинеттердің жобаларын жасайтын пайдаланушылар кабинет клиентін қайта бастыруы керек, сондықтан жаңғы өзгерістер қолданылады!）',
      number: 'Нөмір',
      name: 'Атау',
      diameter: 'Зым диаметріΦ',
      cutting: 'Кесу',
      use: 'Пайдалану',
      moveZF: 'Z осьдің жылдам жылдамдығы',
      cutZF: 'Z осьдің өңдеу жылдамдығы',
      moveF: 'XY осьдің жылдам жылдамдығы',
      cutF: 'XY осьдің өңдеу жылдамдығы',
      startCode: 'Зым ауыстыру бастау коды',
      stopCode: 'Зым ауыстыру аяқталу коды',
      startCode2: '工位2换刀开始代码',
      stopCode2: '工位2换刀结束代码',
      preStartCode: 'Алдын ала іске қосу коды',
      punchHoles: 'Тырнақ ішу',
      pullSlots: 'Слоттарды ішу',
      millHoles: 'Тырнақтарды фрезерлеу',
      millSlots: 'Слоттарды фрезерлеу',
      sawBlade: 'Кескіш',
      doubleSaw:
        'Дуплексті станциялар үшін әртүрлі құралды өзгерту бастау кодтарын қосыңыз',
      singleStation: 'Бір станция',
      doubleStation: 'Дуплекс',
      sawBladeTip:
        'Бұл функсия бірден екі кескіш машинасы мен бірлік машинасына қолданылмайды',
      serialNum: 'Серіалік нөмір',
      sawWidth: 'Зым қашықтығы',
      sawType: 'Кескіш түрі',
      cutDirection: 'Кесу бағыты',
      moveSpeed: 'Бос жүру жылдамдығы',
      cutSpeed: 'Өңдеу жылдамдығы',
      startCode: 'Зым ауыстыру бастау коды',
      endCode: 'Зым ауыстыру аяқталу коды',
      sawDownCode: 'Зым ішу коды',
      sawUpCode: 'Зым ішудан шығу коды',
      abscissa: 'Жакшы координаттың алдынкісі',
      ordinate: 'Жакшы координаттың ортасы',
      sawHor: 'Жақшы кескіш',
      sawVer: 'Вертикаль кескіш',
      towardsZero: '0 координатасына қарай кесу',
      awayFromZero: '0 координатасынан алыс кесу',
      diameterDesc: 'Зым диаметрі Φ',
      xOffset: 'Жакшы координатасының ауытқу',
      yOffset: 'Орталық координатасының ауытқу',
      zOffset: 'Жоғарғы-төмен координатасының ауытқу',
      offsetX: 'X ауытқу',
      offsetY: 'Y ауытқу',
      offsetZ: 'Z ауытқу',
      offsetNo: 'Ауытқу нөмірі',
      knifeWidth: 'Зым қашықтығы',
      drill: 'Зым ішу',
      cut: 'Өңдеу',
      speed: 'Айналым ылдамдығы',
      type: 'Түрі',
      millHoleSlot: 'Тырнақ/слот фрезерлеу',
      rapidFeedSpeed: 'Жылдам жеткізу жылдамдығы',
    },
    other: {
      title: 'Басқа',
      tip: '（Ескерту: Бирлектегі станс құрылғысы үшін 1 және 2 станстарын автоматтық тіркелім кодымен толтыру керек)',
    },
  },

  sideHoleMachine: {
    useArcCode: 'Доға кесуге арналған доғалық интерполяция командасы',
    coordSwitch: 'Координаталық Негізді Ауыстыру',
    updownCoordSwitch: 'Көтеру Координаталық Негізді Ауыстыру',
    knifes: {
      number: 'Номер',
      diameter: 'Қасақ ені',
      type: 'Түрі',
      moveSpeed: 'Жылдамдықпен жылдамдық',
      doSpeed: 'Өңдеу жылдамдығы',
      startCode: 'Қасақ ауыстыру бастау коды',
      endCode: 'Қасақ ауыстыру аяқтау коды',
    },
    ymSetting: 'Yimu байланыс настройкасы',
    flanking: 'Сірткі өңдеу',
    frontProcess: 'Алдыңғы өңдеу',
    oldSetting: 'Ескі параметрлер',
    knifeStore: 'Зым қоры',
    equipParamsSetting: 'Құрылғы параметрлерін орнату',
    holeSetting: 'Тырнақ параметрлерін орнату',
    raminoSetting: 'Lamino қосылу параметрлерін орнату',
    mortiseTenonSetting: 'Mortise и Tenon қосылу параметрлерін орнату',
    straight: 'Түз сызық',
    eccentric: 'Эксцентрлік модель',
    pbSize: 'Жоғарғы және төменгі сыну тақтасының өлшемі',
    coordinateValue: 'Координаталық мән',
    codeFormat: 'Файл кодирлеу форматы',
    suffixSetting: 'Файл суффиксін орнату',
    insertRow: 'Қатар енгізу',
    deleteRow: 'Қатар өшіру',
    equipmentParams: {
      equipPauseCode: 'Жабдықтар тоқтату коды',
      wiggleCode: 'Сілкіту коды',
      equipStartCode: 'Құрылғы іске қосу коды:',
      flipPauseCode: 'Өңделгені туқтату коды:',
      equipEndCode: 'Құрылғы іске қою коды:',
      equipStartCodePlaceholder: 'Құрылғы іске қосу коды енгізу',
      flipPauseCodePlaceholder: 'Өңделгені туқтату коды енгізу',
      equipEndCodeTip: 'Құрылғы іске қою коды енгізу',
      sideChangeSetting: 'Сіделік ауыстыру орнату',
      workTip: 'Әдетте сол жақтан ① бастап, шымал бұраншқа қарай өңдеу істеу',
      frontTurnCode: 'Жоғарғы бет басталу коды:',
      frontTurnEndCode: 'Жоғары бет аяқталу коды:',
      frontTurnCodePlaceholder: 'Жоғары бет басталу коды енгізу',
      frontTurnEndCodePlaceholder: 'Жоғары бет аяқталу коды енгізу',
      leftTurnCode: 'Сол бет басталу коды:',
      leftTurnEndCode: 'Сол бет аяқталу коды:',
      leftTurnCodePlaceholder: 'Сол бет басталу коды енгізу',
      leftTurnEndCodePlaceholder: 'Сол бет аяқталу коды енгізу',
      downTurnCode: 'Төменгі бет басталу коды:',
      downTurnEndCode: 'Төменгі бет аяқталу коды:',
      downTurnCodePlaceholder: 'Төменгі бет басталу коды енгізу',
      downTurnEndCodePlaceholder: 'Төменгі бет аяқталу коды енгізу',
      rightTurnCode: 'Уң бет басталу коды:',
      rightTurnEndCode: 'Уң бет аяқталу коды:',
      rightTurnCodePlaceholder: 'Уң бет басталу коды енгізу',
      rightTurnEndCodePlaceholder: 'Уң бет аяқталу коды енгізу',
      topFlipCode: 'Өңделгені коды - жоғары',
      downFlipCode: 'Өңделгені коды - төмен',
      leftFlipCode: 'Өңделгені коды - сол',
      rightFlipCode: 'Өңделгені коды - уң',
      cylinderOutCode: 'Цилиндр шығус коды',
      cylinderbackCode: 'Цилиндр қайтару коды',
      cylinderbackDirective: 'Цилиндр қайтару нұсқаулығы',
      moveDirective: 'Қозғалыс нұсқаулығы',
      moveAxisCode: 'Қозғалыс ось',
      pushAxisCode: 'Цилиндр қозғалту ось',
      swingAxisCode: 'Кыймылдан ось',
      moveCode: 'Қозғалыс коды',
      punchCode: 'Өңдеу коды',
      free: 'Бос жүру',
      punchPart: 'Өңдеу бөлім',
      cutFaceCode: 'Тасқышты оңдеу коды',
      horVerCutFace: 'Кері-жұмысқышты тасы',
      horCutFace: 'Кері-тұру тасы',
      verCutFace: 'Жұмысқышты-тұру тасы',
    },
    tagPool: 'Деректеріңіздің белгілері',
    dragTip: 'Белгілерді сүйреп таңдау',
    knives: 'Өңдеу құрал:',
    directives: 'Өңдеу нұсқаулығы:',
    slot: 'Тік оңдеу',
    cirWork: 'Кемерлі оңдеу',
    cirInter: 'Кемерлі оңдеудің толтыру',
    cirOption1: 'Кемерлі: G03 Кемерлі: G02',
    cirOption2: 'Кемерлі: G02 Кемерлі: G03',
    coordinate: {
      xPointSymbol: 'Көлденең координаты:',
      yPointSymbol: 'Жұмысқышты координаты:',
      zPointSymbol: 'Жүкілу координаты:',
      upright: 'Қостар',
      negative: 'Кемерлі',
    },
    encodeFormat: {
      format: 'Файл кодирлеу пішімі:',
      wrap: 'Жаңу шаруашылым пішімі:',
      formatOption1: 'UTF-8 пішімі',
      formatOption2: 'ANSI пішімі',
    },
    suffix: 'Файлдің суффиксін орнату:',
    enableFP: 'Өңдеудің алдыңғы бетін іске қосу',
    slotSetting: 'Керектендіру орнату',
    takeUpDirective: 'Қырғышты алу нұсқаулығы',
    workSeparately: 'Алдыңғы/ артқы беттің керектіңдірілім екі бөлімге бөліну',
    secondSlotLong: 'Екінші керектіңдірілімнің ұзындығы',
    enableOld: 'Ескі версияның жанбалау орнатуын іске қосу',
    default: 'Әдетті орнату',
    customizable1: 'Бірінші жеке орнату',
    customizable2: 'Екінші жеке орнату',
    customizable3: 'Үшінші жеке орнату',
    customizable4: 'Төртінші жеке орнату',
    knifeSetting: 'Қырғышты орнату',
    soltTip: '(Керектендіру/ керектіңдірілімді қосу)',
    specialSetting: 'Арнайы орнату',
    ramino: 'Рамино',
    sun: 'Суньмау',
    please: 'Күтініңіз',
    designatedKnives: 'Белгіленген қырғыш',
    designated: 'Белгіленген',
    workOrder: 'Өңдеу нұсқаулығы',
    other: 'Басқа',
    nameSetting: 'Суффикс орнату',
    repeatErr: 'Номер қайталанды, қайтадан орнатуіңіз керек',
  },
  // 电子锯
  electronicSaw: {
    importMaterial: 'Материалдар тізімін імпорттау',
    formSetting: 'Электронды пісіру машинасының нысандар орнату',
    fileFormat: 'Выходні форматі файлів для список материалдар:',
    isSmallFile: 'Экспорттау шағын пікір файл:',
    fileGroupRule:
      'Электронды пісіру машинасының файлдарі бойынша түс, материал және қалыңдықтық бойынша әртүрлі кестелерде:',
    roomsByCategory:
      'Электрондық жаттығу файлдары бөлмелерге бөлінген тақырыпта:',
    delimiter: 'бөлімші:',
    fileName: 'Файл атауы для список материалдар:',
    fineNameTip: 'Tên tệp danh sách cắt không được để trống!',
    fileNamePlaceholder: 'Өзіңіздің материалдар тізімі',
    outputFileEncoding: 'Выходні кодирування формату:',
    utf8: 'UTF-8 боммен',
    cellTab: 'Ұяшаларды табулатура қалыптастыру:',
    texture: 'Текстурасын көрсету:',
    special: 'Арнайы пішіру:',
    notSpecial: 'Арнайы емес',
    genSimpleLabelBarcode:
      'Қарапайым форматтағы тегтер мен штрих-кодтарды генерау',
    uniqueBarCode: 'Қаптама штрих-коды бірегей',
    uniqueBarCodeTip:
      'Активталғаннан кейін жалпы пішіндік тег баркод функциясы қолданылмайды',
    customeForm: 'Өзіңіздің нысандар',
    tagPool: 'Деректер тегтер жинағы',
    tagPoolTips:
      'Тегтер жинағынан тегтерді сүйрету немесе көшірмелеуге басыңыз',
    displayHead: 'Кестенің бастысын көрсетпеу',
    addTableHead: 'Кестенің бастысын қосу',
    deleteTableHead: 'Кестенің бастысын жою',
    generateExample: 'Выходні нысандар үлгісіні генерау',
    importExample: 'Нысандар үлгісін імпорттау',
    importExampleTip:
      'Імпортталған нысандар үлгісі электронды пісіру машинасының өзіңіздің нысандарыңызға референс болып табылады',
    importExampleErrTip:
      'Материалдар тізімі әлі імпортталмаған, материалдар тізімін імпорттау',
    outputExample: 'Выходні нысандар үлгісі',
    outputExampleTip: 'Өзіңіздің нысандарыңыздың выходні үлгісі',
    outputExampleErrTip:
      'Выходні нысандар үлгісі әлі генералмаған, выходні нысандар үлгісі генерау',
    defaultTableHead: 'Кестенің әдетті бастысы',
    deleteHeadErrTip: 'Жоюға болатын кестенің бастысы жоқ!',
  },
  // 锁定弹窗组件
  lockModal: {
    passwordInput: 'Құпия сөзді енгізу',
    lockPasswordInput: 'Құпия сөзді ашу ұсын',
    passwordInputPlaceholder: 'Төрт таңбалық құпия сөзді енгізу',
    editPassword: 'Құпия сөзіні өңдеу',
    confirmPassword: 'Құпия сөзіні растау',
    setLockPwd: 'Құпия сөзді ашу ұсын',
    pwdFormatErr: 'Құпия сөздің пішімі дұрыс емес',
    sendMessageTo: 'Кодты смс-хабарламашы',
    captchaPlaceholder: 'Кодты енгізу',
    sendCaptcha: 'Кодты жіберу',
    sendSeccess: 'Смс-хабарлама жіберілді',
    sendFail: 'Смс-хабарлама жіберуге сәтсіз, кейінгіре тексеріңіз',
    updateSeccess: 'Құпия сөздің орнатылуы сәтті',
    editSuccess: 'Құпия сөздің өзгерісі сәтті',
    lockSeccess: 'Құпия сөзден ашу сәтті',
    lock: 'Ашу',
    inputErrLimit:
      'Қазіргі қатетіңіздің шегіні ашу, қайтадан құпия сөздің орнатылуын орнату!',
  },

  // 56面钻
  dock56Drill: {
    equipType: 'Құрылғы түрі',
    equipment: 'Устройство',
    fiveDock: 'Бес жақты бұрғылау машина',
    sixDock: 'Алты жақты бұрғылау машина',
    fileType: 'Құрылған файл түрі',
    templateOffline: 'Қолданылатын шаблондыңыз қызметтілігі тоқтатылды!',
    showExample: 'Деректер үлгісін көрсету',
    conNoticeTip: 'Қосымша ескерту:',
    conNoticeTip1:
      '1. "XY осьтері алмастыру" қолданылған оюу машинасы бес/алты жақты бұрғылау машинасының арнайы шаблондарынын алмастыруыны қолданады',
    conNoticeTip2:
      '2. Бес/алты жақты бұрғылау машинасында панельдің арнайы бөліктерді көрсету немесе өңдеу үшін шаблонда арнайы бөліктердің деректерін конфигурациялау керек',
    conNoticeTip3:
      '3. Бес/алты жақты бұрғылау машинасын алғашқы қолдану кезінде немесе шаблонды ауыстырғаннан кейін, қаптамасының сынақтық өндірісі істеуіш, содан кейін қалыпты өндіріске кіріңіз',
    conNoticeTip4:
      '4. Сынақтық өндіріске кіріп, панельдің алдыңғы және артқы бетіндегі тесіктің, тереңдің, орналасқан бетің және орналасуың дұрыс екендігіне мұқайық бақылау,қаптамалардің зергілікті көрінісі болмау үшін',
    example: 'Деректер үлгісі',
    commonSetting: 'Жалпы параметрлерді орнату',
    throughHoleSetting: 'Толық тесіктің екі бетіне дейін ішкірілу',
    throughSlotSetting: 'Толық слоттің екі бетіне дейін ішкірілу',
    nonHolesNotFiles:
      'Квадраттық панельде тесіктер мен слоттары болмаса, файл ішкірілмейді:',
    frontFileIdentifier: 'Алдыңғы беттің файл белгісі:',
    oppositeFileIdentifier: 'Артқы беттің файл белгісі:',
    oppositeFileIdentifierTips:
      'Қалыптастыру әдісі: алғашқы 13-шілікті панельдің кодты + белгілікі метін',
    arcDescription: 'Кемердің сипаттама әдісі',
    linePoints: 'Сызықтық пішірудің сипаттамасы',
    arcCommand: 'Кемердің командалық сипаттамасы',
    slotWidthSplit: 'Кең слоттың бірнеше жіңілік слоттарына айырылуы:',
    slotSplitWidth: 'Айырылғаннан кейінгі жіңілік слоттың ені:',
    mm: 'Ескерту: Айырыланатын нысандар қалыпты ректангульікті слоттар',
    isNotMergeSlot: 'Үшіншісің бар кенітті және терең слоттарды біріктіру:',
    slotPunchTip:
      'Бұл функциясы тек жеке шаблондыңыздың артқы панель слоттары мен сандықтің астынары слоттарына ғана қолданылады',
    sizeRange: 'Үшіншісің бар кенітті және тереңсіздің кішісі',
    enableWorningTip:
      'Бұл функциясы алдыңғы шеттің орналасу бағытымен бір мезгілде іске қосылмайды',
    longDirection: 'Панельдің ұзын бағытының орналасу бағыты',
    horizontal: 'Кеңдегі',
    vertical: 'Вертикалды',
    slotDirection:
      'Бес/алты жақты бұрғылау машинасының слоттарының немесе арнайы пішірудің орналасу бағыты',
    default: 'Системаның әдетті параметрлері',
    frontLeft: 'Алдыңғы және сол жақ',
    frontRight: 'Алдыңғы және оң жақ',
    backLeft: 'артқы және сол',
    backRight: 'артқы және оң',
    frontDirection: 'Алдыңғы шеттің орналасу юнітелі',
    enableTip: 'Ескерту: Бұл функциясы тек бұлтты нұсқадау үшін қолданылады',
    fixedOn: 'Қаталды:',
    front: 'Алдыңғы бет',
    back: 'Артқы бет',
    left: 'Сол жақ',
    right: 'Оң жақ',
    coordinateValue: 'Координаттардың сақталған цифрлар саны:',
    decimalPlaces: 'Цифрлар',
    specialParameter: 'Арнайы параметрлерді орнату',
    output5FDrillSlotKnifeCode: 'XML2 фрезерлік слоттың құрал коді',
    output5FDrillShapeKnifeCode: 'XML2 фрезерлік пішірудің құрал коды',
    plankTexture: 'XML2 панельдің текстурасы',
    offset: 'XML2 фрезерлік пішірудің құралдарының компенсациясы',
    mprConHoleDir:
      'Бес/алты жақты бұрғылау машинасының MPR файлінің сыртқы жанбалардағы тесіктің Z координатасының базалық плоскості',
    plankTop: 'Панельдің жоғарғы беті',
    plankBottom: 'Панельдің төменгі беті',
    codeInfo: 'ban форматты файл Code ақпаратты қамтиды:',
    bomFile: 'bom форматты файл шығару:',
    plankHeight: 'Панельдің биіктігінің сипаттамасы:',
    plankNum: 'Панельдің нөмірінің сипаттамасы:',
    backZpoint: 'Артқы слоттың Z координатасы:',
    plankThick: 'Панельдің қалыңдығы',
    slotDeep: 'Слоттің тереңдігі',
    outputMprFile: 'Файл түрі',
    oldMPR: 'Ескі MPR',
    newMPR: 'Жаңа MPR',
    mprFileZside:
      'Бес/алты жақты бұрғылау машинасының MPR файлінің сыртқы жанбалардағы тесіктің Z координатасының базалық плоскості',
    fileTypeOffline:
      'Қазіргі таңдаулықты бес/алты жақты бұрғылау машинасының файл түрі қызметтілігі тоқтатылды. Ескертуіш!',
    permissionErr: 'Қызметтеріңіз жоғалды, шаблоннан қайтадан осы бетті ашуіш!',
    banFileSetting: 'ban форматты файлды орнату',
    banFileCodeInfo: 'ban форматты файл Code ақпаратты қамтиды:',
    huahuaLine: 'Huahua Line',
  },

  // PTP
  PTP: {
    title: 'PTP',
    outputFileFormat: 'Чықару файл пішімі',
    outputDefaultFileFormat: 'Қалыпты файлды шығару',
    outputMPRFormat: 'MPR пішімді файлды чыгару',
    fileFormatSettings: 'Файл пішімінің параметрлері',
    wrapSettings: 'Қайта бастау белгісінің пішімі:',
    enCodeSettings: 'Файл кодлау пішімі:',
    utf8bom: 'BOM бар UTF-8',
    partCoordinate: 'Кишіктік панельдің координатасы',
    xPointSymbol: 'Жақыстық координата:',
    yPointSymbol: 'Жүзілік координата:',
    zPointSymbol: 'Көтеру координата:',
    upRight: 'Қостар',
    negative: 'Қайтар',
    plankMirroring: 'Панельдің көрінісі',
    plankMirrorSetting: 'Панельдің көрінісінің параметрлері:',
    xAxis: 'X осьсінің көрінісі',
    yAxis: 'Y осьсінің көрінісі',
    mirrorTip: '（Панельдің көрінісіде осы функцияны қолдану）',
    knifeTable: {
      type: 'Түрі',
      diameter: 'Диаметр Φ',
      offsetX: 'X ауысу',
      offsetY: 'Y ауысу',
      offsetZ: 'Z ауысу',
      offsetNo: 'Ауысу нөмірі',
      drillVelocity: 'Қырқық ату [F]mm/min',
      cutVelocity: 'Қыру [F]mm/min',
      rotationSeep: 'Айналу шығымы [S]r/min',
      insertRow: 'Сатыр енгізу',
      deleteRow: 'Сатыр жою',
    },
    labelPool: 'Деректердің белгісі топ',
    dragTip:
      'Белгісі топтағы белгілерді сүйреп же шерту арқылы шығу ісіне көшіріңіз',
    procedure: 'Процедура',
    beginCode: 'Процедура басталу коды:',
    endCode: 'Процедура аяқталу коды:',
    zzdKnifeBeginCode: 'Негізгі қырқық алмастыру басталу коды:',
    spindleCutter: 'Негізгі қырқық',
    zzdKnifeEndCode: 'Негізгі қырқық алмастыру аяқталу коды:',
    verticalDrill: 'Тік қырқық',
    czzBeginCode: 'Тік қырқық іске қосу коды:',
    czzEndCode: 'Тік қырқық іске тоқтау коды:',
    czzKnifeBeginCode: 'Тік қырқық алмастыру басталу коды:',
    czzKnifeEndCode: 'Тік қырқық алмастыру аяқталу коды:',
    sidetrack: 'Сіде трак',
    czBeginCode: 'Сіде трак іске қосу коды:',
    czEndCode: 'Сіде трак іске тоқтау коды:',
    czKnifeBeginCode: 'Сіде трак алмастыру басталу коды:',
    czKnifeEndCode: 'Сіде трак алмастыру аяқталу коды:',
    pauseCode: 'Тынықтау коды:',
    zSafeHeight: 'Z осьсінің қауіпсіз биіктігі',
    auxiliaryFunction: 'Қосымша функсия',
    xhDedicatedInfo: 'Жұлдызды құрылғыларға арналған ақпаратты жасау',
    xhCheckBox: 'Деректер:',
    outputSettings: 'Чыгару параметрлері:',
    partExegesis:
      'Файлда кішіктік панельдің өлшемдері туралы түсініктемелерді жасау',
    knifeNo: 'Қырқық нөмірі',
    specailKnifeNo: 'Арнайы пішіруінші қырқық нөмірі:',
    slotKnifeNo: 'Слот қырқық нөмірі:',
    fileNamePlaceholder: 'Өзіңіздің іске кіріс тізіміңіз',
    slotDrill: 'Сыртқа тікеуіш қырқық',
  },
  glassEquip: {
    title: 'Шыны кескіш машина',
    fileType: 'Құрастыру файл түрі:',
    sizeReparation: 'Панельдің өлшемдеріні толықтыру:',
    cutPreSpace: 'Панельдерді кесу алдын ала аралық:',
    divideIntoFolders:
      'Шыны түсі, материалы және қалыңдығы бойынша файлдар қапшығына жіктелу:',
  },
  translate: {
    search:
      'Терминді табу үшін қытай тіліне немесе ресми аудармасына енгізуіңізді сұраймын',
    en: 'Ағылшынша',
    kz: 'Қазақша',
    vi: 'Вьетнам тілі',
    customTranslate: 'Реттелетін аударманы қосу үшін басыңыз',
    customText: 'Реттелетін аударма',
    text: 'Қытай тілі',
    targetLang: 'Ресми аудармасы',
  },
  preLayoutSetting: {
    title: 'Алдын ала орналастыру баптаулары',
    titleTip:
      'Алдын ала орналасу параметрлерін алдын ала орнатуға болады, алдымен орналастырып, содан кейін өндіріс желісін таңдаңыз',
    settingTip:
      'Өндіріс желісінде орнатылған параметрлер төмендегі алдын ала орналастыру параметрлерімен сәйкес келуі керек, сонда ғана өндірістік файлдарды шығару мүмкін болады, тек кесу машинасына қолданылады',
    emptyKnife: 'Құрал көрсетілмеген',
    noModify: 'Параметрлер өзгертілмеген, сақтаудың қажеті жоқ',
    reSet: 'Қалпына келтіру',
    gayPosition:
      'Сұр блок артық материалдың орнын ауыстыру үшін ауыстырылуы мүмкін',
    surplusPosition: 'Ағымдағы артық материалдың орны',
    click: 'Басыңыз',
    grayStart: 'Сұр блок жұмыс орнын бастауды ауыстыру үшін ауыстырылуы мүмкін',
    currentStart: 'Ағымдағы жұмыс орнының басталуы',
    holeSlotSetting: 'Тишек сондырау бассейни ишлеу настройкалары',
    knifeDiameter: 'Кесу құралының диаметрі',
    currentVersion: 'Ағымдағы нұсқа',
    on: 'Қосу',
    off: 'Өшіру',
    firstShort: 'Алғашқы кесу - қысқа жағын кесу',
    firstLong: 'Алғашқы кесу - ұзын жағын кесу',
    cuttingSplitLong: 'Ұзын тақтаны екі рет кесу - ұзын жағының артықтығы',
    cuttingSplitShort: 'Ұзын тақтаны екі рет кесу - қысқа жағының артықтығы',
    plankThick: 'Кәдімгі тақтаның қалыңдығы ≥ ',
    normalPlank: 'Кәдімгі тақта',
    confilictTip: 'Қондырғылардың қайшылығы бар: {confilictTotal} элемент',
    selectLine: 'Өндіріс желісін таңдаңыз',
    selectLinePlaceholder: 'Өндіріс желісін таңдаңыз',
    lineConfilictTip: 'Өндіріс желісін таңдау {line} мен қайшылыққа әкеледі',
    preWay: 'Алдын ала орналасу жоспары',
    paibanErrTip:
      'Ұзын тақтаны өңдеу кезінде [ұзын және қысқа жақтарының кеңеюі] өзгерсе, орналасу сәтсіз болуы мүмкін',
    paibanTip:
      'Бұл жолғы орналасуда үлкен тақта материалының кесу пышағы белгіленген, қайта орналасу қажет',
    reLayout: 'Ағымдағы өндіріс желісі бойынша қайта орналасу',
    fetchErr: 'Алдын ала орналастыру параметрлерін алу сәтсіз аяқталды',
    resetSuccess: 'Қалпына келтіру сәтті аяқталды',
    resetConfirm: 'Тарихи нұсқаға қайтару үшін растайсыз ба?',
    prolineName: 'Өндіріс желісінің атауы',
    layoutGap: 'Орналастыру аралығы',
    generalThickness: 'Екінші кесу нысаны - Әдеттегі тақтаның қалыңдығы',
    cutGeneralPlank: 'Екінші кесу нысаны - Әдеттегі тақта',
    cutSingleDoor: 'Екінші кесу нысаны - Есіктің панелі',
    changeProline: 'Өндіріс желісін ауыстыру',
    backTip: 'Өндіріс желісін таңдау үшін глобалды орналастыруға оралыңыз',
    preLayout: 'Алдын ала орналастыру схемасына сәйкес орналастыру',
    preWayEmpty: '(Бос)Алдын ала орналастыру схемасы',
    specialConfilictTip:
      'Таңдалған өндіріс желісі доға фрезерлеу процесінің параметрлеріне әсер етеді, қайта орналасу қажет',
  },
  lunbo: {
    tips: 'Қолдану шетіктері',
    introduction: 'Функциялық сипаттама',
    qtDownloadText:
      'Ескерту: Егер файл толық жүктелмесе, онда бұл файлды бұлтты пішімдеу веб-сайтынан жүктеуге болады!',
  },
}
